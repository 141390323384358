import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGIN_LOADING, AuthDispatchTypes, REFRESH_TOKEN, LOG_OUT, UPDATE_ACCOUNT, UPDATE_DRIVERLICENCE, UPDATE_TAXILICENCE, UPDATE_IDENTIFICATION, UPDATE_VEHICLE, UPDATE_ELIGIBLE, AUTHENTICATION_ERROR } from './types';
import AuthApi from './api'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Account } from '../../types';

export const loginSuccess = (account: Account) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: LOGIN_SUCCESS, payload: account});
}

export const loginFailure = () => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: LOGIN_FAILURE});
}

export const authenticationError = (errorMessage: string) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: AUTHENTICATION_ERROR, payload: errorMessage});
}

export const updateAccount = (firstName: string, lastName: string) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_ACCOUNT, payload: {firstName: firstName, lastName:lastName}});
}

export const updateDriverLicence = (set: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_DRIVERLICENCE, payload: set});
}

export const updateTaxiLicence = (set: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_TAXILICENCE, payload: set});
}

export const updateVehicle = (set: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_VEHICLE, payload: set});
}

export const updateIdentification = (set: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_IDENTIFICATION, payload: set});
}

export const updateEligible = (set: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: UPDATE_ELIGIBLE, payload: set});
}


export const autoLogin = (id: string, firstName: string, lastName: string, token: string, refreshToken: string, /**/ role: string, metric: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: LOGIN_SUCCESS, payload: {id: id, firstName: firstName, lastName:lastName, token: token, refreshToken: refreshToken, /**/ email: '', password: '', profilePhoto: '', role: role, metric: metric}});
}

export const refresh = (id: string, firstName: string, lastName: string, token: string, refreshToken: string, profilePhoto: string) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  dispatch({type: REFRESH_TOKEN, payload: {id: id, firstName: firstName, lastName: lastName, token: token, refreshToken: refreshToken, email: '', password: '', profilePhoto: profilePhoto}});
}

export const logout = () => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  await logOut();
  dispatch({type: LOG_OUT, payload: {id: '', firstName: '', lastName:'', token: '', refreshToken: '', email: '', password: '', profilePhoto: ''}});
}

const logOut = (async() => {
  try {
    await AsyncStorage.removeItem('user.id');
    await AsyncStorage.removeItem('user.firstName');
    await AsyncStorage.removeItem('user.lastName');
    await AsyncStorage.removeItem('user.token');
    await AsyncStorage.removeItem('user.refreshToken'); 
    await AsyncStorage.removeItem('user.profilePhoto');
  } catch(error) {
  }
});
