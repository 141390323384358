import React from 'react'

import {
  StyleSheet,
  View,
} from 'react-native';
import Drivers from '../components/driver/Drivers';

interface Props {
}

const DriversScreen: React.FC<Props> = ({ 
}) => {   
  return ( 
    <View style={styles.container}>
      <Drivers />
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    width: "100%"
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 20
  }
});

export default DriversScreen