import React from 'react'

import {
  StyleSheet,
  View,
  Image,
  ScrollView,
} from 'react-native';

import {useNavigation} from '@react-navigation/native';
import Button from '../components/ui/Button';
import ForgotPasswordForm from '../components/authentication/ForgotPasswordForm';

interface Props {
}

const ForgotPasswordScene: React.FC<Props> = ({ 
}) => { 
  const navigation = useNavigation();
  
  const cancel = () => {
    navigation.goBack();
  }

  return ( 
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      keyboardShouldPersistTaps='always'>
          <View style={styles.inputContainer}>
          <Image style={styles.image} source={require('../assets/logo.png')} />
            <ForgotPasswordForm 
              onComplete={cancel} />
            <View style={styles.signUpContainer}>
              <Button onPress={cancel}
                style={styles.button}
                text="Cancel">
              </Button>
            </View>
          </View>
       </ScrollView>
  )
};

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
 
  image: {
    width: 200,
    height: 70,
    resizeMode: 'contain',
    marginBottom: 20,
    marginTop: 20
  },

  button: {
    margin: 10,
    width: 300,
  },
  
  signUpContainer: {
    marginTop: 40
  }
});

export default ForgotPasswordScene