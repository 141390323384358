import { AuthDispatchTypes, AuthState, LOGIN_FAILURE, LOGIN_LOADING, LOGIN_SUCCESS, LOG_OUT, REFRESH_TOKEN, UPDATE_ACCOUNT, UPDATE_DRIVERLICENCE, UPDATE_ELIGIBLE, UPDATE_IDENTIFICATION, UPDATE_TAXILICENCE, UPDATE_VEHICLE } from "./types";

export const initialState: AuthState = { error: '', authenticated: false, id: '', firstName: '', lastName: '', role: '', email: '', profilePhoto: '', token: '', refreshToken: '', phoneNumber: '', metric: 0, driverLicenceId: 0, taxiLicenceId: 0, identificationId: 0, vehicleId: 0, eligible: false };

export const authReducer = (
  state: AuthState = initialState,
  action: AuthDispatchTypes
) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
        authenticated: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        profilePhoto: action.payload.profilePhoto,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        phoneNumber: action.payload.phoneNumber,
        role: action.payload.role,
        metric: action.payload.metric,
        driverLicenceId: action.payload.driverLicenceId,
        taxiLicenceId: action.payload.taxiLicenceId,
        identificationId: action.payload.identificationId,
        vehicleId: action.payload.vehicleId,
        eligible: action.payload.eligible,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: 'Invalid login credentials',
      }
    case REFRESH_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      }
    case LOG_OUT:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: '',
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        profilePhoto: '',
        token: '',
        refreshToken: '',
        phoneNumber: '',
        metric: 0
      }
    case UPDATE_ACCOUNT:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      }
    case UPDATE_DRIVERLICENCE:
      return {
        ...state,
        driverLicenceId: action.payload,
      }
    case UPDATE_TAXILICENCE:
      return {
        ...state,
        taxiLicenceId: action.payload,
      }
    case UPDATE_IDENTIFICATION:
      return {
        ...state,
        identificationId: action.payload,
      }
    case UPDATE_VEHICLE:
      return {
        ...state,
        vehicleId: action.payload,
      }
    case UPDATE_ELIGIBLE:
      return {
        ...state,
        eligible: action.payload > 0 ? true : false,
      }

    default:
      return state;
  }
}