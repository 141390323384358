import { createElement } from "react-native";
import { formatDate } from "../../services/helpers";

export const TimePicker = ({value, onChange} : {value: Date, onChange: any}) => {
  const [date, time] = formatDate(value).split(' ');

  return createElement('input', {
    type: 'time',
    value: time,
    onChange: onChange,
  })
}