import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react'
import { Picker } from '@react-native-picker/picker';

import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  TextInput,
  Alert,
  ActivityIndicator,
  Dimensions,
} from 'react-native';

import Button from '../../components/ui/Button';
import { DatePicker } from '../../components/ui/DatePicker';
import Colors from '../../constants/Colors';
import { createScheduleEntry, getAPIDate } from '../../services/helpers';
import ScheduleApi from '../../services/modules/schedule/api';
import { ScheduleEntry } from '../../services/types';
import AccountApi from '../../services/modules/account/api';
import { TimePicker } from '../ui/TimePicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';

interface Props {
}

const ScheduleEntryEditor: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();

  const scheduleState = useSelector((state: RootState) => state.schedule);

  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [scheduleEntry, setScheduleEntry] = React.useState<ScheduleEntry>(createScheduleEntry())

  const [hours, setHours] = React.useState(0)
  const [minutes, setMinutes] = React.useState(0)
  
  const [scheduledDate, setScheduledDate] = React.useState("");
  const [scheduledTime, setScheduledTime] = React.useState("");

  const [drivers, setDrivers] = React.useState([])

  const saveScheduleEntry = () => {
    if (scheduleEntry.accountId.length == 0 || scheduleEntry.duration == 0) {
      alert("Please enter all information.");
      return;
    }

    setSaving(true);
    ScheduleApi.update(scheduleEntry)
    .then(() => {
      setSaving(false);
      close();
    });
  }

  const close = (() => {
    navigation.goBack();
  });

  const onChangeScheduledDate = (selectedDate: any) => {
    setScheduledDate(selectedDate.target.value)
    let storeDate = new Date(selectedDate.target.value + " " + scheduledTime)
    setScheduleEntry({...scheduleEntry, scheduledTime: storeDate})
  };

  const onChangeScheduledTime = (selectedDate: any) => {
    setScheduledTime(selectedDate.target.value)
    let storeDate = new Date(scheduledDate + " " + selectedDate.target.value)
    setScheduleEntry({...scheduleEntry, scheduledTime: storeDate})
  };

  useEffect(() => {
    AccountApi.getApprovedDrivers()
    .then((apiResponse: any) => {
      setDrivers(apiResponse.data)
    })
  }, [])

  useEffect(() => {
    if (scheduleState.id == 0) {
      let storeDate = new Date();
      storeDate.setHours(8,0,0,0);
      setScheduleEntry({...scheduleEntry, scheduledTime: storeDate});
      setScheduledDate(storeDate.getFullYear() + "-" + (storeDate.getMonth() + 1) + "-" + storeDate.getDate());
      setScheduledTime("8:00");
    } else {
      ScheduleApi.getScheduleEntry(scheduleState.id)
      .then((apiResponse: any) => {
        let storeDate = getAPIDate(apiResponse.data.scheduledTime);
        setScheduleEntry({...apiResponse.data, scheduledTime: storeDate});
        setScheduledDate(storeDate.getFullYear() + "-" + (storeDate.getMonth() + 1) + "-" + storeDate.getDate());
        setScheduledTime(storeDate.getHours() + ":" + storeDate.getMinutes());

        if (apiResponse.data.duration > 59) {
          var h = Math.floor(apiResponse.data.duration / 60);
          var m = Math.floor(apiResponse.data.duration % 60);

          setHours(h);
          setMinutes(m);
        } else {
          setMinutes(apiResponse.data.duration);
        }
      })
    }
  }, [scheduleState])

  return ( 
    <ScrollView
      contentInsetAdjustmentBehavior="automatic">
      <View style={styles.container}>        
        {loading &&
          <View style={{marginTop: 40}}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.searchingText}>Loading...</Text>
          </View>
        }

        {saving &&
          <View style={{marginTop: 40}}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.searchingText}>Saving...</Text>
          </View>
        }

        {!saving && !loading && <View style={{width: "50%", alignSelf: 'center'}}>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Driver: </Text>
            <View style={styles.inputGroup}>
              <View style={styles.inputBoxNoBorder}>
                <Picker
                  style={styles.input}
                  selectedValue={scheduleEntry.accountId}
                  onValueChange={(itemValue, itemIndex) => setScheduleEntry({...scheduleEntry, accountId: itemValue})}>
                    <Picker.Item key={0} label="Please Select" value={0} />
                    {
                      drivers.map( (v: any) => {
                        return <Picker.Item key={v.account.id} label={v.account.lastName + ", " + v.account.firstName} value={v.account.id} />
                      })
                    }
                </Picker>
              </View>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Date: </Text>
            <View style={styles.inputGroup}>
              <View style={styles.inputBoxNoBorder}>
                <DatePicker
                  value={scheduleEntry.scheduledTime}
                  onChange={onChangeScheduledDate}
                />
              </View>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Time: </Text>
            <View style={styles.inputGroup}>
              <View style={styles.inputBoxNoBorder}>                  
                  <TimePicker
                    value={scheduleEntry.scheduledTime}
                    onChange={onChangeScheduledTime}
                  />
              </View>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Duration</Text>
            <View style={styles.inputGroup}>
              <TextInput
                style={[styles.input, {width: 60}]}
                placeholder="Enter hours"
                placeholderTextColor="#003f5c"
                value={hours.toString()}
                keyboardType="number-pad"
                returnKeyType="next"
                onChangeText={(value) => {
                  if(value.length == 0){
                    value = "0";
                  }
                  if (!isNaN(+value)) {
                    setHours(parseInt(value));
                    setScheduleEntry({...scheduleEntry, duration: ((+value * 60) + minutes)})
                  } else {
                    setHours(hours)
                  }
                }}
              />
              <Text style={{paddingTop: 5}}> hours</Text>
            </View>
            <View style={styles.inputGroup}>
              <TextInput
                style={[styles.input, {width: 60}]}
                placeholder="Enter minutes"
                placeholderTextColor="#003f5c"
                value={minutes.toString()}
                keyboardType="number-pad"
                returnKeyType="next"
                onChangeText={(value) => {
                  if (!isNaN(+value)) {
                    setMinutes(parseInt(value));
                    setScheduleEntry({...scheduleEntry, duration: ((hours * 60) + +value)})
                  } else {
                    setMinutes(hours)
                  }
                }}
              />
              <Text style={{paddingTop: 5}}> minutes</Text>
            </View>
          </View>
          
          <Button onPress={saveScheduleEntry}
            style={styles.button}
            text="Save">
          </Button>

          <Button onPress={close}
            style={styles.button}
            text="Cancel">
          </Button>
        </View>}
      </View>
    </ScrollView>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    width: "100%"
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },

  inputContainer: {
    flexDirection: 'row',
    padding: 10
  },
  
  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
 
  inputBoxNoBorder: {
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
 
  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  button : {
    margin: 10,
  },
  
  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});

export default ScheduleEntryEditor