export const LOGIN_LOADING = 'AUTH/LOGIN_LOADING';
export const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH/LOGIN_FAILED';
export const AUTHENTICATION_ERROR = 'AUTH/AUTHENTICATION_ERROR';
export const UPDATE_DRIVERLICENCE = 'AUTH/UPDATE_DRIVERLICENCE';
export const UPDATE_TAXILICENCE = 'AUTH/UPDATE_TAXILICENCE';
export const UPDATE_IDENTIFICATION = 'AUTH/UPDATE_IDENTIFICATION';
export const UPDATE_ELIGIBLE = 'AUTH/UPDATE_ELIGIBLE';
export const UPDATE_VEHICLE = 'AUTH/UPDATE_VEHICLE';
export const REFRESH_TOKEN = 'AUTH/REFRESH_TOKEN';
export const UPDATE_ACCOUNT = 'AUTH/UPDATE_ACCOUNT';
export const LOG_OUT = 'AUTH/LOG_OUT';

export interface AuthDispatchTypes {
  type: string;
  payload?: any;
}

export interface AuthState {
  authenticated: boolean;
  error: string;
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  token: string;
  refreshToken: string;
  profilePhoto: string;
  phoneNumber: string;
  metric: number;
  driverLicenceId: number;
  taxiLicenceId: number;
  identificationId: number;
  vehicleId: number;
  eligible: boolean;

}