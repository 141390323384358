import React from 'react'
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { logout } from '../../services/modules/auth/actions';
import Button from '../ui/Button';

const HamburgerMenu: React.FC = ({ 
}) => { 
  const dispatch = useDispatch();

  const openDrawer = () => {
    dispatch(logout());
  }

  return (<View style={styles.drawerButtonContainer}>   
            <Button onPress={openDrawer} text="Log Out" />
          </View>);
}

const styles = StyleSheet.create({
  drawerButtonContainer: {
    marginRight: 10
  },
});

export default HamburgerMenu;