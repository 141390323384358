import React from 'react'

import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  FlatList,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { Text } from 'react-native';
import Colors from '../../constants/Colors';
import { baseURL } from '../../services/global';
import { FontAwesome } from '@expo/vector-icons';
import { useDispatch } from 'react-redux';
import AccountApi from '../../services/modules/account/api';
import { selectAccount } from '../../services/modules/account/actions';

interface Props {
}

const DriverPendingApproval: React.FC<Props> = ({
}) => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();

  const dispatch = useDispatch();

  const [drivers, setDrivers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [count, setCount] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);

  const viewDriver = (driver: any) => {
    dispatch(selectAccount(driver.id));
    navigation.navigate("Driver");
  }

  const loadDrivers = () => {
    AccountApi.getDriversPendingApproval()
    .then((apiResponse: any) => {
      setDrivers(apiResponse.data);
      setCount("(" + apiResponse.data.length + ")");
      setLoading(false);
    });
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const drawCard = (({ item }: { item: any }) => {
    return (
      <TouchableOpacity onPress={() => viewDriver(item)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            {item.profilePhoto != null && <Image source={{ uri: baseURL + "/account/photo/file/" + item.id + "/" + item.profilePhoto }} resizeMode="cover" style={{ borderRadius: 50, height: 50, width: 50, margin: 10 }} />}
            {item.profilePhoto == null && <Image source={require('../../assets/no_photo.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            <View style={{ marginTop: 5 }}>
              <Text style={styles.cardHeader}>{item.firstName} {item.lastName}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>)
  })

  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        loadDrivers();
      }
    }, [isFocused])
  );

  return (<View>
    {drivers.length > 0 && <View>
        <View style={styles.sectionHeaderView}>
          <View style={{flexDirection: 'row', width: "100%"}}>
            <View style={{flexDirection: 'row', flex: 1}}>
              <Text style={styles.sectionHeader}>DRIVERS PENDING APPROVAL {count}</Text>
            </View>
            <TouchableOpacity onPress={toggleExpanded} style={{paddingRight: 5}}>
              {expanded && <FontAwesome name="arrow-circle-up" color="#CCCCCC" size={30}/>}
              {!expanded && <FontAwesome name="arrow-circle-down" color="#CCCCCC" size={30}/>}
            </TouchableOpacity>
          </View>
        </View>     
        
        {loading &&
          <View style={{ marginTop: 40 }}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.loadingText}>Loading...</Text>
          </View>
        }

        {drivers.length > 0 && !loading && expanded && <View>
          <FlatList
            keyExtractor={(item: any, index) => item.id.toString()}
            data={drivers}
            renderItem={drawCard}
          />
        </View>}
      </View>}
  </View>
  )
};

const styles = StyleSheet.create({
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    margin: 10,
  },

  inputLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  selectBox: {
    padding: 5,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubheader: {
    fontSize: 18,
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 4,
    color: Colors.white
  },

  sectionHeaderView: {
    flexDirection: 'row',
    width: "98%",
    backgroundColor: Colors.primary,
    borderRadius: 10,
    padding: 10
  },

  sectionHeaderButton: {
    margin: 4,
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  searchInputContainer: {
    backgroundColor: Colors.white,
  },

  panelContainer: {   
    backgroundColor: Colors.white, 
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "98%",  
    padding: "1%",
    marginBottom: "1%"
  },
});
export default DriverPendingApproval