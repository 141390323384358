import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  FlatList,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { Text } from 'react-native';
import Colors from '../../constants/Colors';
import { baseURL } from '../../services/global';
import { FontAwesome } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount } from '../../services/modules/account/actions';
import RideApi from '../../services/modules/ride/api';
import { selectRide } from '../../services/modules/ride/actions';
import Moment from 'react-moment';
import { getAPIDate } from '../../services/helpers';
import { RootState } from '../../services/store';

interface Props {
}

const PendingReservationAssignment: React.FC<Props> = ({
}) => {
  const appState = useSelector((state: RootState) => state.app);

  const isFocused = useIsFocused();
  const navigation = useNavigation();

  const dispatch = useDispatch();

  const [reservations, setReservations] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [count, setCount] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);

  const viewReservation = (ride: any) => {
    dispatch(selectRide(ride.id));
    navigation.navigate("ReservationViewer");
  }

  const loadReservations = () => {
    RideApi.getReservationsPendingAssignment()
    .then((apiResponse: any) => {
      setReservations(apiResponse.data);
      setCount("(" + apiResponse.data.length + ")");
      setLoading(false);
    });
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const drawCard = (({ item }: { item: any }) => {
    return (
      <TouchableOpacity onPress={() => viewReservation(item)}>
      <View style={styles.card}>
        <View style={styles.cardHeaderContainer}>
          {item.passengerAccount.profilePhoto != null && <Image source={{ uri: baseURL + "/account/photo/file/" + item.passengerAccount.id + "/" + item.passengerAccount.profilePhoto }} resizeMode="cover" style={{ borderRadius: 50, height: 50, width: 50, margin: 10 }} />}
          {item.passengerAccount.profilePhoto == null && <Image source={require('../../assets/no_photo.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
          <View style={{ marginTop: 5 }}>
            <Text style={styles.cardHeader}>{item.passengerAccount.firstName} {item.passengerAccount.lastName}</Text>
            <Text>{item.pickupLocation.address}</Text>
            <Moment element={Text} format="MMM Do, YYYY hh:mm A" style={{marginRight: 20, marginTop: 5}}>{getAPIDate(item.scheduledTime)}</Moment>
          </View>
        </View>
      </View>
      </TouchableOpacity>)
  })

  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        loadReservations();
      }
    }, [isFocused])
  );

  useEffect(() => {
    if (appState.connection) { 
      appState.connection.on("ReservationAdded", () => {
        loadReservations();
      });
    }

    return () => {
      if (appState.connection) {
        appState.connection.off("ReservationAdded");
      }
    }
  }, [appState.connection])

  return (<View>
    {reservations.length > 0 && <View>
        <View style={styles.sectionHeaderView}>
          <View style={{flexDirection: 'row', width: "100%"}}>
            <View style={{flexDirection: 'row', flex: 1}}>
              <Text style={styles.sectionHeader}>RESERVATIONS PENDING ASSIGNMENT {count}</Text>
            </View>
            <TouchableOpacity onPress={toggleExpanded} style={{paddingRight: 5}}>
              {expanded && <FontAwesome name="arrow-circle-up" color="#CCCCCC" size={30}/>}
              {!expanded && <FontAwesome name="arrow-circle-down" color="#CCCCCC" size={30}/>}
            </TouchableOpacity>
          </View>
        </View>      
        
        {loading &&
          <View style={{ marginTop: 40 }}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.loadingText}>Loading...</Text>
          </View>
        }

        {reservations.length > 0 && !loading && expanded && <View>
          <FlatList
            keyExtractor={(item: any, index) => item.id.toString()}
            data={reservations}
            renderItem={drawCard}
          />
        </View>}
      </View>}
  </View>
  )
};

const styles = StyleSheet.create({
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    margin: 10,
  },

  inputLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  selectBox: {
    padding: 5,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 4,
    color: Colors.white
  },

  sectionHeaderView: {
    flexDirection: 'row',
    width: "98%",
    backgroundColor: Colors.primary,
    borderRadius: 10,
    padding: 10
  },
  
  sectionHeaderButton: {
    margin: 4,
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  searchInputContainer: {
    backgroundColor: Colors.white,
  },
});
export default PendingReservationAssignment