import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect } from 'react'

import {
  View,
  StyleSheet,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../constants/Colors';
import { selectPromoCode } from '../../services/modules/payment/actions';
import Moment from 'react-moment';
import PaymentApi from '../../services/modules/payment/api';
import { Account, PromoCode } from '../../services/types';
import Button from '../ui/Button';
import { getAPIDate } from '../../services/helpers';
import AccountApi from '../../services/modules/account/api';
import { isSameDay, format, isSameYear } from 'date-fns';
import { IMessage, GiftedChat } from 'react-native-gifted-chat';
import { RootState } from '../../services/store';
import { Appbar, List, Badge, FAB, Menu, Provider } from 'react-native-paper';
import { baseURL } from '../../services/global';

interface Props {
}

const Messenger: React.FC<Props> = ({
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [visible, setVisible] = React.useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  const [messages, setMessages] = React.useState<any[]>([]);

  const [allChat, setAllChat] = React.useState<any>(null);

  const [allChatUnread, setAllChatUnread] = React.useState<any>(0);

  const [chats, setChats] = React.useState<any>({});
  const [showChat, setShowChat] = React.useState<Account | any>(null);


  const [drivers, setDrivers] = React.useState<any[]>([]);
  
  const [showDrivers, setShowDrivers] = React.useState(true);
  const [loading, setLoading] = React.useState(true);


  const authState = useSelector((state: RootState) => state.auth);

  const getToAllChat = () => {
    AccountApi.getDriverAllRecentMessage()
      .then((apiResponse: any) => {
        console.error(apiResponse.data);
        setAllChat(apiResponse.data.recentMessage)
      })
      .catch((error) => {
      })
      .finally(() => {
        getAllUnreadCount();
      });
  }



  const appState = useSelector((state: RootState) => state.app);

  const getAllUnreadCount = () => {

    AccountApi.getDriverAllUnreadCount(authState.id)
      .then((apiResponse: any) => {

        setAllChatUnread(apiResponse.data)
      })
      .catch((error) => {

        console.error(error);

      })
      .finally(() => {

      });
  }


  const getChats = () => {

    AccountApi.getDriverDriverChats(authState.id)
      .then((apiResponse: any) => {
        console.error(apiResponse.data);

        var arr: any[] = apiResponse.data.chats
        //console.log(arr);
        setChats(arr)

      })
      .catch((error) => {
      })
      .finally(() => {

      });
  }

  useFocusEffect(


    React.useCallback(() => {
      if (isFocused) {

        getToAllChat();
        getAllUnreadCount();
        getChats();
        getOnlineDrivers();

      }
    }, [isFocused])
  );


  useEffect(() => {

    if (showChat != null) {
      startSignals();
      if (showChat == "all") {
        getAllMessages();
      }
      else {
        getMessages(showChat.id);
      }
    }

  }, [showChat])


  useEffect(() => {
    appState.connection.on("MessageSent", (user: string) => {
      getChats();
    });
    appState.connection.on("AllDriverMessageSent", (messageId: number) => {

      getToAllChat();
    });
  }, [])


  const startSignals = () => {
    appState.connection.on("SetMessageSent", (messageId: number) => {

      updateMessage(messageId);
    });

    appState.connection.on("AllDriverMessageSent", (messageId: number) => {

      getMessage(messageId);

    });


    appState.connection.on("SetMessageReceived", (messageId: number) => {

      updateMessage(messageId);
    });
    appState.connection.on("MessageSent", (messageId: number) => {
      getMessage(messageId);

    });
  }



  const stopSignals = () => {
    appState.connection.off("SetMessageSent");

    appState.connection.off("AllDriverMessageSent");


    appState.connection.off("SetMessageReceived");
    appState.connection.off("MessageSent");

    appState.connection.on("MessageSent", (user: string) => {
      getChats();
    });
    appState.connection.on("AllDriverMessageSent", (messageId: number) => {

      getToAllChat();
    });
  }



  const [currentPage, setCurrentPage] = React.useState(0);


  let amount = 10;



  const getMessage = (id: number) => {

    AccountApi.getMessage(id)
      .then(async (apiResponse: any) => {
        let message = apiResponse.data;

if(showChat == "all" || message.account.id == showChat.id){
          var response = await AccountApi.patchMessage(id, true, '/received', 'replace');
          message = response.data;


          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            sent: message.sent,
            received: message.received,
            pending: false,
            createdAt: new Date(message.dateCreated),
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,

            }
          }

          setMessages((previousMessages: IMessage[] | undefined) => GiftedChat.append(previousMessages, [tempMessage]));


        }
      })
      .catch((error) => {

        console.error(error);
      })
      .finally(() => {
      });

  }


  const updateMessage = (id: number) => {

    AccountApi.getMessage(id)
      .then(async (apiResponse: any) => {
        let message = apiResponse.data;

       
        if(showChat == "all" || message.account.id == showChat.id){


          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            sent: message.sent,
            received: message.received,
            pending: false,
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,

            }
          }



          setMessages((previousMessages: IMessage[] | undefined) => {

            //        GiftedChat.append(previousMessages?.splice(previousMessages.findIndex(m => m._id == id), 1), [tempMessage])
            previousMessages?.splice(previousMessages?.findIndex(m => m._id == id), 1);
            return GiftedChat.append(previousMessages, [tempMessage]);
          });

        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });

  }

  const getAllMessages = () => {
    AccountApi.getDriverAllMessages(authState.id, 0, amount)
      .then((apiResponse: any) => {
        console.error(apiResponse.data);

        let tempAccountMessages: IMessage[] = [];
        let tempOtherMessages: IMessage[] = [];



        let arr: any[] = apiResponse.data.accountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            sent: true,
            received: message.received,
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,
            }
          }


          tempAccountMessages.push(tempMessage)

        })




        setMessages(GiftedChat.append([], [...tempAccountMessages]));

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });

  }

  const getMessages = (otherAccount: string) => {

    AccountApi.getDriverDriverMessages(authState.id, otherAccount, 0, amount)
      .then((apiResponse: any) => {
        console.error(apiResponse.data);

        let tempAccountMessages: IMessage[] = [];
        let tempOtherMessages: IMessage[] = [];



        let arr: any[] = apiResponse.data.accountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            sent: true,
            received: message.received,
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,

            }
          }


          tempAccountMessages.push(tempMessage)

        })


        arr = apiResponse.data.toAccountMessages;


        arr.forEach(async message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            sent: true,
            received: message.received,
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,


            }
          }

          if (!message.received) {
            AccountApi.patchMessage(message.id, true, '/received', 'replace')
              .then((apiResponse: any) => {
                //console.error(apiResponse.data);



              })
              .catch((error) => {
                console.error(error);

              })
              .finally(() => {

              });
          }

          tempAccountMessages.push(tempMessage)

        })

        setMessages(GiftedChat.append([], [...tempOtherMessages, ...tempAccountMessages]));

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });

  }

  useEffect(() => {
    if (currentPage > 0) {
      if (showChat != "all") {
        getMoreMessages();
      }
      else {
        getMoreAllMessages();
      }


    }

  }, [currentPage])


  const getMoreMessages = () => {

    AccountApi.getDriverDriverMessages(authState.id, showChat.id, currentPage, amount)
      .then((apiResponse: any) => {


        let tempAccountMessages: IMessage[] = [];
        let tempOtherMessages: IMessage[] = [];

        let arr: any[] = apiResponse.data.accountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            sent: message.sent,
            received: message.received,
            pending: false,
            createdAt: new Date(message.dateCreated),
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,

            }
          }


          tempAccountMessages.push(tempMessage)

        })


        arr = apiResponse.data.toAccountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,



            }
          }


          if (!message.received) {
            AccountApi.patchMessage(message.id, true, '/received', 'replace')
              .then((apiResponse: any) => {
                //console.error(apiResponse.data);



              })
              .catch((error) => {
                console.error(error);

              })
              .finally(() => {

              });

          }
          tempAccountMessages.push(tempMessage)

        })



        setMessages((previousMessages: IMessage[] | undefined) => GiftedChat.append(previousMessages, tempAccountMessages));

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });

  }



  const getMoreAllMessages = () => {

    AccountApi.getDriverAllMessages(authState.id, currentPage, amount)
      .then((apiResponse: any) => {


        let tempAccountMessages: IMessage[] = [];
        let tempOtherMessages: IMessage[] = [];

        let arr: any[] = apiResponse.data.accountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            sent: message.sent,
            received: message.received,
            pending: false,
            createdAt: new Date(message.dateCreated),
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,

            }
          }


          tempAccountMessages.push(tempMessage)

        })


        arr = apiResponse.data.toAccountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            user: {
              _id: message.toAccount.id,
              name: message.toAccount.firstName + " " + message.toAccount.lastName,


            }
          }


          if (!message.received) {
            AccountApi.patchMessage(message.id, true, '/received', 'replace')
              .then((apiResponse: any) => {
                //console.error(apiResponse.data);



              })
              .catch((error) => {
                console.error(error);

              })
              .finally(() => {

              });
          }
          tempAccountMessages.push(tempMessage)

        })



        setMessages((previousMessages: IMessage[] | undefined) => GiftedChat.append(previousMessages, tempAccountMessages));

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });

  }


  const getMessageTime = (chat: any) => {
    if (chat) {
      if (isSameDay(new Date(), new Date(chat.dateCreated))) { // If same day, show time
        return format(new Date(chat.dateCreated), "p");
      }
      else if (isSameYear(new Date(), new Date(chat.dateCreated))) { // If same year, show month and date
        return format(new Date(chat.dateCreated), "MMM d");
      }
      else { // Else show, month and date and year
        return format(new Date(chat.dateCreated), "MMM d y");
      }
    }
    else {
      return "";
    }
  }




  const sendAllMessage = (message: IMessage[]) => {
    AccountApi.addMessage(message[0].text, false, false, false, true, authState.id, null, null)
      .then(async (apiResponse: any) => {
        
        //console.log(apiResponse.data);
        var response = await AccountApi.patchMessage(apiResponse.data.id, true, '/sent', 'replace');

        var item = response.data;

        let tempMessage: IMessage = {
          _id: item.id,
          text: item.text,
          sent: item.sent,
          received: item.received,
          pending: false,
          createdAt: new Date(item.dateCreated),
          user: {
            _id: item.account.id,
            name: item.account.firstName + " " + item.account.lastName,

          }
        }

        setMessages((previousMessages: IMessage[] | undefined) => GiftedChat.append(previousMessages, [tempMessage]))
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {

      });

  }



  const sendMessage = (message: IMessage[]) => {
    // set pending true, set false after save and signal/push sent and sent true
    AccountApi.addMessage(message[0].text, false, false, true, false, authState.id, null, showChat.id)
      .then(async (apiResponse: any) => {



        //console.log(apiResponse.data);
        var response = await AccountApi.patchMessage(apiResponse.data.id, true, '/sent', 'replace');

        var item = response.data;

        let tempMessage: IMessage = {
          _id: item.id,
          text: item.text,
          sent: item.sent,
          received: item.received,
          pending: false,
          createdAt: new Date(item.dateCreated),
          user: {
            _id: item.account.id,
            name: item.account.firstName + " " + item.account.lastName,

          }
        }



        // arr[arr.indexOf(message[0])] = tempMessage;
        /*  .then(async (apiResponse: any) => {
            //console.error(apiResponse.data);


            let items = [...arr];

            var item = apiResponse.data;

            items[arr.indexOf(message)] = item;
            // 5. Set the state to our new copy
            return items;


          })
          .catch((error) => {
            console.error(error);
            return [];
          })
          .finally(() => {

          });*/


        setMessages(prevMessages => GiftedChat.append(prevMessages, [tempMessage]))
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {

      });

  }

  const getOnlineDrivers = () => {
    //setLoading(true);

    AccountApi.getApprovedDrivers()
      .then((apiResponse: any) => {


console.error(apiResponse.data);
        setDrivers(apiResponse.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

    

  const onSend = (newMessages = []) => {

    if (showChat == "all") {
      sendAllMessage(newMessages);
    }
    else {
      sendMessage(newMessages)
    }

  }

  return (
    <Provider>
      <View>





        {showChat != null &&
          <View style={{ alignSelf: "center", height: 300, width: "100%" }}>

            <Appbar>
              <Appbar.Action
                icon="arrow-left"
                onPress={() => { stopSignals(); setShowChat(null); setCurrentPage(0); }}
              />

              <Appbar.Content title={showChat == "all" ? "All" : showChat.firstName + " " + showChat.lastName} />
            </Appbar>

            <GiftedChat
              messages={messages.sort((a: { createdAt: number; }, b: { createdAt: number; }) => b.createdAt - a.createdAt)}
              loadEarlier={true}
              onSend={onSend}
              onLoadEarlier={() => {
                setCurrentPage(currentPage + 1)
              }}

              showUserAvatar={true}

              user={
                {
                  _id: authState.id,
                  name: authState.firstName + " " + authState.lastName,

                }

              } />

          </View>
        }
        {showChat == null &&
          <>



            <List.Item
              onPress={() => { setShowChat("all");  }}
              style={{ height: 100, padding: 5 }}

              left={() =>
                (<View>
                  <Image source={require('../../assets/no_photo.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />
                </View>)


              }
              title={"All"}
              description={allChat != null ? allChat.text : ""}
              right={() => {
                return (
                  <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                    {allChat && <Text>{getMessageTime(allChat)}</Text>}
                    {allChatUnread > 0 && <Badge>{allChatUnread}</Badge>}
                  </View>
                )
              }}>




            </List.Item>





            {Object.keys(chats).map((key: string, index) => {
              let chat = chats[key].messages;
              let count = chats[key].count;

              return (




                <List.Item
                  onPress={() => { setShowChat(chat.accountId == authState.id ? chat.toAccount : chat.account);  }}
                  style={{ height: 100, padding: 5 }}
                  key={chat.id}
                  left={() => {
                    if (chat.accountId == authState.id) {
                      return (<View>
                        {chat.toAccount.profilePhoto.length > 0 && <Image source={{ uri: baseURL + "/account/photo/file/" + chat.toAccount.id + "/" + chat.toAccount.profilePhoto }} resizeMode="cover" style={{ borderRadius: 50, height: 50, width: 50, margin: 10 }} />}
                        {chat.toAccount.profilePhoto.length == 0 && <Image source={require('../../assets/no_photo.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
                      </View>)
                    }
                    else {
                      return (<View>
                        {chat.account.profilePhoto.length > 0 && <Image source={{ uri: baseURL + "/account/photo/file/" + chat.account.id + "/" + chat.account.profilePhoto }} resizeMode="cover" style={{ borderRadius: 50, height: 50, width: 50, margin: 10 }} />}
                        {chat.account.profilePhoto.length == 0 && <Image source={require('../../assets/no_photo.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
                      </View>)
                    }

                  }}
                  title={chat.accountId == authState.id ? chat.toAccount.firstName + " " + chat.toAccount.lastName : chat.account.firstName + " " + chat.account.lastName}
                  description={chat.text}
                  right={() => {
                    return (
                      <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Text>{getMessageTime(chat)}</Text>
                        {count > 0 && <Badge>{count}</Badge>}
                      </View>
                    )
                  }}>




                </List.Item>




              )



            })}
          </>
        }
        <ScrollView>
          <View  style={{
            paddingTop: 50,
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
          <Menu
            visible={visible}
            onDismiss={closeMenu}
            anchor={
              <FAB
                icon="plus"

                onPress={() => openMenu()}
              />
            }>

            {drivers.map((item: any, index) => (<Menu.Item onPress={() => { setShowChat(item.account);  }} title={item.account.firstName + " " + item.account.lastName} />))}
         

          </Menu>
          </View>
        </ScrollView>



      </View >
    </Provider>)
};

const styles = StyleSheet.create({
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },

  card: {
    backgroundColor: Colors.white,
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardTime: {
    fontSize: 18,
  },

  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 4,
    color: Colors.white
  },

  sectionHeaderView: {
    flexDirection: 'row',
    width: "98%",
    backgroundColor: Colors.primary,
    borderRadius: 10,
    padding: 10
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  button: {
    marginTop: 2,
    marginLeft: 10,
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
  },
});

export default Messenger