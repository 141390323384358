import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Text,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import DriverExpiringSoon from '../components/driver/DriverExpiringSoon';
import DriverPendingApproval from '../components/driver/DriverPendingApproval';
import OnlineDrivers from '../components/driver/OnlineDrivers';
import IdentificationPendingApproval from '../components/passenger/IdentificationPendingApproval';
import StatsTicker from '../components/report/StatsTicker';
import PendingReservation from '../components/ride/PendingReservation';
import PendingReservationAssignment from '../components/ride/PendingReservationAssignment';
import RideQueue from '../components/ride/RideQueue';
import DaySchedule from '../components/schedule/DaySchedule';
import Button from '../components/ui/Button';
import { baseURL } from '../services/global';
import { loadSettings, loadSignalR } from '../services/modules/app/actions';
import AppApi from '../services/modules/app/api';
import { RootState } from '../services/store';

interface Props {
}

const DashboardScreen: React.FC<Props> = ({ 
}) => {   
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const authState = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    AppApi.loadSettings()
    .then((apiResponse: any) => {
      dispatch(loadSettings(apiResponse.data))
    }) 

    setupSignalR()
  }, [])
  
  const setupSignalR = () => {
    const connection = new HubConnectionBuilder()
      .withUrl(baseURL + "/chatHub", {
        accessTokenFactory: async () => {
          return authState.token;
        }
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Warning)
      .build();
  
    connection
      .start()
      .then(() => {
        dispatch(loadSignalR(connection))
      })
      .catch(err => {
        console.log(err)
      });
  }
  
  return (<View> 
            <Text style={styles.header}>Welcome {authState.firstName}</Text>
            <View style={{flexDirection: 'row'}}>
              <View style={styles.mainContainer}>
                <View style={{margin: 10, width: "100%"}}>
                  <PendingReservationAssignment />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <DriverPendingApproval />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <DriverExpiringSoon />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <IdentificationPendingApproval />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <RideQueue />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <OnlineDrivers />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <DaySchedule />
                </View>
              </View>
              <View style={styles.sideContainer}>
                <View style={{margin: 10, width: "100%"}}>
                  <StatsTicker />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <Button text={"Messenger"} onPress={() => navigation.navigate("Messenger")} />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <Button text={"Reservations"} onPress={() => navigation.navigate("Reservations")} />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <Button text={"Push Notifications"} onPress={() => navigation.navigate("PushNotifications")} />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <Button text={"Promo Codes"} onPress={() => navigation.navigate("PromoCodes")} />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <Button text={"Drivers"} onPress={() => navigation.navigate("Drivers")} />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <Button text={"Passengers"} onPress={() => navigation.navigate("Passengers")} />
                </View>
                <View style={{marginHorizontal: 10, marginTop: 10, width: "100%"}}>
                  <Button text={"Payroll Report"} onPress={() => navigation.navigate("PayrollReport")} />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <Button text={"Insurance Report"} onPress={() => navigation.navigate("InsuranceReport")} />
                </View>
                <View style={{marginHorizontal: 10, width: "100%"}}>
                  <Button text={"Cancelation Report"} onPress={() => navigation.navigate("CancelationReport")} />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <Button text={"Driver Report"} onPress={() => navigation.navigate("DriverReport")} />
                </View>
                <View style={{margin: 10, width: "100%"}}>
                  <Button text={"Settings"} onPress={() => navigation.navigate("Settings")} />
                </View>
              </View>
            </View>
          </View>)
};

const styles = StyleSheet.create({ 
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },

  mainContainer: {
    margin: "2%",
    width: "66%",
  },

  sideContainer: {
    marginTop: "2%",
    marginRight: "2%",
    width: "28%",
  },
});

export default DashboardScreen