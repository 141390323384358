import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';

import { Text } from 'react-native';
import { useSelector } from 'react-redux';
import Colors from '../../constants/Colors';
import { RootState } from '../../services/store';
import dayjs from 'dayjs'
import Button from '../ui/Button';
import { baseURL } from '../../services/global';
import { DatePicker } from '../ui/DatePicker';

interface Props {
}

const PayrollReport: React.FC<Props> = ({ 
}) => {   
  const authState = useSelector((state: RootState) => state.auth);

  const [loading, setLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  
  useEffect(() => {
    var start = dayjs().startOf('week')
    var end = dayjs().endOf('week')

    setStartDate(start.toDate());
    setEndDate(end.toDate());
  }, []);

  const downloadExcel = () => {
    setLoading(true);

    let curDate = new Date();
    let filename = "payroll_" + curDate.getDate() + "_" + (curDate.getMonth() + 1) + "_" + curDate.getFullYear() + ".xlsx";
  
    fetch(baseURL + '/report/payrollexcel/' + startDate.toISOString() + '/' + endDate.toISOString(),
    { headers: new Headers({
      'Authorization': 'bearer ' + authState.token, 
      'Content-Type': 'application/x-www-form-urlencoded'
    })})    
    .then(res => res.blob()) 
    .then(blob => {
      try {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false)
      } catch(error) {
        setLoading(false)
        console.log(error)
      }
    });
  }

  const onChangeStartDate = (selectedDate: any) => {
    let storeDate = new Date(selectedDate.target.value + " 00:00:00")
    setStartDate(storeDate)
  }

  const onChangeEndDate = (selectedDate: any) => {
    let storeDate = new Date(selectedDate.target.value + " 23:59:59")
    setEndDate(storeDate)
  };

  return (<View style={styles.componentContainer}>         
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.loadingText}>Loading...</Text>
              </View>
            }
            {!loading && <View>
              <Text style={styles.header}>Enter criteria and click "Export" to download the payroll report.</Text> 
              <Text style={styles.header}>NOTE: this report is to be used as a real-time tool to assist with payroll. It does not keep a historic log of payroll runs (e.g. if you change the % a driver earns, and you run a historic report, the report will show the current % and not the % at the time you ran the report previously).</Text>   
              <View style={{flexDirection: 'row'}}>
                <View style={styles.inputView}>
                  <Text style={styles.inputLabel}>Start Date</Text>
                  <DatePicker
                    value={startDate}
                    onChange={onChangeStartDate} />
                </View>      
                <View style={styles.inputView}>
                  <Text style={styles.inputLabel}>End Date</Text>
                  <DatePicker
                    value={endDate}
                    onChange={onChangeEndDate} />
                </View>   
                <View style={styles.inputView}>  
                  <Button onPress={() => {downloadExcel();}}
                    style={[styles.button,{marginTop: 30}]}
                    text="Export">
                  </Button>
                </View>
              </View>
            </View>}
          </View>
  )
};

const styles = StyleSheet.create({ 
  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    margin: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "98%"
  },
  
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  panel: {
    backgroundColor: Colors.white, 
    width: "28%",
    marginHorizontal: "1%",
    borderWidth: 1, 
    borderRadius: 10, 
    borderColor: Colors.borderGrey,
    padding: 10,
  },

  heading: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  
  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardInfoContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardDetail: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 20,
    borderColor: Colors.borderGrey,
    borderLeftWidth: 1,
  },

  cardDetailStatus: {
    alignSelf: 'flex-start',
    marginTop: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    backgroundColor: Colors.primary,
    color: Colors.white,
  },

  button : {
    marginTop: 10
  },
  
  header: {
    fontSize: 16,
    marginLeft: 5,
  },
  
  inputView: {
    width: "30%",
    padding: 10,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
    marginRight: 10,
  },
});
export default PayrollReport