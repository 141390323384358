import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../constants/Colors';
import { baseURL } from '../../services/global';
import { selectRide } from '../../services/modules/ride/actions';
import RideApi from '../../services/modules/ride/api';
import { selectScheduleEntry } from '../../services/modules/schedule/actions';
import ScheduleApi from '../../services/modules/schedule/api';
import { RootState } from '../../services/store';
import { DatePicker } from '../ui/DatePicker';
import { FontAwesome } from '@expo/vector-icons';
import { selectAccount } from '../../services/modules/account/actions';
import AccountApi from '../../services/modules/account/api';
import { DriverStatuses } from '../../services/types';

interface Props {
}

const OnlineDrivers: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const appState = useSelector((state: RootState) => state.app);

  const [drivers, setDrivers] = React.useState([]);
  
  const [loading, setLoading] = React.useState(true);
  const [expanded, setExpanded] = React.useState(true);

  const viewDriver = (accountId: any) => {
    dispatch(selectAccount(accountId));
    navigation.navigate("Driver");
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const loadOnlineDrivers = () => {  
    AccountApi.getOnlineDrivers()
    .then((apiResponse: any) => {
      setDrivers(apiResponse.data)
      setLoading(false)
    })
  }

  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        loadOnlineDrivers();
      }
    }, [isFocused])
  );

  useEffect(() => {
    if (appState.connection) { 
      appState.connection.on("DriverStatusChanged", () => {
        loadOnlineDrivers();
      });
    }

    return () => {
      if (appState.connection) {
        appState.connection.off("DriverStatusChanged");
      }
    }
  }, [appState.connection])

  const drawCard = (({ item }: { item: any }) => {
    return (
      <TouchableOpacity onPress={() => viewDriver(item.account.id)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
          {item.account.profilePhoto.length > 0 && <Image source={{ uri: baseURL + "/account/photo/file/" + item.account.id + "/" + item.account.profilePhoto }} resizeMode="cover" style={{ borderRadius: 50, height: 50, width: 50, margin: 10 }} />}
            {item.account.profilePhoto.length == 0 && <Image source={require('../../assets/no_photo.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            <View style={{ marginTop: 5 }}>
              <Text style={styles.cardHeader}>{item.account.firstName} {item.account.lastName}</Text>
              <Text style={styles.cardSubHeader}>{item.available == 0 ? "Not on ride" : "On ride"}</Text>
              
              <Text style={styles.cardSubHeader}>{item.account.statusId == DriverStatuses['Paused'] ? "On break" : ""}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>)
  })

  return (<View>     
    <View style={styles.sectionHeaderView}>
      <View style={{flexDirection: 'row', width: "100%"}}>
        <View style={{flexDirection: 'row', flex: 1}}>
          <Text style={styles.sectionHeader}>Drivers Online</Text>
        </View>
        <TouchableOpacity onPress={toggleExpanded} style={{paddingRight: 5}}>
          {expanded && <FontAwesome name="arrow-circle-up" color="#CCCCCC" size={30}/>}
          {!expanded && <FontAwesome name="arrow-circle-down" color="#CCCCCC" size={30}/>}
        </TouchableOpacity>
      </View>
    </View>  

    {loading && expanded &&
      <View style={{marginTop: 40}}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.loadingText}>Loading...</Text>
      </View>}

    {!loading && expanded &&
      <View style={{margin: 10}}>
        {drivers.length > 0 && <View>
          <FlatList
            keyExtractor={(item: any, index) => item.account.id.toString()}
            data={drivers}
            renderItem={drawCard}
          />
        </View>}
        {drivers.length == 0 && <View style={{alignItems: 'center'}}>
          <Text style={styles.header}>No drivers online</Text>
        </View>}
      </View>}
  </View>)
};

const styles = StyleSheet.create({ 
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },
  
  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
  
  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },
  
  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },
  
  cardTime: {
    fontSize: 18,
  },

  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 4,
    color: Colors.white
  },

  sectionHeaderView: {
    flexDirection: 'row',
    width: "98%",
    backgroundColor: Colors.primary,
    borderRadius: 10,
    padding: 10
  },
  
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  
  button : {
    marginTop: 2,
    marginLeft: 10,
  },
});

export default OnlineDrivers