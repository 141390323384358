import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';

import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import { Text } from 'react-native';
import ProjectApi from '../../services/modules/ride/api';
import Colors from '../../constants/Colors';
import RideApi from '../../services/modules/ride/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { endOfDay, endOfWeek, endOfYear, startOfDay, startOfWeek, startOfYear } from 'date-fns';

interface Props {
}

const StatsTicker: React.FC<Props> = ({ 
}) => {   
  const isFocused = useIsFocused()

  const appState = useSelector((state: RootState) => state.app);

  const [totalCancelled, setTotalCancelled] = React.useState(0);
  const [totalCompleted, setTotalCompleted] = React.useState(0);
  const [totalInProgress, setTotalInProgress] = React.useState(0);
  const [totalOverallCompleted, setTotalOverallCompleted] = React.useState(0);
  const [revenueToday, setRevenueToday] = React.useState(0);
  const [revenueThisWeek, setRevenueThisWeek] = React.useState(0);
  const [revenueThisYear, setRevenueThisYear] = React.useState(0);

  const [loading, setLoading] = React.useState(true);
  
  const init = () => {
    setLoading(true);

    RideApi.statsToday(startOfDay(new Date()), endOfDay(new Date()), startOfWeek(new Date()), endOfWeek(new Date()), startOfYear(new Date()), endOfYear(new Date()))
    .then((apiResponse: any) => {
      setTotalCancelled(apiResponse.data.cancelled);
      setTotalCompleted(apiResponse.data.complete);
      setTotalInProgress(apiResponse.data.inProgress);
      setTotalOverallCompleted(apiResponse.data.completeOverall);
      setRevenueToday(apiResponse.data.revenueToday);
      setRevenueThisWeek(apiResponse.data.revenueThisWeek);
      setRevenueThisYear(apiResponse.data.revenueThisYear);
      setLoading(false);
    });
  }

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  useEffect(() => {
    if (appState.connection) { 
      appState.connection.on("RideAssigned", () => {
        init();
      });

      appState.connection.on("RideCancelled", () => {
        init();
      });

      appState.connection.on("RideAssignedQueued", () => {
        init();
      });

      appState.connection.on("QueuedRideCancelled", () => {
        init();
      });

      appState.connection.on("QueueChanged", () => {
        init();
      });
      
      appState.connection.on("ReceiveMessage", () => {
        init();
      });
    }

    return () => {
      if (appState.connection) {
        appState.connection.off("RideAssigned");
        appState.connection.off("RideCancelled");
        appState.connection.off("RideAssignedQueued");
        appState.connection.off("QueuedRideCancelled");
        appState.connection.off("QueueChanged");
        appState.connection.off("ReceiveMessage");
      }
    }
  }, [appState.connection])
  
  return (<View>       
    {loading &&
      <View style={{marginTop: 40}}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.loadingText}>Loading...</Text>
      </View>
    }
    {!loading && <View>    
      <View style={styles.componentContainer}>            
        <View style={styles.sectionHeaderView}>
          <Text style={styles.sectionHeader}>RIDES TODAY</Text>
        </View>
        <View style={styles.container}>
          <View style={styles.statsContainer33}>
            <Text style={styles.statsHeader}>In Progress</Text>
            <Text style={styles.stat}>{totalInProgress}</Text>
          </View>
          <View style={styles.statsContainer33}>
            <Text style={styles.statsHeader}>Completed</Text>
            <Text style={styles.stat}>{totalCompleted}</Text>
          </View>
          <View style={styles.statsContainer33}>
            <Text style={styles.statsHeader}>Cancelled</Text>
            <Text style={styles.stat}>{totalCancelled}</Text>
          </View>
        </View>     
      </View>
      <View style={[styles.componentContainer, {marginTop: "2%"}]}>    
        <View style={styles.sectionHeaderView}>
          <Text style={styles.sectionHeader}>ALL RIDES</Text>
        </View>
        <View style={styles.container}>
          <View style={styles.statsContainer100}>
            <Text style={styles.statsHeader}>Completed</Text>
            <Text style={styles.stat}>{totalOverallCompleted}</Text>
          </View>
        </View>
      </View>   
      <View style={[styles.componentContainer, {marginTop: "2%"}]}>           
        <View style={styles.sectionHeaderView}>
          <Text style={styles.sectionHeader}>REVENUE</Text>
        </View>
        <View style={styles.container}>
          <View style={styles.statsContainer33}>
            <Text style={styles.statsHeader}>Today</Text>
            <Text style={styles.stat}>${revenueToday.toFixed(2)}</Text>
          </View>
          <View style={styles.statsContainer33}>
            <Text style={styles.statsHeader}>This Week</Text>
            <Text style={styles.stat}>${revenueThisWeek.toFixed(2)}</Text>
          </View>
          <View style={styles.statsContainer33}>
            <Text style={styles.statsHeader}>This Year</Text>
            <Text style={styles.stat}>${revenueThisYear.toFixed(2)}</Text>
          </View>
        </View>     
      </View>
    </View>}
  </View>
  )
};

const styles = StyleSheet.create({ 
  stat: {
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  statsHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  statsContainer33: {
    width: "33%",
  },
  statsContainer50: {
    width: "50%",
  },
  statsContainer100: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    textAlign: 'center',
    marginBottom: 10,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  componentContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "100%"
  },
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  container: {
    flexDirection: 'row',
    padding: 10,
  },
});
export default StatsTicker