import React from 'react';
import { StyleSheet, Dimensions, TouchableOpacity, View, Text, ActivityIndicator } from 'react-native';




import { MaterialCommunityIcons } from '@expo/vector-icons';
import AccountApi from '../../services/modules/account/api';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';

import { Rating } from 'react-native-ratings';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import Colors from '../../constants/Colors';
import { Card, ListItem } from 'react-native-elements';
import { format } from 'date-fns';

interface Props {

}

const DriverRatings: React.FC<Props> = ({

}) => {

  const [loading, setLoading] = React.useState(false);

  const [ratings, setRatings] = React.useState<any>(null);
  const [comment, setComment] = React.useState<number>(0);
  const [rating, setRating] = React.useState<number>(0);
  const isFocused = useIsFocused()

  const accountState = useSelector((state: RootState) => state.account);

  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        loadStatus();
      }
    }, [isFocused])
  );

  const loadStatus = () => {
    setLoading(true)

    AccountApi.getDriverRatings(accountState.id)
      .then((apiResponse: any) => {
        setLoading(false)
        console.log(apiResponse.data);
        setRatings(apiResponse.data);

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {

      });
  }



  const CommentCardContent = (props: any) => {

    return (

      <ListItem  key={props.comment.id} bottomDivider >
          <ListItem.Content>
        <ListItem.Title>{props.comment.comment}</ListItem.Title>
        <ListItem.Subtitle>{props.comment.passengerAccount.firstName} {props.comment.passengerAccount.lastName} on {format(new Date(props.comment.dropoffTime), "MMM d y p")}</ListItem.Subtitle>
        </ListItem.Content>
      </ListItem>
    );


  }
  return (
    <View>

      <Card>
        <Card.Title>Ratings</Card.Title>
        {loading &&
          <View>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.searchingText}>Loading...</Text>
          </View>
        }
        <Rating
          type='star'
          ratingColor='#3498db'
          ratingBackgroundColor='#c8c7c8'
          ratingCount={5}
          fractions={0}
          jumpValue={0}
          minValue={0}
          imageSize={30}
          startingValue={rating}
          onFinishRating={(rate: number) => { setRating(rate); }}
          style={{ paddingVertical: 10 }}
        />

        {ratings && ratings[rating] &&




          <Card>
            <Card.Title>{"" + ratings[rating]['count'] + " rides"}</Card.Title>

            {ratings[rating]['comments'].length == 0 &&
              <Text >No comments</Text>
            }



            {ratings[rating]['comments'].length > 0 && ratings[rating]['comments'].map((item: any, index: any) => (
              <CommentCardContent comment={item} />
            ))
            }




          </Card>
        }

      </Card>

    </View >


  )
};

const width = Dimensions.get('window').width


const styles = StyleSheet.create({
  headerButton: {
    margin: 5,
  },

  mainContainer: {
    margin: "2%",
    width: "66%",
  },

  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  sideContainer: {
    marginTop: "2%",
    marginRight: "2%",
    width: "28%",
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  image: {
    width: 220,
    height: 220,
    resizeMode: 'contain',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button: {
    width: width - 20,
    margin: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    color: Colors.borderGrey,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
    margin: 10
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton: {
    margin: 4,
  },
});

export default DriverRatings