export const createAccount = () => {
  return {
    id: '',    
    firstName: '',
    lastName: '',
    role: '',
    userName: '',
    email: '',
    password: '',
    token: '',
    refreshToken: '',
    photo: '',
    vehicleId: '',
    metric: 0,
    city: '',
    cityLongitude: 0,
    cityLatitude: 0,
    phoneNumber: '',
    profilePhotoDate: "",
    profilePhotoData: '',
    toggleMarkerImage: false,
    isOnRide: false
  }
}

export const createRide = () => {
  return {
    id: 0,
    driverAccountId: "",
    passengerAccountId: "",
    rating: 0,
    tip: 0,
    distanceEstimated: 0,
    distanceActual: 0,
    durationEstimated: 0,
    durationActual: 0,
    costEstimated: 0,
    costActual: 0,
    pickupLocation: createLocation(),
    destinationLocation: createLocation(),
    typeId: 0,
    statusId: 0,
    stops: [],
    seats: 0,
    luggage: false,
    note: "",
    currentStopIndex: 0,
    queued: false,
    waitTime: 0,
    cancelReason: "",
    passengerAccount: createAccount(),
    driverAccount: createAccount(),
    status: createStatus(),
    scheduledTime: "",
    destinationLatitudeActual: 0,
    destinationLongitudeActual: 0
  }
}

export const createStatus = () => {
  return {
    status: ''
  }
}

export const createLocation = () => {
  return {
    id: 0,
    title: "",
    address: "",
    addressOptional: "",
    city: "",
    latitude: 0,
    longitude: 0,
    province: "",
    postalCode: ""
  }
};

export const createScheduleEntry = () => {
  return {
    id: 0,    
    accountId: '',
    scheduledTime: new Date(),
    duration: 0
  }
};

export const createPromoCode = () => {
  return {
    id: 0,   
    title: '', 
    accountId: '',
    startDate: new Date(),
    expiryDate: new Date(),
    noExpiry: false,
    everyone: false,
    system: false,
    singleUse: false,
    percent: 0
  }
};

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date: Date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      // padTo2Digits(date.getSeconds()),  
    ].join(':')
  );
}

export const minutesToHm = (d: number) => {
  var h = Math.floor(d / 60);
  var m = Math.floor(d % 60);

  var hDisplay = h > 0 ? h + "h " : "";
  var mDisplay = m > 0 ? m + "m " : "";
  return hDisplay + mDisplay;
}

export const getAPIDate = (date: string) => {
  return new Date(date + "-0000");
}