import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  RefreshControl,
  Image,
  Text,
  ScrollView,
  TouchableOpacity,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import GoogleMapReact from 'google-map-react';
import Colors from '../../constants/Colors';
import RideApi from '../../services/modules/ride/api';
import { createAccount, createRide } from '../../services/helpers';
import AccountApi from '../../services/modules/account/api';
import { baseURL } from '../../services/global';
import VehicleApi from '../../services/modules/vehicle/api';
import DriverStatsTicker from '../report/DriverStatsTicker';
import { Appbar, Badge, List } from 'react-native-paper';
import { isSameDay, format, isSameYear } from 'date-fns';
import { IMessage, GiftedChat } from 'react-native-gifted-chat';
import { Ride } from '../../services/types';
import { selectRide } from '../../services/modules/ride/actions';
import PassengerStatsTicker from '../report/PassengerStatsTicker';

interface Props {
}

const PassengerViewer: React.FC<Props> = ({
}) => {
  const isFocused = useIsFocused()
  const navigation = useNavigation()


  const accountState = useSelector((state: RootState) => state.account);
  const authState = useSelector((state: RootState) => state.auth);

  const [approved, setApproved] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [licencesLoaded, setLicencesLoaded] = React.useState(false);
  const [ridesLoading, setRidesLoading] = React.useState(false);

  const [driver, setDriver] = React.useState(createAccount());

  const [licences, setLicences] = React.useState([]);


  const dispatch = useDispatch();

  const [ridePage, setRidePage] = React.useState(0);
  let rideAmount = 5;

  const [rides, setRides] = React.useState<Ride[] | null>(null);

  const getRides = (id: any) => {
setRidesLoading(true);
    RideApi.getAllRidesForPassenger(id, ridePage, rideAmount)
      .then((apiResponse: any) => {

        setRides(apiResponse.data);
       
        // console.log(apiResponse.data)
      })
      .catch((error) => {
        console.error(error);
        //setImage("");
      })
      .finally(() => {
        setRidesLoading(false);
      });


  }


  const getMoreRides = (id: any) => {
    setRidesLoading(true);
    RideApi.getAllRidesForPassenger(id, ridePage, rideAmount)
      .then((apiResponse: any) => {

        if (rides) {
          setRides([...rides, ...apiResponse.data]);
        }
        else {
          setRides(apiResponse.data);
        }


        // console.log(apiResponse.data)
      })
      .catch((error) => {
        console.error(error);
        //setImage("");
      })
      .finally(() => {
        setRidesLoading(false);
      });


  }


  const close = (() => {
    navigation.goBack();
  });

  const suspend = () => {
    AccountApi.patchAccount(accountState.id, "/suspended", true)
      .then((apiResponse: any) => {
        setApproved(true);
      })
  }

  const unsuspend = () => {
    AccountApi.patchAccount(accountState.id, "/suspended", false)
      .then((apiResponse: any) => {
        setApproved(false);
      })
  }

  const approveLicence = (id: number) => {
    AccountApi.approveDiscountID(id)
      .then((apiResponse: any) => {
        loadLicences()
      })
  }

  const unapproveLicence = (id: number) => {
    AccountApi.unapproveDiscountID(id)
      .then((apiResponse: any) => {
        loadLicences()
      })
  }

  const viewRide = (id: any) => {
    dispatch(selectRide(id));
    navigation.navigate("RideViewer");
  }

  const downloadLicence = async (filename: string) => {
    fetch(baseURL + "/account/photo/discountidentification/file/" + accountState.id + "/" + filename,
      {
        headers: new Headers({
          'Authorization': 'bearer ' + authState.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.blob())
      .then(blob => {
        try {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.log(error)
        }
      });
  }


  const loadLicences = () => {
    setLicencesLoaded(false)
    AccountApi.getDiscountIDList(accountState.id)
      .then((apiResponse: any) => {
        setLicences(apiResponse.data)
        setLicencesLoaded(true)
      })
  }



  useEffect(() => {
    getMoreRides(accountState.id);
  }, [ridePage]);

  useEffect(() => {
    AccountApi.getAccount(accountState.id)
      .then((apiResponse: any) => {
        console.log(apiResponse.data.suspended);
        setApproved(apiResponse.data.suspended)
        setDriver(apiResponse.data)

        setLoaded(true)
      })
    getRides(accountState.id);
    loadLicences()

  }, [accountState])




  return (<ScrollView
    contentInsetAdjustmentBehavior="automatic">

    {!loaded &&
      <View>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.searchingText}>Loading...</Text>
      </View>
    }

    {loaded && <View>
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.mainContainer}>
          <View style={styles.sectionContainer}>
            <View style={styles.sectionHeaderView}>
              <Text style={styles.sectionHeader}>DETAILS</Text>
            </View>
            <Text style={styles.inputLabel}>Status</Text>
            <View style={[styles.inputBox, { flexDirection: 'row' }]}>
              <Text style={[styles.inputText, { marginRight: 20, marginTop: 5 }]}>{approved ? 'Suspended' : 'Not Suspended'}</Text>
              {!approved && <Button text='Suspended' onPress={() => { suspend(); }} />}
              {approved && <Button text='Unsuspended' onPress={() => { unsuspend(); }} />}
            </View>
            <Text style={styles.inputLabel}>Name</Text>
            <View style={styles.inputBox}>
              <Text style={styles.inputText}>{driver.firstName} {driver.lastName}</Text>
            </View>
            <Text style={styles.inputLabel}>Email</Text>
            <View style={styles.inputBox}>
              <Text style={styles.inputText}>{driver.userName}</Text>
            </View>
            <Text style={styles.inputLabel}>City</Text>
            <View style={styles.inputBox}>
              <Text style={styles.inputText}>{driver.city}</Text>
            </View>
          </View>








          <View style={styles.sectionContainer}>
            <View style={styles.sectionHeaderView}>
              <Text style={styles.sectionHeader}>DOCUMENTATION</Text>
            </View>
            <Text style={styles.inputLabel}>Discount Identifications</Text>
            <View style={styles.inputBox}>
              {!licencesLoaded &&
                <View>
                  <ActivityIndicator size="large" color="#3F51B5" />
                  <Text style={styles.searchingText}>Loading...</Text>
                </View>
              }
              {licences.length == 0 && licencesLoaded && <Text>No identifications on file.</Text>}
              {licences.length > 0 && licences.map((licence: any) => {
                return (
                  <View style={{ flexDirection: 'row', marginBottom: 5, marginTop: 5 }} key={licence.id}>
                    <Moment element={Text} format="MMM Do, YYYY" style={{ marginRight: 20, marginTop: 5 }}>{licence.dateCreated}</Moment>
                    <Text style={[styles.inputText, { marginRight: 20, marginTop: 5 }]}>{licence.approved ? 'Approved' : 'Not Approved'}</Text>
                    {!licence.approved && <Button text='Approve' onPress={() => { approveLicence(licence.id) }} />}
                    {licence.approved && <Button text='Unapprove' onPress={() => { unapproveLicence(licence.id) }} />}
                    <Button text='View' onPress={() => { downloadLicence(licence.photo) }} style={{ marginLeft: 10 }} />
                  </View>
                )
              })}
            </View>
          </View>






          <View style={styles.sectionContainer}>
            <View style={styles.sectionHeaderView}>
              <Text style={styles.sectionHeader}>RIDE HISTORY</Text>
            </View>
            <View style={styles.inputBox}>
              {ridesLoading &&
                <View>
                  <ActivityIndicator size="large" color="#3F51B5" />
                  <Text style={styles.searchingText}>Loading...</Text>
                </View>
              }
              {(rides == null || rides.length == 0) && !ridesLoading && <Text>No rides on file.</Text>}
              {rides && rides.length > 0 && rides.map((item: any) => {
                return (
                  <TouchableOpacity onPress={() => viewRide(item.id)}>
                    <View style={styles.card}>
                      <View style={styles.cardHeaderContainer}>
                        {item.driverAccount.profilePhoto != null && <Image source={{ uri: baseURL + "/account/photo/file/" + item.driverAccount.id + "/" + item.driverAccount.profilePhoto }} resizeMode="cover" style={{ borderRadius: 50, height: 50, width: 50, margin: 10 }} />}
                        {item.driverAccount.profilePhoto == null && <Image source={require('../../assets/no_photo.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
                        <View style={{ marginTop: 5 }}>
                          {item.driverAccountId != item.passengerAccountId && <Text style={styles.cardHeader}>Ride #{item.id}: {item.status.status}</Text>}
                          <Text style={styles.cardHeader}>{item.passengerAccount.firstName} {item.passengerAccount.lastName}</Text>
                          {item.driverAccountId == item.passengerAccountId && <Text style={styles.cardHeader}>In Queue</Text>}
                          {item.driverAccountId != item.passengerAccountId && <Text style={styles.cardSubHeader}>{item.driverAccount.firstName} {item.driverAccount.lastName}</Text>}
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                )
              })}
            </View>

            <Button onPress={() => { setRidePage(ridePage + 1) }} text={"Load more..."}></Button>
          </View>

          <Button onPress={close}
            style={styles.button}
            text="Close">
          </Button>
        </View>
        <View style={styles.sideContainer}>
          <View style={{ margin: 10, width: "100%" }}>
            <PassengerStatsTicker />
          </View>
        </View>
      </View>
    </View>}
  </ScrollView >
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  headerButton: {
    margin: 5,
  },

  mainContainer: {
    margin: "2%",
    width: "66%",
  },

  sideContainer: {
    marginTop: "2%",
    marginRight: "2%",
    width: "28%",
  },

  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button: {
    width: width - 20,
    margin: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    color: Colors.borderGrey,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
    margin: 10
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton: {
    margin: 4,
  },
  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
  },

  cardSubBody: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardTime: {
    fontSize: 18,
  },
});
export default PassengerViewer