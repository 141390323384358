import { HubConnection } from '@microsoft/signalr';
import { Dispatch } from 'redux';
import { LOAD_SETTINGS, LOAD_SIGNALR,AppDispatchTypes } from './types';

export const loadSettings = (googleKey: string) => async (dispatch: Dispatch<AppDispatchTypes>) => {
  dispatch({type: LOAD_SETTINGS, payload: googleKey});
}

export const loadSignalR = (connection: HubConnection) => async (dispatch: Dispatch<AppDispatchTypes>) => {
  dispatch({type: LOAD_SIGNALR, payload: connection});
}