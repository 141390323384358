import axiosInstance from "../../interceptor";

const AuthApi = {
  login: (uid: string, pass: string) => axiosInstance.post('/auth/login', { userName: uid, password: pass }),
  updateLocation: (latitude: number, longitude: number) => axiosInstance.post('/account/location', { latitude: latitude, longitude: longitude }),
  refresh: (token: number, refreshToken: number) => axiosInstance.post('/auth/refresh', { token: token, refreshToken: refreshToken }),






  updateName: (firstName: string, lastName: string) => axiosInstance.patch('/account/patch', [{
    "value": firstName,
    "path": "/firstName",
    "op": "replace"
  }, {
    "value": lastName,
    "path": "/lastName",
    "op": "replace"
  }]),



};

export default AuthApi;
