import { baseURL } from "../../global";
import axiosInstance from "../../interceptor";
import { ScheduleEntry } from "../../types";

const ScheduleApi = {
  save: (id: number, type: string, year: string, make: string, model: string, plateNumber: string, color: string) => axiosInstance.post('/vehicle/save', {id: id, type: type, year: year, make: make, model: model, plateNumber: plateNumber, color: color}),
  getDaySchedule: (startDate: Date, endDate: Date) => axiosInstance.get('/schedule/day/' + startDate.toISOString() + '/' + endDate.toISOString()),
  getScheduleEntry: (id: number) => axiosInstance.get('/schedule/' + id),
  update: (scheduleEntry: ScheduleEntry) => axiosInstance.post('/schedule/save', { id: scheduleEntry.id, accountId: scheduleEntry.accountId, scheduledTime: scheduleEntry.scheduledTime.toISOString(), duration: scheduleEntry.duration}),
};

export default ScheduleApi;