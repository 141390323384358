import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react'

import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  TextInput,
  ActivityIndicator,
  Dimensions,
  TouchableOpacity,
  FlatList,
} from 'react-native';

import Button from '../../components/ui/Button';
import { DatePicker } from '../../components/ui/DatePicker';
import Colors from '../../constants/Colors';
import { createPromoCode, createScheduleEntry, getAPIDate } from '../../services/helpers';
import ScheduleApi from '../../services/modules/schedule/api';
import { Account, PromoCode, ScheduleEntry } from '../../services/types';
import AccountApi from '../../services/modules/account/api';
import { TimePicker } from '../ui/TimePicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import PaymentApi from '../../services/modules/payment/api';
import Checkbox from 'expo-checkbox';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';

interface Props {
}

const PromoCodeEditor: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();

  const paymentState = useSelector((state: RootState) => state.payment);

  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [searched, setSearched] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [showAccountSearch, setShowAccountSearch] = React.useState(false);

  const [promoCode, setPromoCode] = React.useState<PromoCode>(createPromoCode())

  const [expiryDate, setExpiryDate] = React.useState("");
  const [expiryTime, setExpiryTime] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);

  const [promoCodeAccounts, setPromoCodeAccounts] = React.useState<Account[]>([])
  const [searchResults, setSearchResults] = React.useState([])

  const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

  const savePromoCode = () => {
    if (promoCode.title.length == 0) {
      alert("Please enter a title for this promo code.");
      return;
    }

    setSaving(true);
    PaymentApi.updatePromoCode(promoCode)
    .then((apiResponse: any) => {
      setPromoCode({...promoCode, id: apiResponse.data.data})
      setSaving(false);
      
      if (promoCode.everyone) {
        close();
      } else {
        if (saved) {
          close();
        } else {
          setSaved(true)
        }
      }
    });
  }

  const removeAccount = (id: string) => {
    let remainingAccounts = promoCodeAccounts.filter(( obj: any ) => {
      return obj.id !== id;
    });
    setPromoCodeAccounts(remainingAccounts)

    PaymentApi.removeAccountPromoCode(id, promoCode.id)
    .then(() => {
    })
  }

  const addAccount = (account: Account) => {
    let currentAccounts = promoCodeAccounts;
    currentAccounts.push(account);
    setPromoCodeAccounts(currentAccounts);

    PaymentApi.addAccountPromoCode(account.id, promoCode.id, new Date())
    .then(() => {
      setShowAccountSearch(false);
    })
  }

  const updateSearch = (text: string) => {
    setKeyword(text)
    if (text.length > 2) {
      setSearching(true)
      AccountApi.searchPassengers(text)
      .then((apiResponse: any) => {
        setSearchResults(apiResponse.data);
        setSearching(false);
        setSearched(true);
      });
    } else if (text.length == 0) {
      setSearchResults([]);
    }
  }

  const clearSearch = () => {
    setKeyword(undefined);
  }

  const close = (() => {
    navigation.goBack();
  });

  const onChangeExpiryDate = (selectedDate: any) => {
    setExpiryDate(selectedDate.target.value)
    let storeDate = new Date(selectedDate.target.value + " " + expiryTime)
    setPromoCode({...promoCode, expiryDate: storeDate})
  };

  const onChangeExpiryTime = (selectedDate: any) => {
    setExpiryTime(selectedDate.target.value)
    let storeDate = new Date(expiryDate + " " + selectedDate.target.value)
    setPromoCode({...promoCode, expiryDate: storeDate})
  };

  const onChangeStartDate = (selectedDate: any) => {
    setStartDate(selectedDate.target.value)
    let storeDate = new Date(selectedDate.target.value + " " + startTime)
    setPromoCode({...promoCode, startDate: storeDate})
  };

  const onChangeStartTime = (selectedDate: any) => {
    setStartTime(selectedDate.target.value)
    let storeDate = new Date(startDate + " " + selectedDate.target.value)
    setPromoCode({...promoCode, startDate: storeDate})
  };

  const drawCard = (({ item }: { item: any }) => {
    return (
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            <View style={{ marginTop: 5, flexDirection: 'row' }}>
              <Text style={styles.cardHeader}>{item.firstName} {item.lastName}</Text>
              <Button text={"Remove"} onPress={() => removeAccount(item.id)} style={{marginLeft: 10}}/>
            </View>
          </View>
        </View>)
  })

  const drawAccountCard = (({ item }: { item: any }) => {
    return (
        <View style={styles.card}>
          <TouchableOpacity onPress={() => addAccount(item)}>
            <View style={styles.cardHeaderContainer}>
              <View style={{ marginTop: 5 }}>
                <Text style={styles.cardHeader}>{item.firstName} {item.lastName}</Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>)
  })

  useEffect(() => {
    if (paymentState.id == 0) {
      let storeDate = new Date();
      storeDate.setHours(8,0,0,0);
      setPromoCode({...promoCode, startDate: storeDate, expiryDate: storeDate});
      setExpiryDate(storeDate.getFullYear() + "-" + (storeDate.getMonth() + 1) + "-" + storeDate.getDate());
      setExpiryTime("08:00");
      setStartDate(storeDate.getFullYear() + "-" + (storeDate.getMonth() + 1) + "-" + storeDate.getDate());
      setStartTime("08:00");
    } else {
      PaymentApi.getPromoCode(paymentState.id)
      .then((apiResponse: any) => {
        let startStoreDate = getAPIDate(apiResponse.data.startDate);
        let expiryStoreDate = getAPIDate(apiResponse.data.expiryDate);
        setPromoCode({...apiResponse.data, startDate: startStoreDate, expiryDate: expiryStoreDate});

        setStartDate(startStoreDate.getFullYear() + "-" + (startStoreDate.getMonth() + 1) + "-" + startStoreDate.getDate());
        setStartTime(startStoreDate.getHours() + ":" + startStoreDate.getMinutes());

        setExpiryDate(expiryStoreDate.getFullYear() + "-" + (expiryStoreDate.getMonth() + 1) + "-" + expiryStoreDate.getDate());
        setExpiryTime(expiryStoreDate.getHours() + ":" + expiryStoreDate.getMinutes());

        PaymentApi.getPromoCodeAccounts(apiResponse.data.id)
        .then((apiResponse: any) => {
          setPromoCodeAccounts(apiResponse.data)
        })

        setSaved(true);
      })
    }
  }, [paymentState])

  return ( 
    <ScrollView
      contentInsetAdjustmentBehavior="automatic">
      <View style={styles.container}>        
        {loading &&
          <View style={{marginTop: 40}}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.searchingText}>Loading...</Text>
          </View>
        }

        {saving &&
          <View style={{marginTop: 40}}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.searchingText}>Saving...</Text>
          </View>
        }

        {showAccountSearch && <View style={{padding: 10}}>
          <Button onPress={() => setShowAccountSearch(false)} text={"Cancel"} />
          <Text style={{fontSize: 16, padding: 10}}>Start typing the persons name then click them.</Text>          
          <SafeSearchBar
            platform={"default"}
            searchIcon={false}
            onChangeText={updateSearch}
            onCancel={clearSearch}
            value={keyword}
            placeholder="Search"
            inputContainerStyle={styles.searchInputContainer}
            inputStyle={styles.searchInput}
            containerStyle={styles.searchContainer}
          />     

          {searching &&
            <View style={{marginTop: 40}}>
              <ActivityIndicator size="large" color="#3F51B5" />
              <Text style={styles.searchingText}>Searching...</Text>
            </View>
          }

          {searchResults.length == 0 && !searching && searched && <Text style={styles.cardHeader}>No accounts found</Text>}
          {searchResults.length > 0 && !searching && <View>
            <FlatList
              keyExtractor={(item: any, index) => item.id.toString()}
              data={searchResults}
              renderItem={drawAccountCard}
            />
          </View>}
        </View>}

        {!saving && !loading && !showAccountSearch && <View style={{width: "50%", alignSelf: 'center'}}>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Title: </Text>
            <View style={styles.inputGroup}>
              <View style={styles.inputBoxNoBorder}>
                <TextInput
                  style={[styles.input, {width: 300}]}
                  placeholder="Enter promo code title"
                  placeholderTextColor="#003f5c"
                  value={promoCode.title}
                  returnKeyType="next"
                  onChangeText={(value) => {
                    setPromoCode({...promoCode, title: value})
                  }}
                />
              </View>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Discount Percent: </Text>
            <View style={styles.inputGroup}>
              <View style={styles.inputBoxNoBorder}>
                <TextInput
                  style={[styles.input, {width: 100}]}
                  placeholder="Enter percent to discount"
                  placeholderTextColor="#003f5c"
                  value={promoCode.percent.toString()}
                  keyboardType="number-pad"
                  returnKeyType="next"
                  onChangeText={(value) => {
                    if (!isNaN(+value)) {
                      setPromoCode({...promoCode, percent: +value})
                    } else {
                      setPromoCode({...promoCode, percent: promoCode.percent})
                    }
                  }}
                />
              </View>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <View style={styles.checkboxContainer}>
              <Checkbox
                value={promoCode.singleUse}
                onValueChange={(value: boolean) => {
                  setPromoCode({...promoCode, singleUse: value})
                }}
                style={styles.checkbox}
              />
              <Text style={styles.label}> Can only be used once per customer</Text>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Start Date: </Text>
            <View style={styles.inputGroup}>
              <View style={styles.inputBoxNoBorder}>
                <DatePicker
                  value={promoCode.startDate}
                  onChange={onChangeStartDate}
                />
              </View>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Start Time: </Text>
            <View style={styles.inputGroup}>
              <View style={styles.inputBoxNoBorder}>                  
                  <TimePicker
                    value={promoCode.startDate}
                    onChange={onChangeStartTime}
                  />
              </View>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <View style={styles.checkboxContainer}>
              <Checkbox
                value={promoCode.noExpiry}
                onValueChange={(value: boolean) => {
                  setPromoCode({...promoCode, noExpiry: value})
                }}
                style={styles.checkbox}
              />
              <Text style={styles.label}> No expiry</Text>
            </View>
          </View>
          {!promoCode.noExpiry && <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Expiry Date: </Text>
            <View style={styles.inputGroup}>
              <View style={styles.inputBoxNoBorder}>
                <DatePicker
                  value={promoCode.expiryDate}
                  onChange={onChangeExpiryDate}
                />
              </View>
            </View>
          </View>}
          {!promoCode.noExpiry && <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Expiry Time: </Text>
            <View style={styles.inputGroup}>
              <View style={styles.inputBoxNoBorder}>                  
                  <TimePicker
                    value={promoCode.expiryDate}
                    onChange={onChangeExpiryTime}
                  />
              </View>
            </View>
          </View>}
          <View style={styles.inputContainer}>
            <View style={styles.checkboxContainer}>
              <Checkbox
                value={promoCode.everyone}
                onValueChange={(value: boolean) => {
                  setPromoCode({...promoCode, everyone: value})
                }}
                style={styles.checkbox}
              />
              <Text style={styles.label}> Everyone can use this promo code</Text>
            </View>
          </View>
          {saved && <View style={styles.inputContainer}>
            <View>
              <View style={{flexDirection: 'row'}}>
                <Text style={styles.cardHeader}>Accounts</Text>
                <Button onPress={() => setShowAccountSearch(true)} text={"Add"} style={{marginLeft: 10}} />
              </View>
              <Text style={styles.inputLabel}>Allow the following people to use this promo code: </Text>
              <View style={styles.inputGroup}>
                <View style={styles.inputBoxNoBorder}>
                  {promoCodeAccounts.length > 0 && <View>
                    <FlatList
                      keyExtractor={(item: any, index) => item.id.toString()}
                      data={promoCodeAccounts}
                      renderItem={drawCard}
                    />
                  </View>}
                  {promoCodeAccounts.length == 0 && <View style={{alignItems: 'center', width: "100%"}}>
                    <View style={styles.inputBox}>
                      <Text style={styles.cardSubHeader}>This promo code has not been assigned to any accounts</Text>
                    </View>
                  </View>}
                </View>
              </View>
            </View>
          </View>}
          
          <Button onPress={savePromoCode}
            style={styles.button}
            text="Save">
          </Button>

          <Button onPress={close}
            style={styles.button}
            text="Cancel">
          </Button>
        </View>}
      </View>
    </ScrollView>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    width: "100%"
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontWeight: 'bold',
  },

  inputContainer: {
    flexDirection: 'row',
    padding: 10
  },
  
  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
 
  inputBoxNoBorder: {
    fontSize: 16,
    padding: 10,
  },
 
  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  card: {
    backgroundColor: Colors.white,
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },
  
  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },
  
  cardTime: {
    fontSize: 18,
  },

  button : {
    margin: 10,
  },
  
  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  
  searchInputContainer: {
    backgroundColor: Colors.white,
  },

  checkboxContainer: {
    flexDirection: "row",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10
  },

  checkbox: {
    alignSelf: "center",
  },

  label: {
    margin: 8,
  },
});

export default PromoCodeEditor