import { combineReducers } from "redux";
import { accountReducer } from "./modules/account/reducer";
import { appReducer } from './modules/app/reducer';
import { authReducer } from './modules/auth/reducer';
import { paymentReducer } from "./modules/payment/reducer";
import { rideReducer } from "./modules/ride/reducer";
import { scheduleReducer } from "./modules/schedule/reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  ride: rideReducer,
  schedule: scheduleReducer,
  account: accountReducer,
  payment: paymentReducer
})