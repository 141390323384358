import { useNavigation } from '@react-navigation/native';
import Checkbox from 'expo-checkbox';
import React, { useEffect } from 'react'

import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';

import DriverViewer from '../components/driver/DriverViewer';
import Button from '../components/ui/Button';
import Colors from '../constants/Colors';
import AppApi from '../services/modules/app/api';

interface Props {
}

const SettingsScreen: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();
  
  const [saved, setSaved] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [enabled, setEnabled] = React.useState(false);

  const [message, setMessage] = React.useState("")
  const [minutePrice, setMinutePrice] = React.useState("")
  const [kmPrice, setKMPrice] = React.useState("")
  const [initialFee, setInitialFee] = React.useState("")
  const [driverRate, setDriverRate] = React.useState("")
  const [reservationBuffer, setReservationBuffer] = React.useState("")
  const [reservationAdvance, setReservationAdvance] = React.useState("")
  const [cancelDistance, setCancelDistance] = React.useState("")
  const [cancelFee, setCancelFee] = React.useState("")
  const [alwaysCancelFee, setAlwaysCancelFee] = React.useState("")
  const [noShowCancelFee, setNoShowCancelFee] = React.useState("")
  const [allowBookingStartHour, setAllowBookingStartHour] = React.useState("")
  const [allowBookingEndHour, setAllowBookingEndHour] = React.useState("")

  const saveSettings = () => {
    if (minutePrice.length == 0 || kmPrice.length == 0 || initialFee.length == 0 || driverRate.length == 0 || reservationBuffer.length == 0 || reservationAdvance.length == 0 || cancelDistance.length == 0 || cancelFee.length == 0  || noShowCancelFee.length == 0|| allowBookingStartHour.length == 0 || allowBookingEndHour.length == 0) {
      alert("Please enter a value for all settings.");
      return;
    }

    if (isNaN(+minutePrice) || isNaN(+kmPrice) || isNaN(+initialFee) || isNaN(+driverRate) || isNaN(+reservationBuffer) || isNaN(+reservationAdvance) || isNaN(+cancelDistance) || isNaN(+cancelFee) || isNaN(+noShowCancelFee)  || isNaN(+allowBookingStartHour)  || isNaN(+allowBookingEndHour)) {
      alert("Setting values must be numeric.");
      return;
    }

    if (message.length == 0 && enabled) {
      alert("Please enter a value for the message.");
      return;
    }

    setSaving(true);

    AppApi.setRegistryValue("allow_booking_start_hour", allowBookingStartHour)
    .then(() => {
    });

    AppApi.setRegistryValue("allow_booking_end_hour", allowBookingEndHour)
    .then(() => {
    });

    AppApi.setRegistryValue("km_price", kmPrice)
    .then(() => {
    });
    
    AppApi.setRegistryValue("minute_price", minutePrice)
    .then(() => {
    });
    
    AppApi.setRegistryValue("initial_fee", initialFee)
    .then(() => {
    });
    
    AppApi.setRegistryValue("driver_rate", driverRate)
    .then(() => {
    });
    
    AppApi.setRegistryValue("reservation_buffer", reservationBuffer)
    .then(() => {
    });
    
    AppApi.setRegistryValue("reservation_advance", reservationAdvance)
    .then(() => {
    });
    
    AppApi.setRegistryValue("cancel_distance", cancelDistance)
    .then(() => {
    });
    
    AppApi.setRegistryValue("cancel_fee", cancelFee)
    .then(() => {
    });
    
    AppApi.setRegistryValue("cancel_fee_2", noShowCancelFee)
    .then(() => {
    });
    

        
    AppApi.setRegistryValue("cancel_fee_3", alwaysCancelFee)
    .then(() => {
    });
    

    AppApi.setRegistryValue("app_banner_enabled", enabled ? "true" : "false")
    .then(() => {
    });
    
    AppApi.setRegistryValue("app_banner_message", message)
    .then(() => {
      setSaved(true)
      setSaving(false)

      
    AppApi.getRegistryValue("allow_booking_start_hour")
    .then((apiResponse: any) => {
      setAllowBookingStartHour(apiResponse.data)
    })
    

    AppApi.getRegistryValue("allow_booking_end_hour")
    .then((apiResponse: any) => {
      setAllowBookingEndHour(apiResponse.data)
    })
    

    AppApi.getRegistryValue("km_price")
    .then((apiResponse: any) => {
      setKMPrice(apiResponse.data)
    })
    
    AppApi.getRegistryValue("minute_price")
    .then((apiResponse: any) => {
      setMinutePrice(apiResponse.data)
    })
    
    AppApi.getRegistryValue("initial_fee")
    .then((apiResponse: any) => {
      setInitialFee(apiResponse.data)
    })
    
    AppApi.getRegistryValue("driver_rate")
    .then((apiResponse: any) => {
      setDriverRate(apiResponse.data)
    })
    
    AppApi.getRegistryValue("reservation_buffer")
    .then((apiResponse: any) => {
      setReservationBuffer(apiResponse.data)
    })
    
    AppApi.getRegistryValue("reservation_advance")
    .then((apiResponse: any) => {
      setReservationAdvance(apiResponse.data)
      setLoading(false)
    }) 
    
    AppApi.getRegistryValue("cancel_distance")
    .then((apiResponse: any) => {
      setCancelDistance(apiResponse.data)
    })
    
    AppApi.getRegistryValue("cancel_fee")
    .then((apiResponse: any) => {
      setCancelFee(parseFloat(apiResponse.data).toFixed(2))
    })    
    
    AppApi.getRegistryValue("cancel_fee_2")
    .then((apiResponse: any) => {
      setNoShowCancelFee(parseFloat(apiResponse.data).toFixed(2))
    })     
    

    AppApi.getRegistryValue("cancel_fee_3")
    .then((apiResponse: any) => {
      setAlwaysCancelFee(parseFloat(apiResponse.data).toFixed(2))
    })     

    AppApi.getRegistryValue("app_banner_enabled")
    .then((apiResponse: any) => {
      if (apiResponse.data) {
        setEnabled(true)
      } else {
        setEnabled(false)
      }
    })
    
    AppApi.getRegistryValue("app_banner_message")
    .then((apiResponse: any) => {
      setMessage(apiResponse.data)
      setLoading(false)
    })    

    });
  }

  const close = (() => {
    navigation.goBack();
  });

  useEffect(() => {

    AppApi.getRegistryValue("allow_booking_start_hour")
    .then((apiResponse: any) => {
      setAllowBookingStartHour(apiResponse.data)
    })
    

    AppApi.getRegistryValue("allow_booking_end_hour")
    .then((apiResponse: any) => {
      setAllowBookingEndHour(apiResponse.data)
    })
    

    AppApi.getRegistryValue("km_price")
    .then((apiResponse: any) => {
      setKMPrice(apiResponse.data)
    })
    
    AppApi.getRegistryValue("minute_price")
    .then((apiResponse: any) => {
      setMinutePrice(apiResponse.data)
    })
    
    AppApi.getRegistryValue("initial_fee")
    .then((apiResponse: any) => {
      setInitialFee(apiResponse.data)
    })
    
    AppApi.getRegistryValue("driver_rate")
    .then((apiResponse: any) => {
      setDriverRate(apiResponse.data)
    })
    
    AppApi.getRegistryValue("reservation_buffer")
    .then((apiResponse: any) => {
      setReservationBuffer(apiResponse.data)
    })
    
    AppApi.getRegistryValue("reservation_advance")
    .then((apiResponse: any) => {
      setReservationAdvance(apiResponse.data)
      setLoading(false)
    }) 
    
    AppApi.getRegistryValue("cancel_distance")
    .then((apiResponse: any) => {
      setCancelDistance(apiResponse.data)
    })
    
    AppApi.getRegistryValue("cancel_fee")
    .then((apiResponse: any) => {
      setCancelFee(parseFloat(apiResponse.data).toFixed(2))
    })    
    
    AppApi.getRegistryValue("cancel_fee_2")
    .then((apiResponse: any) => {
      setNoShowCancelFee(parseFloat(apiResponse.data).toFixed(2))
    })     
    

    AppApi.getRegistryValue("cancel_fee_3")
    .then((apiResponse: any) => {
      setAlwaysCancelFee(parseFloat(apiResponse.data).toFixed(2))
    })     

    AppApi.getRegistryValue("app_banner_enabled")
    .then((apiResponse: any) => {
      if (apiResponse.data) {
        setEnabled(true)
      } else {
        setEnabled(false)
      }
    })
    
    AppApi.getRegistryValue("app_banner_message")
    .then((apiResponse: any) => {
      setMessage(apiResponse.data)
      setLoading(false)
    })    
  }, [])

  return (     
    <ScrollView
      contentInsetAdjustmentBehavior="automatic">
      <View style={styles.container}>        
        {loading &&
          <View style={{marginTop: 40}}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.searchingText}>Loading...</Text>
          </View>
        }

        {saving &&
          <View style={{marginTop: 40}}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.searchingText}>Saving...</Text>
          </View>
        }

        {!saving && !loading && <View style={{width: "50%", alignSelf: 'center'}}>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Initial fee: </Text>
            <View style={styles.inputGroup}>
              <Text style={{paddingTop: 8, paddingRight: 5}}>$</Text>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter amount"
                placeholderTextColor="#003f5c"
                value={initialFee.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setInitialFee(value) }}
              />
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Price per minute: </Text>
            <View style={styles.inputGroup}>
              <Text style={{paddingTop: 8, paddingRight: 5}}>$</Text>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter amount"
                placeholderTextColor="#003f5c"
                value={minutePrice.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setMinutePrice(value) }}
              />
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Price per KM: </Text>
            <View style={styles.inputGroup}>
              <Text style={{paddingTop: 8, paddingRight: 5}}>$</Text>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter amount"
                placeholderTextColor="#003f5c"
                value={kmPrice.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setKMPrice(value) }}
              />
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Driver pay rate: </Text>
            <View style={styles.inputGroup}>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter decimal percentage"
                placeholderTextColor="#003f5c"
                value={driverRate.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setDriverRate(value) }}
              />
              <Text style={{paddingTop: 8, paddingRight: 5}}> decimal percentage</Text>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Reservation buffer: </Text>
            <View style={styles.inputGroup}>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter minutes"
                placeholderTextColor="#003f5c"
                value={reservationBuffer.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setReservationBuffer(value) }}
              />
              <Text style={{paddingTop: 8, paddingRight: 5}}> minutes</Text>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Reservation book in advance: </Text>
            <View style={styles.inputGroup}>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter minutes"
                placeholderTextColor="#003f5c"
                value={reservationAdvance.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setReservationAdvance(value) }}
              />
              <Text style={{paddingTop: 8, paddingRight: 5}}> minutes</Text>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Passenger cancel charge distance: </Text>
            <View style={styles.inputGroup}>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter distance"
                placeholderTextColor="#003f5c"
                value={cancelDistance.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setCancelDistance(value) }}
              />
              <Text style={{paddingTop: 8, paddingRight: 5}}> meters</Text>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Cancellation fee: </Text>
            <View style={styles.inputGroup}>
              <Text style={{paddingTop: 8, paddingRight: 5}}>$</Text>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter amount"
                placeholderTextColor="#003f5c"
                value={cancelFee.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setCancelFee(value) }}
              />
            </View>
          </View>

          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Other cancellation fee: </Text>
            <View style={styles.inputGroup}>
              <Text style={{paddingTop: 8, paddingRight: 5}}>$</Text>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter amount"
                placeholderTextColor="#003f5c"
                value={alwaysCancelFee.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setAlwaysCancelFee(value) }}
              />
            </View>
          </View>

          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>No show cancellation fee: </Text>
            <View style={styles.inputGroup}>
              <Text style={{paddingTop: 8, paddingRight: 5}}>$</Text>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter amount"
                placeholderTextColor="#003f5c"
                value={noShowCancelFee.toString()}
                keyboardType="decimal-pad"
                returnKeyType="next"
                onChangeText={(value) => { setNoShowCancelFee(value) }}
              />
            </View>
          </View>




          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Allow Booking Start Hour (24 hour clock, -1 to disable) </Text>
            <View style={styles.inputGroup}>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter hour"
                placeholderTextColor="#003f5c"
                value={allowBookingEndHour.toString()}
                keyboardType="number-pad"
                returnKeyType="next"
                onChangeText={(value) => { setAllowBookingEndHour(value) }} />
                </View>
              </View>

          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Allow Booking End Hour (24 hour clock, -1 to disable)</Text>
            <View style={styles.inputGroup}>
              <TextInput
                style={[styles.input, {width: 100}]}
                placeholder="Enter hour"
                placeholderTextColor="#003f5c"
                value={allowBookingStartHour.toString()}
                keyboardType="number-pad"
                returnKeyType="next"
                onChangeText={(value) => { setAllowBookingStartHour(value) }}
              />
            </View>
          </View>

          <View style={styles.inputContainer}>
            <View style={styles.checkboxContainer}>
              <Checkbox
                value={enabled}
                onValueChange={(value: boolean) => {
                  setEnabled(value)
                }}
                style={styles.checkbox}
              />
              <Text style={styles.label}> Show passenger app banner</Text>
            </View>
          </View>
          
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Banner Message: </Text>
            <View style={styles.inputGroup}>
              <TextInput
                style={[styles.input, {width: 400}]}
                placeholder="Enter banner message"
                placeholderTextColor="#003f5c"
                value={message}
                returnKeyType="next"
                onChangeText={(value) => { setMessage(value) }}
              />
            </View>
          </View>
          
          <Button onPress={saveSettings}
            style={styles.button}
            text="Save">
          </Button>
          
          {saved &&
            <View style={{marginTop: 40}}>
              <Text style={styles.searchingText}>Saved!</Text>
            </View>
          }

          <Button onPress={close}
            style={styles.button}
            text="Close">
          </Button>
        </View>}
      </View>
    </ScrollView>
  )
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    width: "100%"
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontWeight: 'bold',
  },

  inputContainer: {
    flexDirection: 'row',
    padding: 10
  },
  
  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
 
  inputBoxNoBorder: {
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
 
  inputLabel: {
    fontSize: 16,
    marginTop: 7,
    marginLeft: 5,
  },

  button : {
    margin: 10,
  },
  
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 20
  },

  checkboxContainer: {
    flexDirection: "row",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10
  },

  checkbox: {
    alignSelf: "center",
  },

  label: {
    margin: 8,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});

export default SettingsScreen