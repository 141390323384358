import { ScheduleEntry } from "../../types";
import { ScheduleDispatchTypes, VIEW_SCHEDULE_ENTRY } from "./types";

export const initialState: ScheduleEntry = { id: 0, accountId: '', scheduledTime: new Date(), duration: 0 };

export const scheduleReducer = (
  state: ScheduleEntry = initialState,
  action: ScheduleDispatchTypes
) => {
  switch (action.type){
    case VIEW_SCHEDULE_ENTRY:
      return {
        ...state,
        id: action.payload,
      }
    default:
        return state;
  }
}