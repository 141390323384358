import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import React from 'react'

import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { useDispatch } from 'react-redux';
import Colors from '../../constants/Colors';
import { selectPromoCode } from '../../services/modules/payment/actions';
import Moment from 'react-moment';
import PaymentApi from '../../services/modules/payment/api';
import { Account, PromoCode } from '../../services/types';
import Button from '../ui/Button';
import { selectAccount } from '../../services/modules/account/actions';
import AccountApi from '../../services/modules/account/api';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';

interface Props {
}

const Passengers: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [drivers, setDrivers] = React.useState([]);
  
  const [searching, setSearching] = React.useState(false);
  const [searched, setSearched] = React.useState(false);

  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);
  
  const [searchResults, setSearchResults] = React.useState([])

  const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

  const viewDriver = (id: string) => {
    dispatch(selectAccount(id));
    navigation.navigate("Passenger");
  }

  const updateSearch = (text: string) => {
    setKeyword(text)
    if (text.length > 2) {
      setSearching(true)
      AccountApi.searchPassengers(text)
      .then((apiResponse: any) => {
        setSearchResults(apiResponse.data);
        setSearching(false);
        setSearched(true);
      });
    } else if (text.length == 0) {
      setSearchResults([]);
    }
  }

  const clearSearch = () => {
    setKeyword(undefined);
  }

  const drawAccountCard = (({ item }: { item: any }) => {
    return (
        <View style={styles.card}>
          <TouchableOpacity onPress={() => viewDriver(item.id)}>
            <View style={styles.cardHeaderContainer}>
              <View style={{ marginTop: 5 }}>
                <Text style={styles.cardHeader}>{item.firstName} {item.lastName}</Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>)
  })

  return (<View>     
            <Text style={{fontSize: 16, padding: 10}}>Start typing the persons name then click them.</Text>          
            <SafeSearchBar
              platform={"default"}
              searchIcon={false}
              onChangeText={updateSearch}
              onCancel={clearSearch}
              value={keyword}
              placeholder="Search"
              inputContainerStyle={styles.searchInputContainer}
              inputStyle={styles.searchInput}
              containerStyle={styles.searchContainer}
            />     

            {searching &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.searchingText}>Searching...</Text>
              </View>
            }

            {searchResults.length == 0 && !searching && searched && <Text style={styles.cardHeader}>No accounts found</Text>}
            {searchResults.length > 0 && !searching && <View>
              <FlatList
                keyExtractor={(item: any, index) => item.id.toString()}
                data={searchResults}
                renderItem={drawAccountCard}
              />
            </View>}
  </View>)
};

const styles = StyleSheet.create({ 
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },
  
  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
  
  card: {
    backgroundColor: Colors.white,
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },
  
  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },
  
  cardTime: {
    fontSize: 18,
  },

  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 4,
    color: Colors.white
  },

  sectionHeaderView: {
    flexDirection: 'row',
    width: "98%",
    backgroundColor: Colors.primary,
    borderRadius: 10,
    padding: 10
  },
  
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  
  button : {
    marginTop: 2,
    marginLeft: 10,
  },
  
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  
  searchInputContainer: {
    backgroundColor: Colors.white,
  },
  
  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});

export default Passengers