import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  RefreshControl,
  Image,
  Text,
  ScrollView,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import GoogleMapReact from 'google-map-react';
import Colors from '../../constants/Colors';
import RideApi from '../../services/modules/ride/api';
import { createRide, getAPIDate } from '../../services/helpers';
import AccountApi from '../../services/modules/account/api';
import { Picker } from '@react-native-picker/picker';
import { colors } from 'react-native-elements';

interface Props {
}

const ReservationViewer: React.FC<Props> = ({
}) => {
  const isFocused = useIsFocused()
  const navigation = useNavigation()

  const appState = useSelector((state: RootState) => state.app);
  const rideState = useSelector((state: RootState) => state.ride);
  const authState = useSelector((state: RootState) => state.auth);

  const [saving, setSaving] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  const [ride, setRide] = React.useState(createRide());

  const [driverAccountId, setDriverAccountId] = React.useState("");

  const [drivers, setDrivers] = React.useState([])

  const close = (() => {
    navigation.goBack();
  });

  const MapPin = ({ lat, lng }: { lat: any, lng: any }) => {
    return (<Image
      source={require('../../assets/car.png')}
      style={{ height: 30, width: 30 }} />)
  };

  const saveDriver = () => {
    if (driverAccountId.length == 0) {
      alert("Please select a driver.");
      return;
    }

    //Only need to notify them if they changed the driver
    if (ride.driverAccountId != driverAccountId) {
      if (!confirm("This reservation will be assigned to the selected driver and they will be notified, continue?")) {
        return;
      }
    }

    setSaving(true);
    RideApi.reservationAssignDriver(ride.id, driverAccountId)
      .then(() => {
        setSaving(false);
        close();
      });
  }




  const cancelReservation = () => {

    //Only need to notify them if they changed the driver
    var reason: any = prompt("Are you sure you want to cancel this reservation, please enter a reason?");
    
    if (!reason) {
      return;
    }


    setSaving(true);
    RideApi.setReservationCancelled(ride.id, reason)
      .then(() => {
        setSaving(false);
        close();
      });
  }



  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        RideApi.getRide(rideState.id)
          .then((apiResponse: any) => {
            let ride = apiResponse.data;
            setRide(ride)

            if (ride.driverAccountId != ride.passengerAccountId) {
              setDriverAccountId(ride.driverAccountId)
            }

            setLoaded(true)
          })
      }
    }, [isFocused])
  )

  useEffect(() => {
    AccountApi.getApprovedDrivers()
      .then((apiResponse: any) => {
        setDrivers(apiResponse.data)
      })
  }, [])

  return (<ScrollView
    contentInsetAdjustmentBehavior="automatic">

    {!loaded &&
      <View>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.searchingText}>Loading...</Text>
      </View>
    }

    {saving &&
      <View style={{ marginTop: 40 }}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.searchingText}>Saving...</Text>
      </View>
    }

    {loaded && !saving && <View>
      <View style={styles.inputContainer}>
        <View style={styles.sectionContainer}>
          <View style={styles.sectionHeaderView}>
            <Text style={styles.sectionHeader}>DETAILS</Text>
          </View>
          <Text style={styles.inputLabel}>Date Booked</Text>
          <View style={styles.inputBox}>
            <Moment element={Text} format="D-MMM-yyyy H:mm A" style={styles.inputText}>{getAPIDate(ride.scheduledTime)}</Moment>
          </View>
          <Text style={styles.inputLabel}>Status</Text>
          <View style={styles.inputBox}>
            <Text style={styles.inputText}>{ride.status.status}</Text>
          </View>
          <Text style={styles.inputLabel}>Driver</Text>
          <View style={styles.inputGroup}>
            <View style={styles.inputBoxNoBorder}>
              <Picker
                style={styles.input}
                selectedValue={driverAccountId}
                onValueChange={(itemValue, itemIndex) => setDriverAccountId(itemValue)}>
                <Picker.Item key={0} label="Please Select" value={""} />
                {
                  drivers.map((v: any) => {
                    return <Picker.Item key={v.account.id} label={v.account.lastName + ", " + v.account.firstName} value={v.account.id} />
                  })
                }
              </Picker>
            </View>
          </View>
          <Button onPress={saveDriver}
            style={styles.button}
            text="Save">
          </Button>

          <Button onPress={cancelReservation}
            style={styles.buttonDanger}
            text="Cancel Reservation">
          </Button>

          <Button onPress={close}
            style={styles.button}
            text="Cancel">
          </Button>
          <Text style={styles.inputLabel}>Passenger</Text>
          <View style={styles.inputBox}>
            <Text style={styles.inputText}>{ride.passengerAccount?.firstName} {ride.passengerAccount?.lastName}</Text>
          </View>
          <Text style={styles.inputLabel}>Pick Up Address</Text>
          <View style={styles.inputBox}>
            <Text>{ride.pickupLocation.address}</Text>
            <Text>{ride.pickupLocation.city}</Text>
            <Text>{ride.pickupLocation.province}, {ride.pickupLocation.postalCode}</Text>
          </View>
          <Text style={styles.inputLabel}>Destination Address</Text>
          <View style={styles.inputBox}>
            <Text>{ride.destinationLocation.address}</Text>
            <Text>{ride.destinationLocation.city}</Text>
            <Text>{ride.destinationLocation.province}, {ride.destinationLocation.postalCode}</Text>
          </View>

          {ride.pickupLocation.latitude != 0 && ride.pickupLocation.longitude != 0 && <View>
            <GoogleMapReact
              style={{ width: "100%", height: 300 }}
              bootstrapURLKeys={{ key: appState.googleKey }}
              defaultCenter={{ lat: ride.pickupLocation.latitude, lng: ride.pickupLocation.longitude }}
              defaultZoom={16}>
              <MapPin
                lat={ride.pickupLocation.latitude}
                lng={ride.pickupLocation.longitude}
              />
            </GoogleMapReact>
          </View>}

        </View>
      </View>

      <Button onPress={close}
        style={styles.button}
        text="Close">
      </Button>
    </View>}
  </ScrollView>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  headerButton: {
    margin: 5,
  },

  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBoxNoBorder: {
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button: {
    width: width - 20,
    margin: 10,
  },
  buttonDanger: {
    backgroundColor: colors.warning,
    color: Colors.borderGrey,
    width: width - 20,
    margin: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    color: Colors.borderGrey,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  sectionContainer: {
    width: width - 20,
    margin: 10
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton: {
    margin: 4,
  },
});
export default ReservationViewer