import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import React from 'react'

import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { useDispatch } from 'react-redux';
import Colors from '../../constants/Colors';
import { selectPromoCode } from '../../services/modules/payment/actions';
import Moment from 'react-moment';
import PaymentApi from '../../services/modules/payment/api';
import { PromoCode } from '../../services/types';
import Button from '../ui/Button';
import { getAPIDate } from '../../services/helpers';

interface Props {
}

const PromoCodes: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [promoCodes, setPromoCodes] = React.useState([]);
  
  const [loading, setLoading] = React.useState(true);
  const [showAll, setShowAll] = React.useState(false);

  const viewPromoCode = (promoCode: PromoCode) => {
    if (promoCode.system) {
      alert("This is a system promo code, you cannot edit it.");
    } else {
      dispatch(selectPromoCode(promoCode.id));
      navigation.navigate("PromoCodeEditor");
    }
  }

  const removePromoCode = (id: number) => {  
    if (confirm("Are you sure?")) {
      PaymentApi.removePromoCode(id)
      .then(() => {
        loadPromoCodes(showAll)
      })
    }
  }

  const loadPromoCodes = (all: boolean) => {  
    setShowAll(all)

    if (all) {
      PaymentApi.getAllPromoCodes()
      .then((apiResponse: any) => {
        setPromoCodes(apiResponse.data)
        setLoading(false)
      })
    } else {
      PaymentApi.getActivePromoCodes()
      .then((apiResponse: any) => {
        setPromoCodes(apiResponse.data)
        setLoading(false)
      })
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        loadPromoCodes(showAll);
      }
    }, [isFocused])
  );

  const drawCard = (({ item }: { item: any }) => {
    return (
      <TouchableOpacity onPress={() => viewPromoCode(item)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            <View style={{ marginTop: 5 }}>
              <Text style={styles.cardHeader}>{item.title} {item.system ? ' (system promo code)' : ''}</Text>
              {item.noExpiry && <Text style={styles.cardHeader}>No Expiry</Text>}
              {!item.noExpiry && <Moment element={Text} format="D-MMM-yyyy H:mm A" style={styles.cardHeader}>{getAPIDate(item.expiryDate)}</Moment>}
            </View>            
            {!item.system && <Button text={"Remove"} onPress={() => removePromoCode(item.id)} style={{marginRight: 10}}/>}
          </View>
        </View>
      </TouchableOpacity>)
  })

  return (<View>     

    {loading &&
      <View style={{marginTop: 40}}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.loadingText}>Loading...</Text>
      </View>}

    {!loading &&
      <View style={{margin: 10}}>
        <View style={{flexDirection: 'row'}}>
          <Button text={"Add Promo Code"} onPress={() => navigation.navigate("PromoCodeEditor")} style={{marginRight: 10}}/>
          {!showAll && <Button text={"Show All"} onPress={() => loadPromoCodes(true)} />}
          {showAll && <Button text={"Show Active"} onPress={() => loadPromoCodes(false)} />}
        </View>
        {promoCodes.length > 0 && <View>
          <FlatList
            keyExtractor={(item: any, index) => item.id.toString()}
            data={promoCodes}
            renderItem={drawCard}
          />
        </View>}
        {promoCodes.length == 0 && <View style={{alignItems: 'center'}}>
          {!showAll && <Text style={styles.header}>No active promo codes</Text>}
          {showAll && <Text style={styles.header}>No promo codes</Text>}
        </View>}
      </View>}
  </View>)
};

const styles = StyleSheet.create({ 
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },
  
  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
  
  card: {
    backgroundColor: Colors.white,
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },
  
  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },
  
  cardTime: {
    fontSize: 18,
  },

  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 4,
    color: Colors.white
  },

  sectionHeaderView: {
    flexDirection: 'row',
    width: "98%",
    backgroundColor: Colors.primary,
    borderRadius: 10,
    padding: 10
  },
  
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  
  button : {
    marginTop: 2,
    marginLeft: 10,
  },
});

export default PromoCodes