import React, { useEffect } from 'react'
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  Dimensions,
} from 'react-native';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import Colors from '../../constants/Colors';
import { baseURL } from '../../services/global';
import { selectScheduleEntry } from '../../services/modules/schedule/actions';
import ScheduleApi from '../../services/modules/schedule/api';
import { DatePicker } from '../ui/DatePicker';
import Moment from 'react-moment';
import { getAPIDate, minutesToHm } from '../../services/helpers';
import { FontAwesome } from '@expo/vector-icons';
import Button from '../ui/Button';
import { endOfDay, startOfDay } from 'date-fns';

interface Props {
}

const DaySchedule: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [scheduleDate, setScheduleDate] = React.useState(new Date());
  
  const [scheduleEntries, setScheduleEntries] = React.useState([]);
  
  const [loading, setLoading] = React.useState(true);
  const [expanded, setExpanded] = React.useState(true);

  const onChangeScheduleDate = (selectedDate: any) => {
    let checkDate = new Date(selectedDate.target.value + "T00:00:00");
    setScheduleDate(checkDate);
    loadSchedule(checkDate);
  };

  const viewScheduleEntry = (id: any) => {
    dispatch(selectScheduleEntry(id));
    navigation.navigate("ScheduleEntryEditor");
  }

  const addScheduleEntry = () => {
    dispatch(selectScheduleEntry(0));
    navigation.navigate("ScheduleEntryEditor");
  }

  const loadSchedule = (loadDate: Date) => {
    setLoading(true);
    ScheduleApi.getDaySchedule(startOfDay(loadDate), endOfDay(loadDate))
    .then((apiResponse: any) => {
      setScheduleEntries(apiResponse.data);
    })
    .catch(() => {
      setScheduleEntries([]);
    })
    .finally(() => {
      setLoading(false);
    })
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        loadSchedule(scheduleDate);
      }
    }, [isFocused])
  );

  const drawCard = (({ item }: { item: any }) => {
    let startTime = getAPIDate(item.scheduledTime);
    let endTime = getAPIDate(item.scheduledTime);
    endTime = new Date(endTime.getTime() + (item.duration * 60 * 1000));

    return (
      <TouchableOpacity onPress={() => viewScheduleEntry(item.id)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            {item.account.profilePhoto != null && item.account.profilePhoto.length > 0 && <Image source={{ uri: baseURL + "/account/photo/file/" + item.account.id + "/" + item.account.profilePhoto }} resizeMode="cover" style={{ borderRadius: 50, height: 50, width: 50, margin: 10 }} />}
            {item.account.profilePhoto == null && <Image source={require('../../assets/no_photo.png')} resizeMode="cover" style={{ height: 50, width: 50, margin: 10 }} />}
            <View style={{ marginTop: 5 }}>
              <Text style={styles.cardHeader}>{item.account.firstName} {item.account.lastName}</Text>
              <View style={{flexDirection: 'row'}}>
                <Moment element={Text} format="h:mm A" style={styles.cardTime} local>{startTime}</Moment>
                <Text> - </Text>
                <Moment element={Text} format="h:mm A" style={styles.cardTime} local>{endTime}</Moment>
              </View>
              <Text style={styles.cardSubHeader}>{minutesToHm(item.duration)}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>)
  })

  return (<View>     
            <View style={styles.sectionHeaderView}>
              <View style={{flexDirection: 'row', width: "100%"}}>
                <View style={{flexDirection: 'row', flex: 1}}>
                  <Text style={styles.sectionHeader}>Schedule for</Text>
                  <Moment element={Text} format="D-MMM-yyyy" style={styles.sectionHeader}>{scheduleDate}</Moment>
                </View>
                <TouchableOpacity onPress={toggleExpanded} style={{paddingRight: 5}}>
                  {expanded && <FontAwesome name="arrow-circle-up" color="#CCCCCC" size={30}/>}
                  {!expanded && <FontAwesome name="arrow-circle-down" color="#CCCCCC" size={30}/>}
                </TouchableOpacity>
              </View>
            </View>  

            {loading && expanded &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.loadingText}>Loading...</Text>
              </View>}

            {!loading && expanded &&
              <View style={{margin: 10}}>
                <View style={[styles.inputGroup, {flexDirection: 'row', height: 40}]}>
                  <Text style={{fontSize: 18, paddingTop: 4}}>Change date: </Text>
                  <DatePicker
                    value={scheduleDate}
                    onChange={onChangeScheduleDate}
                  />
                  <Button onPress={addScheduleEntry}
                    style={styles.button}
                    text="Add to Schedule">
                  </Button>
                </View>
                {scheduleEntries.length > 0 && <View>
                  <FlatList
                    keyExtractor={(item: any, index) => item.id.toString()}
                    data={scheduleEntries}
                    renderItem={drawCard}
                  />
                </View>}
                {scheduleEntries.length == 0 && <View style={{alignItems: 'center'}}>
                  <Text style={styles.header}>No drivers scheduled for this date</Text>
                </View>}
              </View>}
          </View>)
};

const styles = StyleSheet.create({ 
  header: {
    fontSize: 22,
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },
  
  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
  
  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },
  
  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },
  
  cardTime: {
    fontSize: 18,
  },

  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 4,
    color: Colors.white
  },

  sectionHeaderView: {
    flexDirection: 'row',
    width: "98%",
    backgroundColor: Colors.primary,
    borderRadius: 10,
    padding: 10
  },
  
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  
  button : {
    marginTop: 2,
    marginLeft: 10,
  },
  
});

export default DaySchedule