import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { Image } from 'react-native';
import { useSelector } from 'react-redux';
import BackButton from '../components/menu/BackButton';
import HamburgerMenu from '../components/menu/Hamburger';
import LogoutHeader from '../components/menu/LogoutHeader';
import PassengerViewer from '../components/passenger/PassengerViewer';
import CancelationReport from '../components/report/CancelationReport';
import DriverReport from '../components/report/DriverReport';
import InsuranceReport from '../components/report/InsuranceReport';
import PayrollReport from '../components/report/PayrollReports';
import ReservationViewer from '../components/ride/ReservationViewer';
import DashboardScreen from '../screens/Dashboard';
import DriverScreen from '../screens/Driver';
import DriversScreen from '../screens/Drivers';
import ForgotPasswordScene from '../screens/ForgotPassword';
import LoginScreen from '../screens/Login';
import MessengerScreen from '../screens/Messenger';
import PassengerScreen from '../screens/Passenger';
import PassengersScreen from '../screens/Passengers';
import PromoCodeEditorScreen from '../screens/PromoCodeEditor';
import PromoCodesScreen from '../screens/PromoCodes';
import PushNotificationsScreen from '../screens/PushNotifications';
import ReservationsScreen from '../screens/Reservations';
import RideViewerScreen from '../screens/RideViewer';
import ScheduleEntryEditorScreen from '../screens/ScheduleEntryEditor';
import SettingsScreen from '../screens/Settings';
import UnauthorizedScreen from '../screens/Unauthorized';
import { RootState } from '../services/store';
import { RootStackParamList } from './types';

export default function Navigation() {
  return (
    <NavigationContainer>
      <RootNavigator />
    </NavigationContainer>
  );
}

const LoginStack = createNativeStackNavigator<RootStackParamList>();
const MainStack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {  
  const authState = useSelector((state: RootState) => state.auth);

  if (!authState.authenticated) {
    return (
      <LoginStack.Navigator>
        <LoginStack.Screen
          name="Login"
          component={LoginScreen}
          options={{headerShown: false}}
        />
        <LoginStack.Screen
          name="ForgotPassword"
          component={ForgotPasswordScene}
          options={{headerShown: false}}
        />
      </LoginStack.Navigator>
    );
  } else {
    if (authState.role == "Admin") {
      return (<MainStack.Navigator>
        <MainStack.Screen name="Dashboard"
            component={DashboardScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <HeaderImage />,
              headerTitleAlign: 'center',
              title: "Home",
            }}/>
        <MainStack.Screen name="ScheduleEntryEditor"
            component={ScheduleEntryEditorScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Schedule Entry Editor",
            }}/>
        <MainStack.Screen name="RideViewer"
            component={RideViewerScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Ride Viewer",
            }}/>
        <MainStack.Screen name="Driver"
            component={DriverScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Driver Viewer",
            }}/>


<MainStack.Screen name="Passenger"
            component={PassengerScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Passenger Viewer",
            }}/>

<MainStack.Screen name="Messenger"
            component={MessengerScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Messenger",
            }}/>

        <MainStack.Screen name="PromoCodes"
            component={PromoCodesScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Promo Codes",
            }}/>
        <MainStack.Screen name="PromoCodeEditor"
            component={PromoCodeEditorScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Promo Code Editor",
            }}/>
        <MainStack.Screen name="Drivers"
            component={DriversScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Drivers",
            }}/>
                    <MainStack.Screen name="Passengers"
            component={PassengersScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Passengers",
            }}/>

        <MainStack.Screen name="ReservationViewer"
            component={ReservationViewer}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Reservation Viewer",
            }}/>
        <MainStack.Screen name="PayrollReport"
            component={PayrollReport}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Payroll Report",
            }}/>
        <MainStack.Screen name="InsuranceReport"
            component={InsuranceReport}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Insurance Report",
            }}/>
        <MainStack.Screen name="DriverReport"
            component={DriverReport}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Driver Report",
            }}/>            
        <MainStack.Screen name="Settings"
            component={SettingsScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Settings",
            }}/>                     
        <MainStack.Screen name="Reservations"
            component={ReservationsScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Reservations",
            }}/>                              
        <MainStack.Screen name="PushNotifications"
            component={PushNotificationsScreen}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Send Push Notifications",
            }}/>                                       
        <MainStack.Screen name="CancelationReport"
            component={CancelationReport}
            options={{          
              headerRight: () => <HamburgerMenu />,
              headerLeft: () => <BackButton />,
              headerTitleAlign: 'center',
              title: "Cancelation Report",
            }}/>
      </MainStack.Navigator>);
    } else {
      return (
        <MainStack.Navigator>
        <MainStack.Screen name="Unauthorized"
            component={UnauthorizedScreen}
            options={{          
              headerRight: () => <LogoutHeader />,
              headerTitleAlign: 'center',
              title: "Unauthorized",
            }}/>
      </MainStack.Navigator>
      )
    }
  }
}
    
function HeaderImage() {
  return <Image style={{width: 100, height: 20, marginLeft: 10, resizeMode: 'contain'}} source={require('../assets/icon.png')} />;
}