
import { baseURL } from "../../global";
import axiosInstance from "../../interceptor";
import store from "../../store";
const VehicleApi = {
  
  save: (id: number, type: string, year: string, make: string, model: string, plateNumber: string, color: string) => axiosInstance.post('/vehicle/save', {id: id, type: type, year: year, make: make, model: model, plateNumber: plateNumber, color: color}),

  getVehicle: (id: number) => axiosInstance.get('/vehicle/' + id),
  
  getDriverVehicle: (id: string) => axiosInstance.get('/vehicle/driver/' + id),
  
  getVehicleDocumentationStatus: () => axiosInstance.get('/vehicle/documentation/status'),

  getVehiclePhoto: () => axiosInstance.get('/vehicle/photo/'),
  getVehiclePhotoForDriver: (id: string) => axiosInstance.get('/vehicle/current/photo/' + id),
  getVehicleRegistrationPhoto: () => axiosInstance.get('/vehicle/photo/registration'),
  getVehicleInsurancePhoto: () => axiosInstance.get('/vehicle/photo/insurance'),
  getVehicleSafetyPhoto: () => axiosInstance.get('/vehicle/photo/safety'),

  getVehicleList: (id: string) => axiosInstance.get('/vehicle/documentation/list/vehicle/' + id),
  getRegistrationList: (id: string) => axiosInstance.get('/vehicle/documentation/list/registration/' + id),
  getInsuranceList: (id: string) => axiosInstance.get('/vehicle/documentation/list/insurance/' + id),
  getSafetyList: (id: string) => axiosInstance.get('/vehicle/documentation/list/safety/' + id),

  approveRegistration: (id: number) => axiosInstance.get('vehicle/' + id + '/approved/registration/set/true'),
  unapproveRegistration: (id: number) => axiosInstance.get('vehicle/' + id + '/approved/registration/set/false'),
  approveInsurance: (id: number) => axiosInstance.get('vehicle/' + id + '/approved/insurance/set/true'),
  unapproveInsurance: (id: number) => axiosInstance.get('vehicle/' + id + '/approved/insurance/set/false'),
  approveSafety: (id: number) => axiosInstance.get('vehicle/' + id + '/approved/safety/set/true'),
  unapproveSafety: (id: number) => axiosInstance.get('vehicle/' + id + '/approved/safety/set/false'),

  updateVehiclePhoto: ((postData: FormData) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', baseURL + '/vehicle/photo/');
      
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(postData);
    });
  }),    


  updateVehicleRegistrationPhoto: ((postData: FormData) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', baseURL + '/vehicle/photo/registration');
      
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(postData);
    });
  }),    
  updateVehicleInsurancePhoto: ((postData: FormData) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', baseURL + '/vehicle/photo/insurance');
      
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(postData);
    });
  }),  
  updateVehicleSafetyPhoto: ((postData: FormData) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      
      xhr.open('POST', baseURL + '/vehicle/photo/safety');
      
    
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(postData);
    });
  }),  
  
  


};

export default VehicleApi;