import React, { useEffect } from 'react';
import { StyleSheet, View, TextInput, Dimensions, Text, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { autoLogin, loginSuccess, loginFailure, authenticationError } from '../../services/modules/auth/actions';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthApi from '../../services/modules/auth/api';
import Colors from '../../constants/Colors';

interface Props {
  onComplete: () => void;
}

const LoginForm: React.FC<Props> = ({ 
  onComplete
}) => { 
  const dispatch = useDispatch();

  const authenticationState = useSelector((state: RootState) => state.auth);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const authenticate = () => {
    if (email.trim().length == 0 || password.trim().length == 0) {
      dispatch(authenticationError("Please enter a username and password"));
      return;
    }

    setLoading(true);
    AuthApi.login(email, password)
    .then(async (apiResponse: any) => {
      await storeAutoLogin(apiResponse.data);
      setLoading(false);
      dispatch(loginSuccess(apiResponse.data));
      onComplete();
    })
    .catch((e) => {
      dispatch(loginFailure());
      setLoading(false);
    });
  };

  useEffect(() => {
    attemptAutoLogin()
    .then((result) => {
      if (result) {
        onComplete();
      } else {
        clearAutoLogin();
      }
    });
  }, []);

  const attemptAutoLogin = (async () => {    
    try {
      const id = await AsyncStorage.getItem('user.id');
      const firstName = await AsyncStorage.getItem('user.firstName');
      const lastName = await AsyncStorage.getItem('user.lastName');
      const role = await AsyncStorage.getItem('user.role');
      const token = await AsyncStorage.getItem('user.token');
      const refreshToken = await AsyncStorage.getItem('user.refreshToken');
      const organizationId = await AsyncStorage.getItem('user.organizationId');

      if (id != null && firstName != null && lastName != null && token != null && refreshToken != null && role != null && organizationId != null) {
        dispatch(autoLogin(id, firstName, lastName, token, refreshToken, role, parseInt(organizationId)));
        return true;
      } else {
        clearAutoLogin();
        return false;
      }
    } catch(e) {
      clearAutoLogin();
      return false;
    }
  });

  const clearAutoLogin = (async() => {
    try {
      await AsyncStorage.removeItem('user.id');
      await AsyncStorage.removeItem('user.firstName');
      await AsyncStorage.removeItem('user.lastName');
      await AsyncStorage.removeItem('user.role');
      await AsyncStorage.removeItem('user.token');
      await AsyncStorage.removeItem('user.refreshToken');
      await AsyncStorage.removeItem('user.profilePhoto');    
      await AsyncStorage.removeItem('user.organizationId');         
      return true;
    } catch(error) {
      return false;
    }
  });

  const storeAutoLogin = (async (userData: any) => {
    try {
      await AsyncStorage.setItem(
        'user.id',
        userData.id
      );
      await AsyncStorage.setItem(
        'user.firstName',
        userData.firstName
      );
      await AsyncStorage.setItem(
        'user.lastName',
        userData.lastName
      );
      await AsyncStorage.setItem(
        'user.role',
        userData.role
      );
      await AsyncStorage.setItem(
        'user.token',
        userData.token
      );
      await AsyncStorage.setItem(
        'user.refreshToken',
        userData.refreshToken
      ); 
      await AsyncStorage.setItem(
        'user.organizationId',
        userData.organizationId
      );  

      if (userData.profilePhoto == null) {
        await AsyncStorage.setItem(
          'user.profilePhoto',
          ""
        );  
      } else {
        await AsyncStorage.setItem(
          'user.profilePhoto',
          userData.profilePhoto
        );  
      }
  
      return true;
    } catch(error) {
      return false;
    }
  });
  
  return (
    <View>
      {loading &&
        <View style={{marginTop: 40}}>
          <ActivityIndicator size="large" color="#3F51B5" />
          <Text style={styles.loadingText}>Authenticating, please wait...</Text>
        </View>
      }
      {!loading && 
        <View style={styles.inputContainer}>
          <View style={styles.inputView}>
            <TextInput
              style={styles.input}
              placeholder="Enter your email"
              placeholderTextColor="#003f5c"
              onChangeText={(email) => setEmail(email)}
            />
          </View>
    
          <View style={styles.inputView}>
            <TextInput
              style={styles.input}
              placeholder="Enter your password"
              placeholderTextColor="#003f5c"
              secureTextEntry={true}
              onChangeText={(password) => setPassword(password)}
            />
          </View>

          {authenticationState.error.length > 0 &&
              <Text style={styles.errorMessage}>{authenticationState.error}</Text>
          }

          <Button onPress={authenticate}
            style={styles.button}
            text="Login">
          </Button>
        </View>}
    </View>
  )
};

const width = Dimensions.get('window').width

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    height: 45,
    width: 300,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    height: 50,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderColor: Colors.primary,
    borderWidth: 1,
    width: 300,
    borderRadius: 20
  },

  errorMessage: {
    borderRadius: 10,
    color: "#FF0000",
    marginBottom: 20,
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    width: width / 1.4,
  },

  button: {
    margin: 10,
    width: 300,
  },
  
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});

export default LoginForm