import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { Account } from '../../types';
import AccountApi from './api'
import { REGISTER_SUCCESS, REGISTER_FAILURE, REGISTER_LOADING, AccountDispatchTypes, VIEW_ACCOUNT } from './types';

export const updateAccount = (account: Account) => async (dispatch: Dispatch<AccountDispatchTypes>) => {
  dispatch({type: REGISTER_LOADING});

  await AccountApi.update(account.firstName, account.lastName)
  .then((apiResponse: AxiosResponse) => {
    dispatch({type: REGISTER_SUCCESS, payload: apiResponse.data});
  })
  .catch((e) => {
    dispatch({type: REGISTER_FAILURE, payload: e.getMessage()});
  });
}

export const register = (account: Account) => async (dispatch: Dispatch<AccountDispatchTypes>) => {
  dispatch({type: REGISTER_LOADING});

  await AccountApi.register(account.email, account.password, account.firstName, account.lastName, account.phoneNumber, account.city, 0.0, 0.0, account.role)
  .then((apiResponse: AxiosResponse) => {
    dispatch({type: REGISTER_SUCCESS, payload: apiResponse.data});
  })
  .catch((e) => {
    dispatch({type: REGISTER_FAILURE, payload: e.getMessage()});
  });
}

export const selectAccount = (id: string) => async (dispatch: Dispatch<AccountDispatchTypes>) => {
  dispatch({type: VIEW_ACCOUNT, payload: id});
}