import { App } from "../../types";
import { LOAD_SETTINGS, LOAD_SIGNALR, AppDispatchTypes } from "./types";

export const initialState: App = { googleKey: '', connection: null };

export const appReducer = (
  state: App = initialState,
  action: AppDispatchTypes
) => {
  switch (action.type){
    case LOAD_SETTINGS:
      return {
        ...state,
        googleKey: action.payload
      }

    case LOAD_SIGNALR:
      return {
        ...state,
        connection: action.payload
      }
      
    default:
        return state;
  }
}