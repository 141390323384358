import { useNavigation } from '@react-navigation/native';
import React from 'react'

import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  Platform,
} from 'react-native';

import LoginForm from '../components/authentication/LoginForm';
import Button from '../components/ui/Button';

interface Props {
}

const LoginScreen: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation()

  const authenticationComplete = () => {   
  };

  const forgotPassword = () => {
    navigation.navigate('ForgotPassword')
  }

  return ( 
    <ScrollView
      contentInsetAdjustmentBehavior="automatic">
      <View style={styles.container}>
        <View style={styles.inputContainer}>
          <Image style={styles.image} source={require('../assets/logo.png')} />
          <LoginForm
            onComplete={authenticationComplete} />      
        </View>
      </View>
    </ScrollView>
  )
};

/*
      
          <View style={styles.signUpContainer}>
            <Button style={[styles.button, {marginTop: 30}]} onPress={forgotPassword}
              text="Forgot Password">
            </Button>
          </View>
          */
         
const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    ...Platform.select({
      ios: {
        width: "100%"
      },
      android: {
        width: "100%"
      },
      web: {
        width: "80%"
      }
    })
  },

  inputContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 80,
  },
 
  image: {
    width: 200,
    height: 70,
    resizeMode: 'contain',
    marginBottom: 20,
    marginTop: 20
  },

  signUpContainer: {
    marginTop: 60,
    marginBottom: 250
  },

  button: {
    margin: 10,
    width: 300,
  }
});

export default LoginScreen