import React from 'react';
import { Alert, StyleSheet, View, TextInput, Dimensions, Text, ActivityIndicator } from 'react-native';
import AccountApi from "../../services/modules/account/api";
import Colors from '../../constants/Colors';
import Button from '../ui/Button';

interface Props {
  onComplete: () => void;
}

const ForgotPasswordForm: React.FC<Props> = ({ 
  onComplete
}) => { 
  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  const updatePassword = () => {
    if (password.trim().length == 0 || code.trim().length == 0 || email.trim().length == 0) {
      alert("Please fill in all fields")
      return;
    }

    if (password != confirmPassword) {
      alert("Passwords do not match")
      return;
    }

    setSaving(true);
    
    AccountApi.updatePassword(email, password, code)
    .then(() => {
      setSaving(false)
      alert("Your password has been updated. Please login using your new password.")
    })
    .catch((error) => {
      setSaving(false)
      alert("Error Updating Account: " + error.data.message)
    });
  };

  const sendResetCode = () => {
    if (email.trim().length == 0) {
      alert("Please enter your email address")
      return;
    }

    setSending(true);
    AccountApi.sendResetCode(email)
    .then(() => {
      setSending(false);
      alert("Check your email for the code to reset your password.")
    })
    .catch((error) => {
      setSending(false);
      alert("Error Sending Reset Code: " + error.data.message)
    });
  }
  
  return (
    <View>      
      {saving && <View>
        <ActivityIndicator size="large" />
        <Text style={styles.loadingText}>Saving new password, one moment...</Text>
      </View>}
      {sending && <View>
        <ActivityIndicator size="large" />
        <Text style={styles.loadingText}>Sending code, one moment...</Text>
      </View>}


      {!saving && !sending && <View style={styles.inputContainer}>
        <View style={[styles.panel, {marginTop: 0}]}>
          <Text>To reset your password, enter your email address and press "Send Reset Code". We will email you the code required to reset your password.</Text>
        </View>

        <View style={styles.inputView}>
          <TextInput
            style={styles.input}
            placeholder="Enter your email address"
            placeholderTextColor="#003f5c"
            onChangeText={(email) => setEmail(email)}
          />
        </View>
        
        <Button onPress={sendResetCode}
          style={styles.button}
          text="Send Reset Code">
        </Button>

        <View style={[styles.panel, {marginTop: 40}]}>
          <Text>Enter the new password and your reset code below to change your password.</Text>
        </View>

        <View style={styles.inputView}>
          <TextInput
            style={styles.input}
            placeholder="Enter your reset code"
            placeholderTextColor="#003f5c"
            onChangeText={(resetCode) => setCode(resetCode)}
          />
        </View>

        <View style={styles.inputView}>
          <TextInput
            style={styles.input}
            placeholder="Enter a password"
            placeholderTextColor="#003f5c"
            secureTextEntry={true}
            onChangeText={(password) => setPassword(password)}
          />
        </View>
        
        <View style={styles.inputView}>
          <TextInput
            style={styles.input}
            placeholder="Confirm your password"
            placeholderTextColor="#003f5c"
            secureTextEntry={true}
            onChangeText={(confirmPassword) => setConfirmPassword(confirmPassword)}
          />
        </View>
        
        <Button onPress={updatePassword}
          style={styles.button}
          text="Save New Password">
        </Button>
      </View>}
    </View>
  )
};

const width = Dimensions.get('window').width

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    height: 45,
    width: 300,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    height: 50,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderColor: Colors.primary,
    borderWidth: 1,
    width: 300,
    borderRadius: 20
  },

  button: {
    margin: 10,
    width: 300,
  },
  
  panel: {
    width: 350,
    marginBottom: 20,
    backgroundColor:  Colors.white,
    borderRadius: 10,
    padding: 10,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold',
    color: Colors.white
  },
});

export default ForgotPasswordForm