import React from 'react'
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  TextInput,
  Alert,
} from 'react-native';
import Colors from '../../constants/Colors';
import Button from '../ui/Button';
import AccountApi from '../../services/modules/account/api';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';
import Checkbox from 'expo-checkbox';
import { createAccount } from '../../services/helpers';
import CommunicationApi from '../../services/modules/communication/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import { useNavigation } from '@react-navigation/native';

interface Props {
}

const PushNotifications: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation()

  const authState = useSelector((state: RootState) => state.auth)

  const [selectedPassenger, setSelectedPassenger] = React.useState(createAccount())

  const [allPassengers, setAllPassengers] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [searched, setSearched] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);
  
  const [title, setTitle] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");

  const [searchResults, setSearchResults] = React.useState([])

  const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

  const updateSearch = (text: string) => {
    setKeyword(text)
    if (text.length > 2) {
      setSearching(true)
      AccountApi.searchPassengers(text)
      .then((apiResponse: any) => {
        setSearchResults(apiResponse.data);
        setSearching(false);
        setSearched(true);
      });
    } else if (text.length == 0) {
      setSearchResults([]);
    }
  }

  const sendMessage = () => {
    if (!allPassengers && selectedPassenger.id.length == 0) {
      alert("Please select a passenger to send a message to.");
      return;
    }

    if (title.length == 0 || message.length == 0) {
      alert("Please enter a title and message.")
      return;
    }

    setSending(true)
    CommunicationApi.sendMessage(allPassengers, authState.id, selectedPassenger.id, title, message)
    .then((apiResponse: any) => {
      setSending(false)
      alert("Your message has been sent.")
      navigation.goBack()
    })
    .catch((error) => {
    });
  }

  const clearSearch = () => {
    setKeyword(undefined);
  }

  const drawAccountCard = (({ item }: { item: any }) => {
    return (
        <View style={styles.card}>
          <TouchableOpacity onPress={() => { setSelectedPassenger(item); setSearchResults([]); setKeyword(undefined); setSearched(false);}}>
            <View style={styles.cardHeaderContainer}>
              <View style={{ marginTop: 5 }}>
                <Text style={styles.cardHeader}>{item.firstName} {item.lastName}</Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>)
  })

  return (<View>     
            {!sending && <Text style={{fontSize: 16, padding: 10}}>Start typing the persons name then click them.</Text>}
            {!sending && <SafeSearchBar
              platform={"default"}
              searchIcon={false}
              onChangeText={updateSearch}
              onCancel={clearSearch}
              value={keyword}
              placeholder="Search"
              inputContainerStyle={styles.searchInputContainer}
              inputStyle={styles.searchInput}
              containerStyle={styles.searchContainer}
            />}

            {searching && !sending &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.searchingText}>Searching...</Text>
              </View>
            }

            {sending &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.searchingText}>Sending...</Text>
              </View>
            }

            {searchResults.length == 0 && !searching && searched && !sending && <Text style={styles.cardHeader}>No accounts found</Text>}
            {searchResults.length > 0 && !searching && !sending && <View>
              <FlatList
                keyExtractor={(item: any, index) => item.id.toString()}
                data={searchResults}
                renderItem={drawAccountCard}
              />
            </View>}

            {!sending && <View>
              <View style={styles.inputContainer}>
                <View style={styles.checkboxContainer}>
                  <Checkbox
                    value={allPassengers}
                    onValueChange={(value: boolean) => {
                      setSelectedPassenger(createAccount())
                      setAllPassengers(value)
                    }}
                    style={styles.checkbox}
                  />
                  <Text style={styles.label}> Send to all passengers</Text>
                </View>
              </View>  

              {selectedPassenger.id.length > 0 && <View style={styles.inputContainer}>
                <View style={styles.checkboxContainer}>
                  <Text> Sending to {selectedPassenger.firstName} {selectedPassenger.lastName}</Text>
                </View>
              </View>}

              {allPassengers && <View style={styles.inputContainer}>
                <View style={styles.checkboxContainer}>
                  <Text> Sending to all passengers</Text>
                </View>
              </View>}

              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>Message Title</Text>
                <View style={styles.inputGroup}>
                  <TextInput
                    placeholder="Enter a title for this message"
                    placeholderTextColor="#003f5c"
                    value={title}
                    onChangeText={(title) => {
                      setTitle(title);
                    }}
                    style={[styles.searchInput, { width: 300 }]}
                  />
                </View>
              </View>   
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>Message</Text>
                <View style={styles.inputGroup}>
                  <TextInput
                    multiline={true}
                    numberOfLines={3}
                    value={message}
                    onChangeText={(message) => {
                      setMessage(message);
                    }}
                    style={[styles.searchInput, { height:80, width: 300, textAlignVertical: 'top', }]}/>
                </View>
              </View> 
            
              <Button onPress={sendMessage}
                style={styles.button}
                text="Send">
              </Button>

              <Button onPress={close}
                style={[styles.button, {marginTop: 20}]}
                text="Cancel">
              </Button>
            </View>}
  </View>)
};

const styles = StyleSheet.create({ 
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },
  
  inputContainer: {
    padding: 10
  },
  
  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,    
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
  
  inputLabel: {
    fontSize: 16,
    marginVertical: 10,
    marginLeft: 5,
    fontWeight: 'bold'
  },

  checkboxContainer: {
    flexDirection: "row",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10
  },

  checkbox: {
    alignSelf: "center",
  },

  card: {
    backgroundColor: Colors.white,
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },
  
  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },
  
  cardTime: {
    fontSize: 18,
  },

  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 4,
    color: Colors.white
  },

  sectionHeaderView: {
    flexDirection: 'row',
    width: "98%",
    backgroundColor: Colors.primary,
    borderRadius: 10,
    padding: 10
  },
  
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  
  button : {
    marginTop: 2,
    marginLeft: 10,
  },
  
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  
  searchInputContainer: {
    backgroundColor: Colors.white,
  },
  
  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  label: {
    margin: 8,
  },
});

export default PushNotifications