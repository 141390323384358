import { createRide } from "../../helpers";
import { Ride } from "../../types";
import { RideDispatchTypes, VIEW_RIDE } from "./types";

export const initialState: Ride = createRide();

export const rideReducer = (
  state: Ride = initialState,
  action: RideDispatchTypes
) => {
  switch (action.type){
    case VIEW_RIDE:
      return {
        ...state,
        id: action.payload,
      }
    default:
        return state;
  }
}