import axiosInstance from "../../interceptor";
import { PromoCode } from "../../types";

const PaymentApi = {
  getActivePromoCodes: () => axiosInstance.get('/payment/promocodes/list/active'),
  getAllPromoCodes: () => axiosInstance.get('/payment/promocodes/list/all'),
  updatePromoCode: (promoCode: PromoCode) => axiosInstance.post('/payment/promocodes/save', { id: promoCode.id, title: promoCode.title, percent: promoCode.percent, singleUse:promoCode.singleUse, startDate: promoCode.startDate.toISOString(), expiryDate: promoCode.expiryDate.toISOString(), noExpiry: promoCode.noExpiry, everyone: promoCode.everyone}),
  removePromoCode: (id: number) => axiosInstance.get('/payment/promocodes/remove/' + id),
  addAccountPromoCode: (accountId: string, promoCodeId: number, dateAssigned: Date) => axiosInstance.post('/payment/promocodes/account/add', { accountId: accountId, promoCodeId: promoCodeId, dateAssigned: dateAssigned}),
  removeAccountPromoCode: (accountId: string, promoCodeId: number) => axiosInstance.get('/payment/promocodes/account/remove/' + accountId + '/' + promoCodeId),
  getPromoCodeAccounts: (id: number) => axiosInstance.get('/payment/promocodes/accounts/' + id),
  getPromoCode: (id: number) => axiosInstance.get('/payment/promocodes/get/' + id),
  searchPromoCode: (keyword: string) => axiosInstance.get('payment/promocodes/search/' + keyword),
};

export default PaymentApi;