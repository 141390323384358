import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  RefreshControl,
  Image,
  Text,
  ScrollView,
} from 'react-native';
import 'fix-date';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';

import 'moment-timezone';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import GoogleMapReact from 'google-map-react';
import Colors from '../../constants/Colors';
import RideApi from '../../services/modules/ride/api';
import { createRide, getAPIDate } from '../../services/helpers';
import AccountApi from '../../services/modules/account/api';
import { List } from 'react-native-paper';
import { baseURL } from '../../services/global';

import {
  GiftedChat,
  IMessage,
} from 'react-native-gifted-chat'
import { Ride } from '../../services/types';
import { Picker } from '@react-native-picker/picker';
import { colors } from 'react-native-elements';
import moment from 'moment-timezone';

interface Props {
}

const RideViewer: React.FC<Props> = ({
}) => {
  const isFocused = useIsFocused()
  const navigation = useNavigation()

  const [saving, setSaving] = React.useState(false);
  const [newDriverAccountId, setNewDriverAccountId] = React.useState("");
  const [drivers, setDrivers] = React.useState([]);
  const appState = useSelector((state: RootState) => state.app);
  const rideState = useSelector((state: RootState) => state.ride);
  const authState = useSelector((state: RootState) => state.auth);
  const [messages, setMessages] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [ride, setRide] = React.useState(createRide());
  const [driverLongitude, setDriverLongitude] = React.useState(0);
  const [driverLatitude, setDriverLatitude] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const close = (() => {
    navigation.goBack();
  });

  const MapPin = ({ lat, lng }: { lat: any, lng: any }) => {
    return (<Image
      source={require('../../assets/car.png')}
      style={{ height: 30, width: 30 }} />)
  };

  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        RideApi.getRide(rideState.id)
          .then((apiResponse: any) => {
            let ride = apiResponse.data;
            setRide(ride)
            getMessages(ride);
            if (ride.passengerAccountId != ride.driverAccountId) {
              AccountApi.getDriverLocation(ride.driverAccountId)
                .then((apiResponse: any) => {
                  setDriverLatitude(apiResponse.data.latitude)
                  setDriverLongitude(apiResponse.data.longitude)
                })
            }

            setLoaded(true)
          })
      }
    }, [isFocused])
  )

  useEffect(() => {
    AccountApi.getApprovedDrivers()
      .then((apiResponse: any) => {
        setDrivers(apiResponse.data)
      })
  }, [])

  const getMessages = (r: Ride) => {

    AccountApi.getRideMessages(r.id)
      .then((apiResponse: any) => {
        let tempMessages: IMessage[] = [];

        let arr: any[] = apiResponse.data;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            sent: message.sent,
            received: message.received,
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,
              /* avatar: () => (
                 <View>
                   {message.account.profilePhoto.length > 0 && <Image source={{ uri: baseURL + "/account/photo/file/" + message.account.id + "/" + message.account.profilePhoto }} resizeMode="cover" style={{ borderRadius: 50, height: 50, width: 50, margin: 10 }} />}
                   {message.account.profilePhoto.length == 0 && <Image source={require('../../assets/no_photo.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
                 </View>
               )*/
            }
          }

          /*if (authState.id == message.toAccountId && !message.received) {
            AccountApi.patchMessage(message.id, true, '/received', 'replace')
              .then((apiResponse: any) => {
                //console.error(apiResponse.data);



              })
              .catch((error) => {
                console.error(error.response);

              })
              .finally(() => {

              });
          }*/
          tempMessages.push(tempMessage)


        });

        setMessages(GiftedChat.append([], tempMessages));

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        //clearNewMessage();
      });

  }
  const saveDriver = () => {
    if (newDriverAccountId.length == 0) {
      alert("Please select a driver.");
      return;
    }

    //Only need to notify them if they changed the driver
    if (ride.driverAccountId != newDriverAccountId) {
      if (!confirm("This ride will be assigned to the selected driver and they will be notified, continue?")) {
        return;
      }
    }

    setSaving(true);
    RideApi.reassignDriver(ride.id, newDriverAccountId)
      .then(() => {
        setSaving(false);
        close();
      });
  }


  return (<ScrollView
    contentInsetAdjustmentBehavior="automatic">

    {!loaded &&
      <View>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.searchingText}>Loading...</Text>
      </View>
    }


    {saving &&
      <View style={{ marginTop: 40 }}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.searchingText}>Saving...</Text>
      </View>
    }

    {loaded && !saving && <View>
      <View style={styles.inputContainer}>
        <View style={styles.sectionContainer}>
          <View style={styles.sectionHeaderView}>
            <Text style={styles.sectionHeader}>DETAILS</Text>
          </View>
          <Text style={styles.inputLabel}>Date Booked</Text>
          <View style={styles.inputBox}>
            <Moment element={Text} format="D-MMM-yyyy H:mm A" style={styles.inputText}>{moment((getAPIDate(ride.scheduledTime)))}</Moment>
          </View>
          <Text style={styles.inputLabel}>Status</Text>
          <View style={styles.inputBox}>
            <Text style={styles.inputText}>{ride.status.status}</Text>
          </View>
          <Text style={styles.inputLabel}>Driver</Text>
          <View style={styles.inputBox}>
            {ride.driverAccountId.length > 0 && <Text style={styles.inputText}>{ride.driverAccount.firstName} {ride.driverAccount.lastName}</Text>}
            {ride.driverAccountId.length == 0 && <Text style={styles.inputText}>Not Assigned</Text>}
          </View>



          <Text style={styles.inputLabel}>Re-assign to driver</Text>
          <View style={styles.inputGroup}>
            <View style={styles.inputBoxNoBorder}>
              <Picker
                style={styles.input}
                selectedValue={newDriverAccountId}
                onValueChange={(itemValue, itemIndex) => setNewDriverAccountId(itemValue)}>
                <Picker.Item key={0} label="Please Select" value={""} />
                {
                  drivers.map((v: any) => {
                    return <Picker.Item key={v.account.id} label={v.account.lastName + ", " + v.account.firstName} value={v.account.id} />
                  })
                }
              </Picker>
            </View>
          </View>
          <Button onPress={saveDriver}
            style={styles.button}
            text="Save">
          </Button>


          <Text style={styles.inputLabel}>Passenger</Text>
          <View style={styles.inputBox}>
            <Text style={styles.inputText}>{ride.passengerAccount?.firstName} {ride.passengerAccount?.lastName}</Text>
          </View>
          <Text style={styles.inputLabel}>Pick Up Address</Text>
          <View style={styles.inputBox}>
            <Text>{ride.pickupLocation.address}</Text>
            <Text>{ride.pickupLocation.city}</Text>
            <Text>{ride.pickupLocation.province}, {ride.pickupLocation.postalCode}</Text>
          </View>
          <Text style={styles.inputLabel}>Destination Address</Text>
          <View style={styles.inputBox}>
            <Text>{ride.destinationLocation.address}</Text>
            <Text>{ride.destinationLocation.city}</Text>
            <Text>{ride.destinationLocation.province}, {ride.destinationLocation.postalCode}</Text>
          </View>


          {ride.destinationLatitudeActual != 0 && ride.destinationLongitudeActual != 0 && <Text style={styles.header}>Actual Destination Location</Text>}

          {ride.destinationLatitudeActual != 0 && ride.destinationLongitudeActual != 0 && <View>
            <GoogleMapReact
              style={{ width: "100%", height: 300 }}
              bootstrapURLKeys={{ key: appState.googleKey }}
              defaultCenter={{ lat: ride.destinationLatitudeActual, lng: ride.destinationLongitudeActual }}
              defaultZoom={16}>
              <MapPin
                lat={ride.destinationLatitudeActual}
                lng={ride.destinationLongitudeActual}
              />
            </GoogleMapReact>
          </View>}


          <List.Accordion
            title="Chat"
            titleStyle={{color: Colors.white}}
            right={props => <List.Icon {...props} icon={expanded ? "chevron-down" : 'chevron-up'} />}
            expanded={expanded}
            onPress={() => setExpanded(!expanded)}
            style={{borderColor: Colors.borderGrey, borderRadius: 10, backgroundColor: Colors.primary, margin: 10}}>




            <View style={{ marginBottom: 20, backgroundColor: colors.grey4, alignSelf: "center", height: 300, width: width / 1.5 }}>
              {messages.length == 0 && <Text>No messages</Text>}
              {messages.length > 0 && <GiftedChat
                messages={messages}
                renderInputToolbar={() => (<></>)}
                showUserAvatar={true}

                user={
                  {
                    _id: authState.id,
                    name: authState.firstName + " " + authState.lastName,
                  }

                }

              />}
            </View>

          </List.Accordion>

          {driverLatitude != 0 && driverLongitude != 0 && <Text style={styles.header}>Driver Location</Text>}

          {driverLatitude != 0 && driverLongitude != 0 && <View>
            <GoogleMapReact
              style={{ width: "100%", height: 300 }}
              bootstrapURLKeys={{ key: appState.googleKey }}
              defaultCenter={{ lat: driverLatitude, lng: driverLongitude }}
              defaultZoom={16}>
              <MapPin
                lat={driverLatitude}
                lng={driverLongitude}
              />
            </GoogleMapReact>
          </View>}

        </View>
      </View>

      <Button onPress={close}
        style={styles.button}
        text="Close">
      </Button>
    </View>}
  </ScrollView>
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  headerButton: {
    margin: 5,
  },

  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  inputBoxNoBorder: {
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },
  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button: {
    width: width - 20,
    margin: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    color: Colors.borderGrey,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  sectionContainer: {
    width: width - 20,
    margin: 10
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton: {
    margin: 4,
  },
  header: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: 4,
  },
});
export default RideViewer