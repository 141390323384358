import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  RefreshControl,
  Image,
  Text,
  ScrollView,
  Modal,

} from 'react-native';

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { RootState } from '../../services/store';
import Button from '../ui/Button';
import GoogleMapReact from 'google-map-react';
import Colors from '../../constants/Colors';
import RideApi from '../../services/modules/ride/api';
import { createAccount, createRide } from '../../services/helpers';
import AccountApi from '../../services/modules/account/api';
import { baseURL } from '../../services/global';
import VehicleApi from '../../services/modules/vehicle/api';
import DriverStatsTicker from '../report/DriverStatsTicker';
import { Appbar, Badge, List } from 'react-native-paper';
import { isSameDay, format, isSameYear } from 'date-fns';
import { IMessage, GiftedChat } from 'react-native-gifted-chat';
import { Account } from '../../services/types';

import * as ImagePicker from "expo-image-picker";
import axiosInstance from '../../services/interceptor';
import { refresh } from '../../services/modules/auth/actions';
import { DatePicker } from '../ui/DatePicker';
import DriverRatings from './DriverRatings';
interface Props {
}

const DriverViewer: React.FC<Props> = ({
}) => {
  const isFocused = useIsFocused()
  const navigation = useNavigation()



  const [messages, setMessages] = React.useState<any[]>([]);

  const [allChat, setAllChat] = React.useState<any>(null);

  const [allChatUnread, setAllChatUnread] = React.useState<any>(0);

  const [chats, setChats] = React.useState<any>({});
  const [showChat, setShowChat] = React.useState<Account | any>(null);



  const accountState = useSelector((state: RootState) => state.account);
  const authState = useSelector((state: RootState) => state.auth);
  const [expanded, setExpanded] = React.useState(true);
  const [approved, setApproved] = React.useState(false);
  const [suspended, setSuspended] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [licencesLoaded, setLicencesLoaded] = React.useState(false);
  const [taxiLicencesLoaded, setTaxiLicencesLoaded] = React.useState(false);
  const [identificationsLoaded, setIdentificationsLoaded] = React.useState(false);
  const [vehiclesLoaded, setVehiclesLoaded] = React.useState(false);
  const [registrationsLoaded, setRegistrationsLoaded] = React.useState(false);
  const [insurancesLoaded, setInsurancesLoaded] = React.useState(false);
  const [safetysLoaded, setSafetysLoaded] = React.useState(false);

  const [insuranceSlipsLoaded, setInsuranceSlipsLoaded] = React.useState(false);

  const [driver, setDriver] = React.useState(createAccount());

  const [licences, setLicences] = React.useState([]);
  const [taxiLicences, setTaxiLicences] = React.useState([]);
  const [identifications, setIdentifications] = React.useState([]);
  const [vehicles, setVehicles] = React.useState([]);
  const [registrations, setRegistrations] = React.useState([]);
  const [insurances, setInsurances] = React.useState([]);
  const [safetys, setSafetys] = React.useState([]);
  const [insuranceSlipExpiryDate, setInsruanceSlipExpiryDate] = React.useState(new Date());

  const [insuranceSlipImage, setInsuranceSlipImage] = React.useState<string>("");

  const [insuranceSlips, setInsuranceSlips] = React.useState([]);

  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = React.useState(false);



  const pickInsuranceSlipImage = async () => {
    try {
      let response = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        base64: true
      });

      if (!response.cancelled) {
        setInsuranceSlipImage(response.base64!);
      }
    } catch (error) {
    }
  };


  const storeRefreshedToken = async (token: string, refreshToken: string) => {
    try {
      //TODO: better local storage
      await AsyncStorage.setItem(
        'user.token',
        token
      );
      await AsyncStorage.setItem(
        'user.refreshToken',
        refreshToken
      );

      dispatch(refresh(authState.id, authState.firstName, authState.lastName, token, refreshToken, ""))

      return true;
    } catch (error) {
      return false;
    }
  };

  const uploadPhoto = () => {
    let formData = new FormData();
    const curDate = new Date();
    const filename = 'insuranceslip' + curDate.getDate() + '_' + curDate.getMonth() + '_' + curDate.getFullYear() + '_' + curDate.getHours() + '_' + curDate.getMinutes() + '_' + curDate.getSeconds() + '.jpg';

    formData.append("expiry", insuranceSlipExpiryDate?.toISOString())
    formData.append("id", accountState.id)
    formData.append("filename", filename);
    formData.append('filedata', insuranceSlipImage);

    //setSaving(true);

    var xhr = new XMLHttpRequest();
    xhr.open('POST', baseURL + '/account/photo/insuranceslip');
    xhr.setRequestHeader('Authorization', 'Bearer ' + authState.token);

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        //finishUpload(xhr.response);
        loadInsuranceSlips()
        setModalVisible(false);
                      
      } else {
        if (xhr.status == 401 && xhr.getResponseHeader("tokenexpired") != null) {
          axiosInstance.post('/auth/refresh', { token: authState.token, refreshToken: authState.refreshToken })
            .then(async (apiResponse: any) => {
              storeRefreshedToken(apiResponse.data.token, apiResponse.data.refreshToken)
                .then(async () => {
                  var xhr2 = new XMLHttpRequest();
                  xhr2.open('POST', baseURL + '/account/photo/insuranceslip');
                  xhr2.setRequestHeader('Authorization', 'Bearer ' + apiResponse.data.token);

                  xhr2.onload = function () {
                    if (xhr2.status >= 200 && xhr2.status < 300) {
                
                      //finishUpload(xhr2.response);
                    } else {
                      // setSaving(false);
                    }
                  };

                  xhr2.onerror = function () {
                    //setSaving(false);
                  };

                  xhr2.send(formData);
                })
                .catch((error: any) => {
                  //setSaving(false);
                })
            });
        } else {
          //setSaving(false);
        }
      }
    };

    xhr.onerror = function () {
      //setSaving(false);
    };

    xhr.send(formData);
  };

  const suspend = () => {
    AccountApi.patchAccount(accountState.id, "/suspended", true)
      .then((apiResponse: any) => {
        setSuspended(true);
      })
  }

  const unsuspend = () => {
    AccountApi.patchAccount(accountState.id, "/suspended", false)
      .then((apiResponse: any) => {
        setSuspended(false);
      })
  }


  const getToAllChat = () => {
    AccountApi.getDriverAllRecentMessage()
      .then((apiResponse: any) => {
        console.error(apiResponse.data);
        setAllChat(apiResponse.data.recentMessage)
      })
      .catch((error) => {
      })
      .finally(() => {
        getAllUnreadCount();
      });
  }




  const getAllUnreadCount = () => {

    AccountApi.getDriverAllUnreadCount(authState.id)
      .then((apiResponse: any) => {


        setAllChatUnread(apiResponse.data)
      })
      .catch((error) => {

        console.error(error);

      })
      .finally(() => {

      });
  }


  const getChats = () => {


    AccountApi.getDriverDriverChats(accountState.id)
      .then((apiResponse: any) => {
        console.error(apiResponse.data);

        var arr: any[] = apiResponse.data.chats
        //console.log(arr);
        setChats(arr)

      })
      .catch((error) => {
      })
      .finally(() => {

      });
  }



  const close = (() => {
    navigation.goBack();
  });

  const approve = () => {
    AccountApi.approve(accountState.id)
      .then((apiResponse: any) => {
      })
  }

  const unapprove = () => {
    AccountApi.unapprove(accountState.id)
      .then((apiResponse: any) => {
      })
  }

  const approveLicence = (id: number) => {
    AccountApi.approveLicence(id)
      .then((apiResponse: any) => {
        loadLicences()
      })
  }

  const unapproveLicence = (id: number) => {
    AccountApi.unapproveLicence(id)
      .then((apiResponse: any) => {
        loadLicences()
      })
  }

  const approveTaxiLicence = (id: number) => {
    AccountApi.approveTaxiLicence(id)
      .then((apiResponse: any) => {
        loadTaxiLicences()
      })
  }

  const unapproveTaxiLicence = (id: number) => {
    AccountApi.unapproveTaxiLicence(id)
      .then((apiResponse: any) => {
        loadTaxiLicences()
      })
  }


  const approveInsuranceSlip = (id: number) => {
    AccountApi.approveInsuranceSlip(id)
      .then((apiResponse: any) => {
        loadInsuranceSlips()
      })
  }

  const unapproveInsuranceSlip = (id: number) => {
    AccountApi.unapproveInsuranceSlip(id)
      .then((apiResponse: any) => {
        loadInsuranceSlips()
      })
  }

  const approveIdentification = (id: number) => {
    AccountApi.approveIdentification(id)
      .then((apiResponse: any) => {
        loadIdentifications()
      })
  }

  const unapproveIdentification = (id: number) => {
    AccountApi.unapproveIdentification(id)
      .then((apiResponse: any) => {
        loadIdentifications()
      })
  }

  const approveRegistration = (id: number) => {
    VehicleApi.approveRegistration(id)
      .then((apiResponse: any) => {
        loadRegistrations()
      })
  }

  const unapproveRegistration = (id: number) => {
    VehicleApi.unapproveRegistration(id)
      .then((apiResponse: any) => {
        loadRegistrations()
      })
  }

  const approveSafety = (id: number) => {
    VehicleApi.approveSafety(id)
      .then((apiResponse: any) => {
        loadSafetys()
      })
  }

  const unapproveSafety = (id: number) => {
    VehicleApi.unapproveSafety(id)
      .then((apiResponse: any) => {
        loadSafetys()
      })
  }

  const approveInsurance = (id: number) => {
    VehicleApi.approveInsurance(id)
      .then((apiResponse: any) => {
        loadInsurances()
      })
  }

  const unapproveInsurance = (id: number) => {
    VehicleApi.unapproveInsurance(id)
      .then((apiResponse: any) => {
        loadInsurances()
      })
  }

  const downloadLicence = async (filename: string) => {
    fetch(baseURL + "/account/photo/driverlicence/file/" + accountState.id + "/" + filename,
      {
        headers: new Headers({
          'Authorization': 'bearer ' + authState.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.blob())
      .then(blob => {
        try {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
        }
      });
  }

  const downloadTaxiLicence = async (filename: string) => {
    fetch(baseURL + "/account/photo/taxilicence/file/" + accountState.id + "/" + filename,
      {
        headers: new Headers({
          'Authorization': 'bearer ' + authState.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.blob())
      .then(blob => {
        try {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
        }
      });
  }


  const downloadInsuranceSlip = async (filename: string) => {
    fetch(baseURL + "/account/photo/insuranceslip/file/" + accountState.id + "/" + filename,
      {
        headers: new Headers({
          'Authorization': 'bearer ' + authState.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.blob())
      .then(blob => {
        try {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
        }
      });
  }

  const downloadIdentification = async (filename: string) => {
    fetch(baseURL + "/account/photo/identification/file/" + accountState.id + "/" + filename,
      {
        headers: new Headers({
          'Authorization': 'bearer ' + authState.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.blob())
      .then(blob => {
        try {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
        }
      });
  }

  const downloadVehicle = async (filename: string) => {
    fetch(baseURL + "/vehicle/photo/file/" + accountState.id + "/" + filename,
      {
        headers: new Headers({
          'Authorization': 'bearer ' + authState.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.blob())
      .then(blob => {
        try {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
        }
      });
  }

  const downloadRegistration = async (filename: string) => {
    fetch(baseURL + "/vehicle/photo/registration/file/" + accountState.id + "/" + filename,
      {
        headers: new Headers({
          'Authorization': 'bearer ' + authState.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.blob())
      .then(blob => {
        try {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
        }
      });
  }

  const downloadInsurance = async (filename: string) => {
    fetch(baseURL + "/vehicle/photo/insurance/file/" + accountState.id + "/" + filename,
      {
        headers: new Headers({
          'Authorization': 'bearer ' + authState.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.blob())
      .then(blob => {
        try {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
        }
      });
  }

  const downloadSafety = async (filename: string) => {
    fetch(baseURL + "/vehicle/photo/safety/file/" + accountState.id + "/" + filename,
      {
        headers: new Headers({
          'Authorization': 'bearer ' + authState.token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.blob())
      .then(blob => {
        try {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
        }
      });
  }

  const loadLicences = () => {
    setLicencesLoaded(false)
    AccountApi.getLicenceList(accountState.id)
      .then((apiResponse: any) => {
        setLicences(apiResponse.data)
        setLicencesLoaded(true)
      })
  }

  const loadTaxiLicences = () => {
    setTaxiLicencesLoaded(false)
    AccountApi.getTaxiLicenceList(accountState.id)
      .then((apiResponse: any) => {
        setTaxiLicences(apiResponse.data)
        setTaxiLicencesLoaded(true)
      })
  }


  const loadInsuranceSlips = () => {
    setInsuranceSlipsLoaded(false)
    AccountApi.getInsuranceSlipList(accountState.id)
      .then((apiResponse: any) => {
        setInsuranceSlips(apiResponse.data)
        setInsuranceSlipsLoaded(true)
      })
  }
  const loadIdentifications = () => {
    setIdentificationsLoaded(false)
    AccountApi.getIdentificationList(accountState.id)
      .then((apiResponse: any) => {
        setIdentifications(apiResponse.data)
        setIdentificationsLoaded(true)
      })
  }

  const loadVehicles = () => {
    setVehiclesLoaded(false)
    VehicleApi.getVehicleList(accountState.id)
      .then((apiResponse: any) => {
        setVehicles(apiResponse.data)
        setVehiclesLoaded(true)
      })
  }

  const loadRegistrations = () => {
    setRegistrationsLoaded(false)
    VehicleApi.getRegistrationList(accountState.id)
      .then((apiResponse: any) => {
        setRegistrations(apiResponse.data)
        setRegistrationsLoaded(true)
      })
  }

  const loadInsurances = () => {
    setInsurancesLoaded(false)
    VehicleApi.getInsuranceList(accountState.id)
      .then((apiResponse: any) => {
        setInsurances(apiResponse.data)
        setInsurancesLoaded(true)
      })
  }

  const loadSafetys = () => {
    setSafetysLoaded(false)
    VehicleApi.getSafetyList(accountState.id)
      .then((apiResponse: any) => {
        setSafetys(apiResponse.data)
        setSafetysLoaded(true)
      })
  }

  useEffect(() => {
    AccountApi.getAccount(accountState.id)
      .then((apiResponse: any) => {
        setApproved(apiResponse.data.approved)
        setSuspended(apiResponse.data.suspended)
        setDriver(apiResponse.data)
        setLoaded(true)
      })
    getChats();
    getToAllChat();
    loadLicences()
    loadTaxiLicences()
    loadInsuranceSlips()
    loadIdentifications()
    loadVehicles()
    loadRegistrations()
    loadInsurances()
    loadSafetys()
  }, [accountState])


  useEffect(() => {

    if (showChat != null) {
      if (showChat == "all") {
        getAllMessages();
      }
      else {
        getMessages(showChat.id);
      }
    }

  }, [showChat])




  const [currentPage, setCurrentPage] = React.useState(0);


  let amount = 10;




  const getAllMessages = () => {
    AccountApi.getDriverAllMessages(accountState.id, 0, amount)
      .then((apiResponse: any) => {
        console.error(apiResponse.data);

        let tempAccountMessages: IMessage[] = [];
        let tempOtherMessages: IMessage[] = [];



        let arr: any[] = apiResponse.data.accountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            sent: true,
            received: message.received,
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,

            }
          }


          tempAccountMessages.push(tempMessage)

        })




        setMessages(GiftedChat.append([], [...tempAccountMessages]));

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });

  }

  const getMessages = (otherAccount: string) => {

    AccountApi.getDriverDriverMessages(accountState.id, otherAccount, 0, amount)
      .then((apiResponse: any) => {
        console.error(apiResponse.data);

        let tempAccountMessages: IMessage[] = [];
        let tempOtherMessages: IMessage[] = [];



        let arr: any[] = apiResponse.data.accountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            sent: true,
            received: message.received,
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,

            }
          }


          tempAccountMessages.push(tempMessage)

        })


        arr = apiResponse.data.toAccountMessages;


        arr.forEach(async message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            sent: true,
            received: message.received,
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,


            }
          }


          tempAccountMessages.push(tempMessage)

        })

        setMessages(GiftedChat.append([], [...tempOtherMessages, ...tempAccountMessages]));

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });

  }

  useEffect(() => {
    if (currentPage > 0) {
      if (showChat != "all") {
        getMoreMessages();
      }
      else {
        getMoreAllMessages();
      }


    }

  }, [currentPage])


  const getMoreMessages = () => {

    AccountApi.getDriverDriverMessages(accountState.id, showChat.id, currentPage, amount)
      .then((apiResponse: any) => {


        let tempAccountMessages: IMessage[] = [];
        let tempOtherMessages: IMessage[] = [];

        let arr: any[] = apiResponse.data.accountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            sent: message.sent,
            received: message.received,
            pending: false,
            createdAt: new Date(message.dateCreated),
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,

            }
          }


          tempAccountMessages.push(tempMessage)

        })


        arr = apiResponse.data.toAccountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,


            }
          }

          tempAccountMessages.push(tempMessage)

        })



        setMessages((previousMessages: IMessage[] | undefined) => GiftedChat.append(previousMessages, tempAccountMessages));

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });

  }



  const getMoreAllMessages = () => {

    AccountApi.getDriverAllMessages(accountState.id, currentPage, amount)
      .then((apiResponse: any) => {


        let tempAccountMessages: IMessage[] = [];
        let tempOtherMessages: IMessage[] = [];

        let arr: any[] = apiResponse.data.accountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            sent: message.sent,
            received: message.received,
            pending: false,
            createdAt: new Date(message.dateCreated),
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,

            }
          }


          tempAccountMessages.push(tempMessage)

        })


        arr = apiResponse.data.toAccountMessages;
        arr.forEach(message => {

          let tempMessage: IMessage = {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.dateCreated),
            user: {
              _id: message.account.id,
              name: message.account.firstName + " " + message.account.lastName,


            }
          }

          tempAccountMessages.push(tempMessage)

        })



        setMessages((previousMessages: IMessage[] | undefined) => GiftedChat.append(previousMessages, tempAccountMessages));

      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });

  }


  const getMessageTime = (chat: any) => {
    if (chat) {
      if (isSameDay(new Date(), new Date(chat.dateCreated))) { // If same day, show time
        return format(new Date(chat.dateCreated), "p");
      }
      else if (isSameYear(new Date(), new Date(chat.dateCreated))) { // If same year, show month and date
        return format(new Date(chat.dateCreated), "MMM d");
      }
      else { // Else show, month and date and year
        return format(new Date(chat.dateCreated), "MMM d y");
      }
    }
    else {
      return "";
    }
  }
  const onChangeStartDate = (selectedDate: any) => {
    let storeDate = new Date(selectedDate.target.value + " 00:00:00")
    setInsruanceSlipExpiryDate(storeDate)
  }
  return (<ScrollView
    contentInsetAdjustmentBehavior="automatic">

    {modalVisible &&
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {

          setModalVisible(!modalVisible);
        }}>
          
      
        <View style={styles.modalContainer}>
          <View style={styles.modalView}>
          <Text style={styles.sectionHeader}>INSURANCE SLIP</Text>
                <Text style={styles.inputLabel}>Expiry Date</Text>
                <DatePicker
                  value={insuranceSlipExpiryDate}
                  onChange={onChangeStartDate} />
            
              <Button text='Select Image' onPress={() => { pickInsuranceSlipImage() }} style={{ marginLeft: 10 }} />
              <Image style={styles.image} source={{ uri: "data:image/png;base64," + insuranceSlipImage }} />
              <Button text='Save' onPress={() => { uploadPhoto() }} style={{ marginLeft: 10 }} />
              <Button text='Cancel' onPress={() => { setModalVisible(false) }} style={{ marginTop: 5, marginLeft: 10 }} />
            </View>
          
        
        </View>
      </Modal>
    }

    {!loaded &&
      <View>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.searchingText}>Loading...</Text>
      </View>
    }

    {loaded && <View>
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.mainContainer}>
          <View style={styles.sectionContainer}>
            <View style={styles.sectionHeaderView}>
              <Text style={styles.sectionHeader}>DETAILS</Text>
            </View>
            <Text style={styles.inputLabel}>Status</Text>
            <View style={[styles.inputBox, { flexDirection: 'row' }]}>
              <Text style={[styles.inputText, { marginRight: 20, marginTop: 5 }]}>{approved ? 'Approved' : 'Not Approved'}</Text>
              {!approved && <Button text='Approve' onPress={() => { approve(); setApproved(true) }} />}
              {approved && <Button text='Unapprove' onPress={() => { unapprove(); setApproved(false) }} />}
            </View>
            <View style={[styles.inputBox, { flexDirection: 'row' }]}>
              <Text style={[styles.inputText, { marginRight: 20, marginTop: 5 }]}>{suspended ? 'Suspended' : 'Not Suspended'}</Text>
              {!suspended && <Button text='Suspend' onPress={() => { suspend(); }} />}
              {suspended && <Button text='Unsuspend' onPress={() => { unsuspend(); }} />}
            </View>
            <Text style={styles.inputLabel}>Name</Text>
            <View style={styles.inputBox}>
              <Text style={styles.inputText}>{driver.firstName} {driver.lastName}</Text>
            </View>
            <Text style={styles.inputLabel}>Email</Text>
            <View style={styles.inputBox}>
              <Text style={styles.inputText}>{driver.userName}</Text>
            </View>
            <Text style={styles.inputLabel}>City</Text>
            <View style={styles.inputBox}>
              <Text style={styles.inputText}>{driver.city}</Text>
            </View>
          </View>


<DriverRatings/>


          <List.Accordion
            title="CHAT"
            titleStyle={styles.sectionHeader}
            style={styles.sectionContainer}
            right={props => <List.Icon {...props} icon={expanded ? "chevron-down" : 'chevron-up'} />}

            expanded={expanded}

            onPress={() => setExpanded(!expanded)}>


            {showChat != null &&
              <View style={{ alignSelf: "center", height: 300, width: width / 1.5 }}>

                <Appbar>
                  <Appbar.Action
                    icon="arrow-left"
                    onPress={() => { setShowChat(null); setCurrentPage(0); }}
                  />

                  <Appbar.Content title={showChat == "all" ? "All" : showChat.firstName + " " + showChat.lastName} />
                </Appbar>

                <GiftedChat
                  messages={messages.sort((a: { createdAt: number; }, b: { createdAt: number; }) => b.createdAt - a.createdAt)}
                  loadEarlier={true}
                  renderInputToolbar={() => (<></>)}
                  onLoadEarlier={() => {
                    setCurrentPage(currentPage + 1)
                  }}

                  showUserAvatar={true}

                  user={
                    {
                      _id: accountState.id,
                      name: accountState.firstName + " " + accountState.lastName,

                    }

                  } />

              </View>
            }
            {showChat == null &&
              <>


                {allChat &&
                  <List.Item
                    onPress={() => { setShowChat("all") }}
                    style={{ height: 100, padding: 5 }}
                    key={allChat.id}

                    title={"All"}
                    description={allChat.text}
                    right={() => {
                      return (
                        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                          <Text>{getMessageTime(allChat)}</Text>
                          {allChatUnread > 0 && <Badge>{allChatUnread}</Badge>}
                        </View>
                      )
                    }}>




                  </List.Item>
                }

                {(Object.keys(chats).length == 0 && allChat == null) && <View><Text>No messages</Text></View>}


                {Object.keys(chats).map((key: string, index) => {
                  let chat = chats[key].messages;
                  let count = chats[key].count;

                  return (




                    <List.Item
                      onPress={() => { setShowChat(chat.accountId == accountState.id ? chat.toAccount : chat.account) }}
                      style={{ height: 100, padding: 5 }}
                      key={chat.id}

                      title={chat.accountId == accountState.id ? chat.toAccount.firstName + " " + chat.toAccount.lastName : chat.account.firstName + " " + chat.account.lastName}
                      description={chat.text}
                      right={() => {
                        return (
                          <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                            <Text>{getMessageTime(chat)}</Text>
                            {count > 0 && <Badge>{count}</Badge>}
                          </View>
                        )
                      }}>




                    </List.Item>




                  )



                })}
              </>
            }
          </List.Accordion>





          <View style={styles.sectionContainer}>
            <View style={styles.sectionHeaderView}>
              <Text style={styles.sectionHeader}>DOCUMENTATION</Text>
            </View>
         
            <Text style={styles.inputLabel}>Taxi License</Text>
            <View style={styles.inputBox}>
              {!taxiLicencesLoaded &&
                <View>
                  <ActivityIndicator size="large" color="#3F51B5" />
                  <Text style={styles.searchingText}>Loading...</Text>
                </View>
              }
              {taxiLicences.length == 0 && taxiLicencesLoaded && <Text>No taxi licences on file.</Text>}
              {taxiLicences.length > 0 && taxiLicences.map((licence: any) => {
                return (
                  <View style={{ flexDirection: 'row', marginBottom: 5, marginTop: 5 }} key={licence.id}>
                    <Moment element={Text} format="MMM Do, YYYY" style={{ marginRight: 20, marginTop: 5 }}>{licence.expiryDate}</Moment>
                    <Text style={[styles.inputText, { marginRight: 20, marginTop: 5 }]}>{licence.approved ? 'Approved' : 'Not Approved'}</Text>
                    {!licence.approved && <Button text='Approve' onPress={() => { approveTaxiLicence(licence.id) }} />}
                    {licence.approved && <Button text='Unapprove' onPress={() => { unapproveTaxiLicence(licence.id) }} />}
                    <Button text='View' onPress={() => { downloadTaxiLicence(licence.photo) }} style={{ marginLeft: 10 }} />
                  </View>
                )
              })}
            </View>
          </View>

          <View style={styles.sectionContainer}>
            <View style={styles.sectionHeaderView}>
              <Text style={styles.sectionHeader}>VEHICLE</Text>
            </View>
            <Text style={styles.inputLabel}>Details</Text>
            <View style={styles.inputBox}>
              {!vehiclesLoaded &&
                <View>
                  <ActivityIndicator size="large" color="#3F51B5" />
                  <Text style={styles.searchingText}>Loading...</Text>
                </View>
              }
              {vehicles.length == 0 && vehiclesLoaded && <Text>No vehicle on file.</Text>}
              {vehicles.length > 0 && vehicles.map((vehicle: any) => {
                return (
                  <View style={{ marginBottom: 5, marginTop: 5 }} key={vehicle.id}>
                    <Text>Type: {vehicle.type}</Text>
                    <Text>Make: {vehicle.make}</Text>
                    <Text>Model: {vehicle.model}</Text>
                    <Text>Year: {vehicle.year}</Text>
                    <Text>Plate Number: {vehicle.plateNumber}</Text>
                    <Text>Color: {vehicle.color}</Text>
                    <View>
                      <Button text='View' onPress={() => { downloadVehicle(vehicle.photo) }} />
                    </View>
                  </View>
                )
              })}
            </View>
            
            <Text style={styles.inputLabel}>Insurance Slip</Text>
            <View style={styles.inputBox}>
              {!insuranceSlipsLoaded &&
                <View>
                  <ActivityIndicator size="large" color="#3F51B5" />
                  <Text style={styles.searchingText}>Loading...</Text>
                </View>
              }
              {insuranceSlips.length == 0 && insuranceSlipsLoaded && <Text>No insurance slip on file.</Text>}
              {insuranceSlips.length > 0 && insuranceSlips.map((insurance: any) => {
                return (
                  <View style={{ flexDirection: 'row', marginBottom: 5, marginTop: 5 }} key={insurance.id}>
                    <Moment element={Text} format="MMM Do, YYYY" style={{ marginRight: 20, marginTop: 5 }}>{insurance.expiryDate}</Moment>
                    <Text style={[styles.inputText, { marginRight: 20, marginTop: 5 }]}>{insurance.approved ? 'Approved' : 'Not Approved'}</Text>
                    {!insurance.approved && <Button text='Approve' onPress={() => { approveInsuranceSlip(insurance.id) }} />}
                    {insurance.approved && <Button text='Unapprove' onPress={() => { unapproveInsuranceSlip(insurance.id) }} />}
                    <Button text='View' onPress={() => { downloadInsuranceSlip(insurance.photo) }} style={{ marginLeft: 10 }} />
                  </View>
                )
              })}
              <Button text='Add new Insurance Slip' onPress={() => { setModalVisible(true) }} style={{ marginLeft: 10 }} />
            </View>
          </View>

          <Button onPress={close}
            style={styles.button}
            text="Close">
          </Button>
        </View>
        <View style={styles.sideContainer}>
          <View style={{ margin: 10, width: "100%" }}>
            <DriverStatsTicker />
          </View>
        </View>
      </View>
    </View>}
  </ScrollView >
  )
};

const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  headerButton: {
    margin: 5,
  },

  mainContainer: {
    margin: "2%",
    width: "66%",
  },

  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  sideContainer: {
    marginTop: "2%",
    marginRight: "2%",
    width: "28%",
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: width,
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    width: "70%",
    height: 45,
    marginBottom: 20,
    alignItems: "center",
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  image: {
    width: 220,
    height: 220,
    resizeMode: 'contain',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button: {
    width: width - 20,
    margin: 10,
  },
  buttonOn: {
    backgroundColor: Colors.primary,
    color: Colors.borderGrey,
    margin: 4,
  },
  buttonOff: {
    backgroundColor: Colors.primary,
    margin: 4,
  },
  sectionContainer: {
    width: "100%",
    margin: 10
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton: {
    margin: 4,
  },
});
export default DriverViewer