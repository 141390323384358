
import { baseURL } from "../../global";
import axiosInstance from "../../interceptor";
import { Ride, Stop } from "../../types";

const RideApi = {
  save: (ride: Ride) => axiosInstance.post('/ride/save', { ...ride }),

  statsToday: (startDate: Date, endDate: Date, startOfWeek: Date, endOfWeek: Date, startOfYear: Date, endOfYear: Date) => axiosInstance.get('/ride/stats/today/' + startDate.toISOString() + '/' + endDate.toISOString() + '/' + startOfWeek.toISOString() + '/' + endOfWeek.toISOString() + '/' + startOfYear.toISOString() + '/' + endOfYear.toISOString()),
  driverStatsToday: (accountId: string, startDate: Date, endDate: Date) => axiosInstance.get('/ride/stats/driver/' + accountId + '/' + startDate.toISOString() + '/' + endDate.toISOString()),

  passengerStatsToday: (accountId: string, startDate: Date, endDate: Date) => axiosInstance.get('/ride/stats/passenger/' + accountId + '/' + startDate.toISOString() + '/' + endDate.toISOString()),


  getRide: (id: number) => axiosInstance.get('/ride/' + id),
  getCurrentRide: () => axiosInstance.get('/ride/current/passenger'),
  getMainQueue: () => axiosInstance.get('/ride/queue'),
  getDriverCurrentRide: () => axiosInstance.get('/ride/current/driver'),
  getDriverQueuedRide: () => axiosInstance.get('/ride/current/queue'),
  getAvailableRides: () => axiosInstance.get('/ride/available'),
  getDriverRides: () => axiosInstance.get('/ride/driver'),
  getRidesInProgress: () => axiosInstance.get('/ride/all/inprogress'),

  getDriverTotals: () => axiosInstance.get('/account/driver/totals'),
  getPassengerTotals: () => axiosInstance.get('/account/passenger/totals'),

  
  getAllDriverRides: (page: number, amount: number) => axiosInstance.get('/ride/all/driver/'+page+'/'+amount),
  getAllPassengerRides: (page: number, amount: number) => axiosInstance.get('/ride/all/passenger/'+page+'/'+amount),

  getAllRidesForPassenger: (accountId: string, page: number, amount: number) => axiosInstance.get('/ride/all/passenger/'+accountId+'/'+page+'/'+amount),


  fastest: (id: number, latitude: number, longitude: number) => axiosInstance.get('/ride/fastest/' + latitude + "/" + longitude + "/" + id) ,

  assignRide: (id: number) => axiosInstance.get('/ride/assign/' + id),
  setRideStatus: (rideId: number, statusId: number) => axiosInstance.patch('/ride/patch/' + rideId, [{
    "value": statusId,
    "path": "/statusId",
    "op": "replace"
  }]),


  finishRide: (rideId: number, cost: number, distance: number, duration: number) => axiosInstance.patch('/ride/patch/' + rideId, [{
    "value": 5,
    "path": "/statusId",
    "op": "replace"
  },
  {
    "value": cost,
    "path": "/costActual",
    "op": "replace"
  },
  {
    "value": distance,
    "path": "/distanceActual",
    "op": "replace"
  },
  {
    "value": duration,
    "path": "/durationActual",
    "op": "replace"
  }]),


  setRideCompletedDone: (rideId: number, tip: number, rating: number) => axiosInstance.patch('/ride/patch/' + rideId, 
  [{
    "value": 12,
    "path": "/statusId",
    "op": "replace"
  },
  {
    "value": rating,
    "path": "/rating",
    "op": "replace"
  },
  {
    "value": tip,
    "path": "/tip",
    "op": "replace"
  }
]),


  setRideDriver: (rideId: number, driverId: string) => axiosInstance.patch('/ride/patch/' + rideId, [{
    "value": 4,
    "path": "/statusId",
    "op": "replace"
  },
  {
    "value": driverId,
    "path": "/driverAccountId",
    "op": "replace"
  }]),


  setRideCancelled: (rideId: number, driver: boolean, reason: string) => axiosInstance.patch('/ride/patch/' + rideId, [{
    "value": driver ? 2 : 1,
    "path": "/statusId",
    "op": "replace"
  },
  {
    "value": reason,
    "path": "/cancelReason",
    "op": "replace"
  }]),


  
  setReservationCancelled: (rideId: number, reason: string) => axiosInstance.patch('/ride/patch/' + rideId, [{
    "value": 13,
    "path": "/statusId",
    "op": "replace"
  },
  {
    "value": reason,
    "path": "/cancelReason",
    "op": "replace"
  }]),


  completeStop: (rideId: number, stopId: number) => axiosInstance.patch('/ride/patch/' + rideId, [{
    "value": true,
    "path": "/stops/" + stopId +" /completed",
    "op": "replace"
  },
  {
    "value": stopId + 1,
    "path": "/currentStopIndex",
    "op": "replace"
  }]),


  skipStop: (rideId: number, stopId: number) => axiosInstance.patch('/ride/patch/' + rideId, [{
    "value": true,
    "path": "/stops/" + stopId +" /skipped",
    "op": "replace"
  },
  {
    "value": stopId + 1,
    "path": "/currentStopIndex",
    "op": "replace"
  }]),




  

  arriveStop: (rideId: number, stopId: number) => axiosInstance.patch('/ride/patch/' + rideId, [{
    "value": true,
    "path": "/stops/" + stopId +" /arrived",
    "op": "replace"
  }]),



  
  addStop: (rideId: number, stop: Stop, position?: number) => axiosInstance.patch('/ride/patch/' + rideId, [{
    "value": stop,
    "path": position ? "/stops/" + position : "/stops/-",
    "op": "add"
  }]),

  getReservationsPending: () => axiosInstance.get('/ride/reservations/pending'),
  getReservationsPendingAssignment: () => axiosInstance.get('/ride/reservations/pendingassignment'),
  reservationAssignDriver: (id: number, driverAccountId: string) => axiosInstance.post('/ride/reservations/assign', { id: id, driverAccountId: driverAccountId }),

  reassignDriver: (id: number, driverAccountId: string) => axiosInstance.post('/ride/reassign', { id: id, driverAccountId: driverAccountId }),
};

export default RideApi;