import { Admin } from "../../types";
import { SELECT_PROMO_CODE, AdminDispatchTypes } from "./types";

export const initialState: Admin = { id: 0 };

export const paymentReducer = (
  state: Admin = initialState,
  action: AdminDispatchTypes
) => {
  switch (action.type){
    case SELECT_PROMO_CODE:
      return {
        ...state,
        id: action.payload
      }
      
    default:
        return state;
  }
}