import React from 'react'

import {
  StyleSheet,
} from 'react-native';

import ScheduleEntryEditor from '../components/schedule/ScheduleEntryEditor';

interface Props {
}

const ScheduleEntryEditorScreen: React.FC<Props> = ({ 
}) => {   
  return ( 
    <ScheduleEntryEditor />
  )
};

const styles = StyleSheet.create({
});

export default ScheduleEntryEditorScreen