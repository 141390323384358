import { useNavigation } from '@react-navigation/native';
import React from 'react'

import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  Platform,
  Text,
} from 'react-native';

import LoginForm from '../components/authentication/LoginForm';
import DriverViewer from '../components/driver/DriverViewer';
import PendingReservation from '../components/ride/PendingReservation';
import Button from '../components/ui/Button';

interface Props {
}

const ReservationsScreen: React.FC<Props> = ({ 
}) => {   
  return ( 
    <View style={styles.container}>
      <PendingReservation />
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    flex: 1,
    width: "100%"
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 20
  }
});

export default ReservationsScreen