import { createElement } from "react-native";
import { formatDate } from "../../services/helpers";

export const DatePicker = ({value, onChange} : {value: Date, onChange: any}) => {
  const [date, time] = formatDate(value).split(' ');

  return createElement('input', {
    type: 'date',
    value: date,
    onChange: onChange,
  })
}