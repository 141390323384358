
import { baseURL } from "../../global";
import axiosInstance from "../../interceptor";
import store from "../../store";

const AccountApi = {
  approve: (id: string) => axiosInstance.get('account/' + id + '/approved/set/true'),
  unapprove: (id: string) => axiosInstance.get('account/' + id + '/approved/set/false'),


  getDriverRatings: (id: string) => axiosInstance.post('/account/driver/' + id + '/ratings'),

  approveDiscountID: (id: number) => axiosInstance.get('account/' + id + '/approved/discountidentification/set/true'),
  unapproveDiscountID: (id: number) => axiosInstance.get('account/' + id + '/approved/discountidentification/set/false'),
  
  
  approveLicence: (id: number) => axiosInstance.get('account/' + id + '/approved/licence/set/true'),
  unapproveLicence: (id: number) => axiosInstance.get('account/' + id + '/approved/licence/set/false'),
  approveTaxiLicence: (id: number) => axiosInstance.get('account/' + id + '/approved/taxilicence/set/true'),
  unapproveTaxiLicence: (id: number) => axiosInstance.get('account/' + id + '/approved/taxilicence/set/false'),

  approveInsuranceSlip: (id: number) => axiosInstance.get('account/' + id + '/approved/insuranceslip/set/true'),
  unapproveInsuranceSlip: (id: number) => axiosInstance.get('account/' + id + '/approved/insuranceslip/set/false'),

  approveIdentification: (id: number) => axiosInstance.get('account/' + id + '/approved/identification/set/true'),
  unapproveIdentification: (id: number) => axiosInstance.get('account/' + id + '/approved/identification/set/false'),
  search: (keyword: string) => axiosInstance.get('account/search/' + keyword),
  searchDrivers: (keyword: string) => axiosInstance.get('account/searchdrivers/' + keyword),
  searchPassengers: (keyword: string) => axiosInstance.get('account/searchpassengers/' + keyword),
  getIdentificationsPendingApproval: () => axiosInstance.get('/account/passenger/all/pending'),

  // For the DriverMessenger page
  getDriverDriverChats: (accountId: number) => axiosInstance.get('/chat/driver/' + accountId),
  getDriverAllRecentMessage: () => axiosInstance.get('/chat/driver/to/all/newest'),

  // Load actual messages for chat
  getMessage: (messageId: number) => axiosInstance.get('/chat/message/' + messageId),
  getRideMessages: (rideId: number) => axiosInstance.get('/chat/ride/' + rideId),
  getDriverDriverMessages: (accountId: string, otherAccountId: string, page: number, amount: number) => axiosInstance.get('/chat/driver/' + accountId + "/to/" + otherAccountId + '/' + page + "/" + amount),
  getDriverAllMessages: (accountId: string, page: number, amount:number) => axiosInstance.get('/chat/driver/' + accountId + '/to/all/' + page + "/" + amount),


  // These 3 can be used anywhere to indicate unread messages, return with count
  getDriverDriverUnreadCount: (accountId: string, otherAccountId: string) => axiosInstance.get('/chat/driver/' + accountId + "/to/" + otherAccountId + '/unread/coount'),
  getDriverAllUnreadCount: (accountId: string) => axiosInstance.get('/chat/driver/all/to/driver/' + accountId + '/unread/count'),
  getRideUnreadCount: (rideId: number, accountId: string) => axiosInstance.get('/chat/ride/' + rideId + "/to/" + accountId + '/unread/coount'),


  patchMessage: (messageId: number, value: any, path: string, operation:string) => axiosInstance.patch('/chat/message/'+ messageId + '/patch', [{
    "value": value,
    "path": path,
    "op": operation
  }]),

  addMessage: (text: string, sent: boolean, received: boolean, pending: boolean, allDrivers: boolean, accountId: string, rideId: number | null, toAccountId: string | null) => axiosInstance.post('/chat/message/add', { text: text, sent: sent, received: received, pending: pending, allDrivers: allDrivers, accountId: accountId, rideId: rideId, toAccountId: toAccountId }),

  



  register: (email: string, password: string, firstName: string, lastName: string, contactPhone: string, city: string, latitude: number, longitude: number, role: string) => axiosInstance.post('/auth/signup', { userName: email, firstName: firstName, lastName: lastName, password: password, contactPhone: contactPhone, city: city, role: role }),
  updatePassword: (email: string, password: string, code: string) => axiosInstance.post('/auth/passwordreset', { userName: email, password: password, resetCode: code }),
  sendResetCode: (email: string) => axiosInstance.get('/auth/reset/' + email),
  update: (firstName: string, lastName: string) => axiosInstance.post('/account', { firstName: firstName, lastName: lastName }),
  getLocation: (accountId: string) => axiosInstance.get('/account/location/' + accountId),



  getOnlineDrivers: () => axiosInstance.get('/account/driver/all/online/break'),
  getOtherDrivers: () => axiosInstance.get('/account/driver/all/other'),
  getDriversPendingApproval: () => axiosInstance.get('/account/driver/all/pending'),
  getDriversExpiring: () => axiosInstance.get('/account/driver/all/expiring'),
  getApprovedDrivers: () => axiosInstance.get('/account/driver/all/approved'),


  getDriverLocation: (id: string) => axiosInstance.get('/account/driver/location/' + id),
  addDriverLocation: (latitude: number, longitude: number, heading: number) => axiosInstance.post('/account/driver/location', { latitude: latitude, longitude: longitude, heading: heading }),


  getDriverEarnings: (startDate: Date, endDate: Date) => axiosInstance.post('/account/driver/earnings', { startDate: startDate, endDate: endDate }),




  patchAccount: (id: string, path: string, value: any, op: string = "replace") => axiosInstance.patch('/account/patch/' + id, [{
    "value": value,
    "path": path,
    "op": op
  }]),




  setDriverStatus: (statusId: number) => axiosInstance.patch('/account/driver/status', [{
    "value": statusId,
    "path": "/statusId",
    "op": "replace"
  }]),

  getDriverStatus: (id: string) => axiosInstance.get('/account/driver/status/' + id),


  getAccount: (id: string) => axiosInstance.get('/account/' + id),

  updateProfilePhoto: (postData: FormData) => axiosInstance.post('/account/photo/profile', postData),


  getDriverAvailable: (id: string) => axiosInstance.get('/account/available/' + id),
  getDriverDocumentationStatus: () => axiosInstance.get('/account/documentation/status'),
  getLicenceList: (id: string) => axiosInstance.get('/account/documentation/list/licence/' + id),
  getDiscountIDList: (id: string) => axiosInstance.get('/account/documentation/list/discountidentification/' + id),
  getTaxiLicenceList: (id: string) => axiosInstance.get('/account/documentation/list/taxilicence/' + id),
  getInsuranceSlipList: (id: string) => axiosInstance.get('/account/documentation/list/insuranceslip/' + id),
  getIdentificationList: (id: string) => axiosInstance.get('/account/documentation/list/identification/' + id),


  updateRequirements: () => axiosInstance.post('/account/requirements/set'),

  getDriverPhoto: () => axiosInstance.get('/account/photo/'),
  getPhotoForDriver: (id: string) => axiosInstance.get('/account/current/photo/' + id),
  getDriverLicencePhoto: () => axiosInstance.get('/account/photo/driverlicence'),
  getTaxiLicencePhoto: () => axiosInstance.get('/account/photo/taxilicence'),
  getInsuranceSlipPhoto: () => axiosInstance.get('/account/photo/insuranceslip'),
  getIdentificationPhoto: () => axiosInstance.get('/account/photo/identification'),

  updatePushToken: (pushToken: string) => axiosInstance.post('/account/pushtoken', { pushToken: pushToken }),


  updateDriverPhoto: ((postData: FormData) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', baseURL + '/account/photo/');

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(postData);
    });
  }),



  updateDriverLicencePhoto: ((postData: FormData) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', baseURL + '/account/photo/driverlicence');

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(postData);
    });
  }),
  updateTaxiLicencePhoto: ((postData: FormData) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', baseURL + '/account/photo/taxilicence');

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(postData);
    });
  }),



  updateInsuranceSlipPhoto: ((postData: FormData) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', baseURL + '/account/photo/insuranceslip');

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(postData);
    });
  }),

  updateIdentificationPhoto: ((postData: FormData) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', baseURL + '/account/photo/identification');

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(postData);
    });
  }),



};

export default AccountApi;