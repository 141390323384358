import { HubConnection } from "@microsoft/signalr";

export enum RideStatuses {
  "Cancelled by passenger" = 1,
  "Cancelled by driver" = 2,
  "Awaiting driver assignment" = 3,
  "Awaiting driver arrival" = 4,
  "Completed" = 5,
  "In progess" = 7,
  "Driver arrived" = 8,
  "Driver nearby" = 9,
  "Done" = 12,
}

export enum DriverStatuses {
  "Online" = 1,
  "Offline" = 2,
  "Paused" = 4
}

export interface Stop {
  id: number;
  location: Location | null;
  rideId: number;
  locationId: number;
  order: number
  note: string;
  completed: boolean;
  arrived: boolean;
  skipped: boolean;
}

export interface Ride {
  driverAccount?: Account;
  status?: any;
  type?: any;
  passengerAccount?: Account;
  id: number;
  driverAccountId: string;
  passengerAccountId: string;
  rating: number;
  tip: number;
  distanceEstimated: number;
  distanceActual: number;
  durationEstimated: number;
  durationActual: number;
  costEstimated: number;
  costActual: number;
  scheduledTime?: Date;
  pickupTime?: Date;
  dropoffTime?: Date;
  pickupLocation: Location;
  destinationLocation: Location;
  typeId: number;
  statusId: number;
  stops: Stop[];
  seats: number;
  luggage: boolean;
  note: string;
  currentStopIndex: number;
  queued: boolean;
  waitTime: number;
  cancelReason: string;
  dateCreated?: string;
  dateUpdated?: string;
  destinationLatitudeActual: number;
  destinationLongitudeActual: number;
}
export interface Account {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  password: string;
  token: string;
  refreshToken: string;
  photo: string;
  phoneNumber: string;
  city: string;
  cityLatitude: number;
  cityLongitude: number;
  metric: number;
  lastLocationLatitude?: number;
  lastLocationLongitude?: number;
  lastLocationHeading?: number;
  statusId?: number;
  status?: any;
  image?: any;
  toggleMarkerImage: boolean;
  isOnRide: boolean;
  suspended: boolean;
  profilePhotoDate: string;
  profilePhotoData: any;
}


  
export interface AccountReceived {
  id: number;
  messageId: number;
  account: Account;
  accountId: string;
}

export interface Message {
  id: number;
  text: string;
  createdAt: Date | number;
  
  sent: boolean;
  received: boolean;
  pending: boolean;
  accountsReceived: AccountReceived[];
  rideId?: number;
  accountId?: string;
  toAccountId?: string;
  toAccount?: Account;
  account: Account;
dateCreated: string;
}

export interface Vehicle {
  id: number;
  accountId: string;
  lastName: string;
  type: string;
  make: string;
  model: string;
  plateNumber: string;
  year: string;
  insuranceId: number;
  registrationId: number;
}
export interface Location {
  id: number;
  title: string;
  address: string;
  addressOptional: string;
  city: string;
  latitude: number;
  longitude: number;
  province: string;
  postalCode: string;
}

export interface DriverLocation {
  id: number;
  accountId: string;
  position: any;
  rideId?: number;
  ride?: any;
  rideStatusId?: number;
  rideStatus?: any;
  driverStatusId?: number;
  driverStatus: any;
  latitude: number;
  longitude: number;
  heading: number;
}

export interface App {
  googleKey: string;
  connection: HubConnection | null;
}

export type PlacesPredictionType = {
  description: string;
  place_id: string;
  reference: string;
  matched_substrings: any[];
  tructured_formatting: Object;
  terms: Object[];
  types: string[];
}

export interface ScheduleEntry {
  id: number;
  accountId: string;
  scheduledTime: Date;
  duration: number;
}

export interface PromoCode {
  id: number;
  title: string;
  accountId: string;
  startDate: Date;
  expiryDate: Date;
  noExpiry: boolean;
  everyone: boolean;
  system: boolean;
  singleUse: boolean;
  percent: number;
}

export interface Admin {
  id: number;
}