import { Account } from "../../types";
import { UPDATE_ACCOUNT, REGISTER_FAILURE, REGISTER_LOADING, REGISTER_SUCCESS, AccountDispatchTypes, VIEW_ACCOUNT } from "./types";

export const initialState: Account = { id: '', firstName: '', lastName: '', email: '', password: '', refreshToken: '', token: '', photo: '', phoneNumber: '', role: '', metric: 0, city: '', cityLatitude: 0.0, cityLongitude: 0.0, toggleMarkerImage: false, isOnRide: false, profilePhotoData: "", profilePhotoDate: "" };

export const accountReducer = (
  state: Account = initialState,
  action: AccountDispatchTypes
) => {
  switch (action.type){
    case UPDATE_ACCOUNT:
      return {
        ...state,
      }
    case REGISTER_LOADING:
      return {
        ...state,
        loading: true
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.payload,
      }
    case REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        message: "Could not create your account",
      }
    case VIEW_ACCOUNT:
      return {
        ...state,
        loading: false,
        id: action.payload,
      }
    default:
        return state;
  }
}